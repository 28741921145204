import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Title } from '../../../components';
import { useLocale } from '../../../hooks';
import { sendBulkOnboardingEmail } from '../../../services/admin/members';
import { Paragraph } from '../../shared/body';
import { Button } from '../../shared/buttons';
import { LayoutModal } from '../../shared/layout';

interface SendOnboardingEmailContentProps {
  onCloseClicked: () => void;
}

export const SendOnboardingEmailContent = ({
  onCloseClicked
}: SendOnboardingEmailContentProps) => {
  const [sendingDate, setSendingDate] = useState<Date | null>(new Date());
  const { locale } = useLocale();
  const { t } = useTranslation('customer');

  const { mutate, isPending, isSuccess, isIdle, isError } = useMutation({
    mutationFn: () => sendBulkOnboardingEmail(sendingDate!),
    onError: () => toast.error(t('general.error'))
  });

  return (
    <>
      <Title
        className="!font-bold"
        variant="h3"
        text={t('admin.collaborators.modal.sendBulkInvitationEmail.title')}
      />
      {isSuccess && (
        <div className="flex flex-col gap-5">
          <Paragraph className="mt-8">
            {t('admin.collaborators.modal.sendBulkInvitationEmail.success')}
          </Paragraph>
          <div className="flex justify-end">
            <Button
              variant="tertiary"
              size="medium"
              label={t('general.close')}
              onClick={onCloseClicked}
            />
          </div>
        </div>
      )}
      {(isIdle || isPending || isError) && (
        <div className="flex flex-col gap-5">
          <Paragraph className="mt-8">
            {t('admin.collaborators.modal.sendBulkInvitationEmail.text')}
          </Paragraph>
          <div className="flex my-4 justify-center">
            <DatePicker
              showIcon
              selected={sendingDate}
              onChange={(date) => setSendingDate(date)}
              locale={locale}
              minDate={new Date()}
              showTimeSelect
              inline
              timeCaption={t(
                'admin.collaborators.modal.sendBulkInvitationEmail.time'
              )}
            />
          </div>

          <div className="flex gap-4 justify-end">
            <Button
              onClick={onCloseClicked}
              variant="secondary"
              size="medium"
              label={t('general.cancel')}
            />
            <Button
              onClick={() => mutate()}
              isLoading={isPending}
              variant="custom"
              size="medium"
              label={t(
                'admin.collaborators.modal.sendBulkInvitationEmail.submit'
              )}
              customClassNames="bg-purple-900 hover:bg-purple-900 text-white justify-center font-medium w-fit"
            />
          </div>
        </div>
      )}
    </>
  );
};

const BulkSendOnboardingEmailDialogButton = () => {
  const { t } = useTranslation('customer');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Button
        variant="outline"
        size="small"
        fullWidth
        onClick={() => setIsModalOpen(true)}
        label={t('admin.collaborators.modal.sendBulkInvitationEmail.button')}
        icon={<PaperAirplaneIcon className="w-5 h-5" />}
      />
      <LayoutModal isModalOpen={isModalOpen} handleModalClose={closeModal}>
        <SendOnboardingEmailContent onCloseClicked={closeModal} />
      </LayoutModal>
    </div>
  );
};

export default BulkSendOnboardingEmailDialogButton;
