import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import FormHelper from 'components/FormHelper';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { funnelQuestionSchema } from 'schemas';

import { Button } from 'features/shared/buttons';

import QuestionTextArea from '../../shared/forms/QuestionTextArea';

export interface QuestionFormInput {
  question: string;
  step: 'source' | 'question';
  source: 'cse' | 'personal';
}

interface MemberQuestionFormProps {
  isLoading?: boolean;
  canSelectSource?: boolean;
  onSubmit: (question: QuestionFormInput) => void;
}

const plans = [
  {
    name: 'account.ask.step1.choice.cse.title',
    value: 'cse',
    description: 'account.ask.step1.choice.cse.description',
    image: '/images/illu_additional_request.png'
  },
  {
    name: 'account.ask.step1.choice.personal.title',
    value: 'personal',
    description: 'account.ask.step1.choice.personal.description',
    image: '/images/illu_personal_question.png'
  }
];

export default function MemberQuestionForm({
  isLoading = false,
  canSelectSource = false,
  onSubmit
}: MemberQuestionFormProps) {
  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch
  } = useForm<QuestionFormInput>({
    resolver: yupResolver(funnelQuestionSchema(tForm)),
    defaultValues: {
      source: !canSelectSource ? 'personal' : undefined,
      step: canSelectSource ? 'source' : 'question'
    }
  });

  const currentStep = watch('step');
  const source = watch('source');

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col gap-4"
    >
      {currentStep === 'source' && (
        <>
          <p>{t('account.ask.step1.paragraph')}</p>
          <Controller
            control={control}
            name="source"
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                value={value}
                onChange={onChange}
                className="mb-4 flex flex-col sm:flex-row justify-between gap-4"
              >
                {plans.map((plan) => (
                  <RadioGroup.Option
                    key={plan.name}
                    value={plan.value}
                    className={({ active }) =>
                      classNames(
                        'relative flex w-1/2 cursor-pointer rounded-lg focus:outline-none bg-white border-gray-300 border hover:ring-2 hover:ring-purple-800/40',
                        active &&
                          'ring-2 ring-purple-800/60 ring-offset-2 ring-offset-purple-800'
                      )
                    }
                  >
                    {({ checked }) => (
                      <div>
                        <div className="relative bg-purple-200 rounded-lg h-32 sm:h-52 flex justify-center">
                          <img
                            alt="additional request"
                            className="max-h-full"
                            src={plan.image}
                          />

                          {checked && (
                            <div className="absolute top-0 right-0 m-6 rounded-full block bg-purple-800 ml-12 p-1 ">
                              <CheckIcon className="h-5 w-5 text-white" />
                            </div>
                          )}
                        </div>
                        <div className="p-4 flex flex-col gap-4">
                          <RadioGroup.Label className="font-bold text-lg">
                            {t(plan.name as any)}
                          </RadioGroup.Label>
                          <RadioGroup.Description>
                            {t(plan.description as any)}
                          </RadioGroup.Description>
                        </div>
                      </div>
                    )}
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            )}
          />
          <Button
            fullWidth
            size="medium"
            variant="tertiary"
            disabled={!source}
            onClick={() => setValue('step', 'question')}
            label={t('general.continue')}
          />
        </>
      )}
      {currentStep === 'question' && (
        <>
          <FormHelper
            variant="transparent"
            title={t('account.ask.advices.howTo.title')}
            footer={t('account.ask.advices.howTo.footer')}
            items={[
              t('account.ask.advices.howTo.advice1'),
              t('account.ask.advices.howTo.advice2'),
              t('account.ask.advices.howTo.advice3')
            ]}
          />
          <div className="flex justify-between items-center mt-4">
            <p>{t('account.ask.step2.paragraph')}</p>
          </div>
          <QuestionTextArea register={register} error={errors.question} />
          <Button
            fullWidth
            submit
            size="medium"
            variant="tertiary"
            isLoading={isLoading}
            label={t('account.ask.cta')}
          />
        </>
      )}
    </form>
  );
}
