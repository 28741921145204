import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'features/shared/buttons';

interface Answer {
  id: number;
  label: string;
  value: boolean;
}

const childrens = [
  {
    id: 1,
    label: 'Oui',
    value: true
  },
  {
    id: 2,
    label: 'Non',
    value: false
  }
];

const owner = [
  {
    id: 1,
    label: 'Oui',
    value: true
  },
  {
    id: 2,
    label: 'Non',
    value: false
  }
];

const salary = [
  {
    id: 1,
    label: 'Oui',
    value: true
  },
  {
    id: 2,
    label: 'Non',
    value: false
  }
];

export default function SituationCompletionForm() {
  const navigate = useNavigate();
  const { t } = useTranslation('customer');

  const [childrenAnswer, setChildrenAnswer] = useState<Answer | null>(null);
  const [ownerAnswer, setOwnerAnswer] = useState<Answer | null>(null);
  const [marriageAnswer, setMarriageAnswer] = useState<Answer | null>(null);

  const handleNavigate = () => {
    const params = new URLSearchParams({
      ...(!!childrenAnswer && { hasChildren: childrenAnswer.value.toString() }),
      ...(!!ownerAnswer && { isOwner: ownerAnswer.value.toString() }),
      ...(!!marriageAnswer && { isMarried: marriageAnswer.value.toString() })
    });
    navigate(`/onboarding/thematics?${params.toString()}`);
  };

  return (
    <div className="flex w-full flex-col gap-4">
      {/*  */}
      <RadioGroup value={childrenAnswer} onChange={setChildrenAnswer}>
        <p className="mb-2">Avez-vous des enfants ?</p>
        <div className="flex gap-4">
          {childrens.map((plan) => (
            <RadioGroup.Option
              key={plan.id}
              value={plan}
              className={({ checked, active }) =>
                classNames(
                  checked &&
                    'border-purple-900 bg-purple-100 drop-shadow-input shadow-purple-900',
                  'w-full relative block cursor-pointer rounded-lg bg-white px-6 py-4 focus:outline-none sm:flex sm:justify-between'
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span className="flex justify-center w-full">
                    <span className="text-sm">
                      <RadioGroup.Label
                        as="span"
                        className={classNames(
                          'text-gray-900',
                          checked ? 'font-bold' : 'font-medium'
                        )}
                      >
                        {plan.label}
                      </RadioGroup.Label>
                    </span>
                  </span>
                  <span
                    className={classNames(
                      checked ? 'border-purple-900' : 'border-gray-500 ',
                      'border pointer-events-none absolute -inset-px rounded-lg'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      <RadioGroup value={ownerAnswer} onChange={setOwnerAnswer}>
        <p className="mb-2">Êtes-vous propriétaire ?</p>
        <div className="flex gap-4">
          {owner.map((plan) => (
            <RadioGroup.Option
              key={plan.id}
              value={plan}
              className={({ checked, active }) =>
                classNames(
                  checked &&
                    'border-purple-900 bg-purple-100 drop-shadow-input shadow-purple-900',
                  'w-full relative block cursor-pointer rounded-lg bg-white px-6 py-4 focus:outline-none sm:flex sm:justify-between'
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span className="flex justify-center w-full">
                    <span className="text-sm">
                      <RadioGroup.Label
                        as="span"
                        className={classNames(
                          'text-gray-900',
                          checked ? 'font-bold' : 'font-medium'
                        )}
                      >
                        {plan.label}
                      </RadioGroup.Label>
                    </span>
                  </span>
                  <span
                    className={classNames(
                      checked ? 'border-purple-900' : 'border-gray-500 ',
                      'border pointer-events-none absolute -inset-px rounded-lg'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      <RadioGroup value={marriageAnswer} onChange={setMarriageAnswer}>
        <p className="mb-2">Êtes-vous marié(e) ?</p>
        <div className="flex justify-between gap-4">
          {salary.map((plan) => (
            <RadioGroup.Option
              key={plan.id}
              value={plan}
              className={({ checked, active }) =>
                classNames(
                  checked &&
                    'border-purple-900 bg-purple-100 drop-shadow-input shadow-purple-900',
                  'w-full relative block cursor-pointer rounded-lg bg-white px-6 py-4 focus:outline-none sm:flex sm:justify-between'
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span className="flex justify-center w-full">
                    <span className="text-sm">
                      <RadioGroup.Label
                        as="span"
                        className={classNames(
                          'text-gray-900',
                          checked ? 'font-bold' : 'font-medium'
                        )}
                      >
                        {plan.label}
                      </RadioGroup.Label>
                    </span>
                  </span>
                  <span
                    className={classNames(
                      checked ? 'border-purple-900' : 'border-gray-500 ',
                      'border pointer-events-none absolute -inset-px rounded-lg'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      {/*  */}
      <Button
        onClick={handleNavigate}
        fullWidth
        isLoading={false}
        submit
        size="medium"
        variant="primary"
        label={t('general.continue')}
      />
    </div>
  );
}
