import { Title } from 'components';
import { LawyerProfile } from 'containers/profile';
import { useTranslation } from 'react-i18next';

import { PageLayout } from 'features/customer/account/content';

export default function ProfilePage() {
  const { t } = useTranslation('lawyer');

  return (
    <PageLayout>
      <Title component="h1" variant="h2">
        {t('account.account.title')}
      </Title>
      <div className="flex w-full flex-col space-y-2">
        <LawyerProfile />
      </div>
    </PageLayout>
  );
}
