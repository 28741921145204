import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Button } from 'features/shared/buttons';

import { postAvailabilityRequest } from '../../../../../services/consultation';

export default function LawyerAvailabilityRequestForm({
  questionId
}: {
  questionId: number;
}) {
  const { t } = useTranslation('customer');
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: () => postAvailabilityRequest(questionId),
    onSuccess: () =>
      toast.success(t('account.consultation.notifyLawyer.success')),
    onError: () => toast.error(t('error.occurred'))
  });

  return (
    <div className="mb-4 flex flex-col lg:flex-row gap-4 lg:gap-12 bg-gray-300 p-9 max-w-[720px] mt-6">
      <div className="flex flex-col py-8 justify-between">
        <p>{t('account.consultations.booking.scheduling.noAvailability')}</p>
        {!isSuccess ? (
          <div className="grid place-items-center mt-8">
            <Button
              variant="tertiary"
              size="medium"
              label={t(
                'account.consultations.booking.scheduling.noAvailability.notify'
              )}
              isLoading={isPending}
              onClick={() => mutate()}
            />
          </div>
        ) : null}
      </div>
      <img
        className="w-56 self-center"
        src="/images/illu_no_slot.png"
        alt="illustration no slot"
      />
    </div>
  );
}
