import { XMarkIcon } from '@heroicons/react/24/outline';

export interface InputClearButtonProps {
  onClear: () => void;
}

const InputClearButton = ({ onClear }: InputClearButtonProps) => (
  <button
    type="button"
    onClick={onClear}
    className="absolute inset-y-0 right-2 flex items-center px-2 text-gray-700 hover:text-gray-900"
  >
    <XMarkIcon className="size-4" />
  </button>
);

export default InputClearButton;
