import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

const AccordionContext = createContext({ openedAccordion: '' });

const convertToDate = (dateString: string) => {
  const newData = dateString.replace(/(\d+[/])(\d+[/])/, '$2$1');
  const data = new Date(newData);
  return data;
};

const Accordion = (props: any) => {
  const { defaultOpen = '', children } = props;
  const [openedAccordion, setOpenedAccordion] = useState<any>(
    defaultOpen || ''
  );

  const values = useMemo(
    () => ({ openedAccordion, setOpenedAccordion }),
    [setOpenedAccordion, openedAccordion]
  );

  return (
    <AccordionContext.Provider value={values}>
      {children}
    </AccordionContext.Provider>
  );
};

const Item = (props: any) => {
  const { id = '', data = {}, onSetSlot, currentSlot } = props;
  const { openedAccordion, setOpenedAccordion } = useContext(
    AccordionContext
  ) as any;

  const stringDate = useMemo(() => {
    const options: any = {
      weekday: 'long',
      month: 'long',
      day: 'numeric'
    };
    const date = convertToDate(data.day);
    if (date) return date.toLocaleDateString('fr-FR', options);
    return 'Erreur';
  }, [data]);

  useEffect(() => {
    if (data?.slots?.find((item: any) => item?.id === currentSlot?.id))
      setOpenedAccordion(id);

    // eslint-disable-next-line
  }, [currentSlot?.id]);

  const onTriggerPanel = useCallback(() => {
    setOpenedAccordion(id === openedAccordion ? null : id);
  }, [setOpenedAccordion, openedAccordion, id]);

  const onSelectSlot = (slot: any) => {
    onSetSlot(slot);
  };

  return (
    <div className="">
      <motion.div>
        <motion.button
          type="button"
          className={classNames(
            'flex flex-row justify-center items-center cursor-pointer rounded border border-[#CCCCCC] rounded-b-none relative py-5 pl-4 pr-[22px] w-full',
            { '!border-b-0 !rounded-b-none': openedAccordion === id }
          )}
          onClick={onTriggerPanel}
        >
          <p className="font-sans font-medium text-base text-[#353535] text-left first-letter:uppercase flex-grow">
            {stringDate ?? ''}
          </p>

          <motion.div
            className="w-5 h-5 fill-black"
            animate={{ rotate: openedAccordion === id ? 180 : 0 }}
            transition={{
              duration: 0.2,
              repeat: 0,
              repeatType: 'reverse',
              reverse: true
            }}
          >
            <ChevronDownIcon className="size-5" />
          </motion.div>
        </motion.button>

        <AnimatePresence>
          {openedAccordion === id && (
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              className={classNames(
                'px-6 rounded rounded-t-none border border-[#CCCCCC] bg-white gap-x-[25px] gap-y-4'
              )}
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 }
              }}
            >
              <div className="pt-[22px] pb-[30px] flex flex-wrap gap-x-[25px] gap-y-4">
                {data?.slots?.map((slot: any) => (
                  <motion.button
                    type="button"
                    onClick={() => onSelectSlot(slot)}
                    className={classNames(
                      'w-[110px] text-[#353535] px-[20px] py-[14px] bg-gray-300 rounded flex flex-row justify-center items-center fill-purple-800 cursor-pointer',
                      {
                        'bg-purple-800 !text-white !fill-white !hover:bg-purple-700 !hover:fill-white !hover:text-white':
                          currentSlot?.id === slot?.id
                      },
                      {
                        'hover:bg-gray-400': currentSlot?.id !== slot?.id
                      }
                    )}
                    key={slot.id}
                  >
                    <p className="font-sans font-bold text-sm leading-[16px] tracking-[0.08em] uppercase">
                      {slot.dayTime}
                    </p>
                  </motion.button>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

Accordion.Item = Item;

export default Accordion;
