import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import {
  AuthLoginPayload,
  loginByPasswordOrMagicLink
} from 'services/authentication/login';

import { useSafedGuardedSearchParams } from 'hooks/useSearchParams';

import { useAuth } from 'context';

import { RequestMagicLink } from 'features/login';
import { LayoutDefault, LayoutLoadingSkeleton } from 'features/shared/layout';

const LoginMagicLinkPage = () => {
  const token = useSafedGuardedSearchParams({ param: 'token' });
  const { setAccessToken, setRefreshToken } = useAuth();

  const { mutate, status } = useMutation({
    mutationFn: (data: AuthLoginPayload) => loginByPasswordOrMagicLink(data),

    onSuccess: (data) => {
      setAccessToken(data.access_token);
      setRefreshToken(data.refresh_token);
    }
  });

  useEffect(() => {
    mutate({ token });
  }, []);

  if (status === 'error') {
    return (
      <LayoutDefault minimal>
        <RequestMagicLink invalid />
      </LayoutDefault>
    );
  }
  return <LayoutLoadingSkeleton />;
};

export default LoginMagicLinkPage;
