import {
  BuildingOfficeIcon,
  ChatBubbleLeftEllipsisIcon,
  CheckBadgeIcon,
  Cog6ToothIcon,
  DocumentTextIcon,
  HomeIcon,
  InformationCircleIcon,
  UserGroupIcon,
  UserPlusIcon
} from '@heroicons/react/24/outline';
import { isEqual } from 'lodash';

export enum PageName {
  Home = 'home',
  Questions = 'questions',
  QuestionsAssignation = 'questions-assignation',
  QuestionsToAnswer = 'questions-to-answer',
  MyCse = 'my-cse',
  CseProfileManagement = 'cse-identity-management',
  Requests = 'requests',
  PersonalRequests = 'requests-personal',
  CseRequests = 'requests-cse',
  Ask = 'ask',
  Consultations = 'consultations',
  Resources = 'resources',
  Calendar = 'calendar',
  Subscription = 'subscription',
  Guarantees = 'guarantees',
  Contact = 'contact',
  Profile = 'profile',
  Dashboard = 'dashboard',
  LawyerCare = 'lawyercare',
  Collaborators = 'collaborators',
  Settings = 'settings',
  Admin = 'admin'
}

export interface IMenuItem {
  name: PageName;
  href: string;
  icon?: any;
  onClick?: Function;
  subMenus?: IMenuItem[];
  extra?: JSX.Element;
}

export const MEMBER_MENU_ITEMS: IMenuItem[] = [
  {
    name: PageName.Home,
    icon: HomeIcon,
    href: '/account/home'
  },
  {
    name: PageName.Requests,
    icon: ChatBubbleLeftEllipsisIcon,
    href: '/account/questions',
    subMenus: [
      {
        name: PageName.PersonalRequests,
        href: '/account/questions'
      },
      {
        name: PageName.CseRequests,
        href: '/account/questions-cse'
      }
    ]
  },
  {
    name: PageName.Resources,
    icon: DocumentTextIcon,
    href: '/account/resources'
  },
  {
    name: PageName.Guarantees,
    icon: CheckBadgeIcon,
    href: '/account/guarantees'
  },
  {
    name: PageName.Contact,
    icon: InformationCircleIcon,
    href: '/account/contact'
  }
];

export const MEMBER_PROFILE_MENU_ITEMS: IMenuItem[] = [
  {
    name: PageName.Profile,
    href: '/account/profile',
    icon: Cog6ToothIcon
  },
  {
    name: PageName.Admin,
    href: '/admin/collaborators',
    icon: BuildingOfficeIcon
  }
];

export const ADMIN_MENU_ITEMS: IMenuItem[] = [
  {
    name: PageName.Collaborators,
    href: '/admin/collaborators',
    icon: UserPlusIcon
  },
  {
    name: PageName.Settings,
    href: '/admin/settings',
    icon: Cog6ToothIcon
  },
  ...(isEqual(process.env.REACT_APP_EKIE_CSE_IDENTITY_AVAILIBILITY, '1')
    ? [
        {
          name: PageName.CseProfileManagement,
          href: '/admin/cse-identity-management',
          icon: UserGroupIcon
        }
      ]
    : [])
];
