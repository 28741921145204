import { ComboboxOption, ComboboxOptionProps } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';

export type ComboboxOptionAutocompleteProps<T> = {
  value: T | null;
} & Omit<ComboboxOptionProps, 'value'>;

const ComboboxAutocompleteOption = <T,>({
  children,
  value,
  ...props
}: ComboboxOptionAutocompleteProps<T>) => (
  <ComboboxOption
    className="group flex cursor-pointer items-center gap-2 bg-white rounded-md py-1.5 px-2 select-none hover:bg-gray-300"
    value={value}
    {...props}
  >
    {children}
  </ComboboxOption>
);

export default ComboboxAutocompleteOption;
