import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { getAccessTokenByRefreshToken } from '../../../services/authentication/refreshToken';
import { Button } from '../../shared/buttons';

function BulkExportButton() {
  /* We need a fresh access token because the file downloading is done via the browser.
  So the axios auto refresh mechanism will not be used. */
  const { mutate, isPending } = useMutation({
    mutationFn: () => getAccessTokenByRefreshToken(),
    onSuccess: (data) => {
      const form = document.createElement('form');
      form.method = 'post';
      form.target = '_blank';
      form.action = extractUrl;
      form.innerHTML = `<input type="hidden" name="access_token" value="${data?.access_token}">`;

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    }
  });
  const { t } = useTranslation('customer');
  const extractUrl = `${process.env.REACT_APP_NEW_API_EKIE_URL}/member/customers/me/members-bulk-export`;
  return (
    <Button
      variant="outline"
      size="small"
      fullWidth
      onClick={mutate}
      isLoading={isPending}
      disabled={isPending}
      label={t('admin.collaborators.button.downloadAll')}
      icon={<ArrowDownTrayIcon className="w-5 h-5" />}
    />
  );
}

export default BulkExportButton;
