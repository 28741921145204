export interface AuthLocalStorage {
  accessToken: string;
  refreshToken: string;
}

// This function is used by axios interceptors in this file for API legacy and axios.ts for Python API
// Unfortunately, AuthContext is not available here because we are outside React and hooks are not available
// We must find a way to initialize interceptors in React component, probably by creating a useAxiosInterceptors hook

const handleClearAuthAndLoginRedirect = () => {
  clearAuthStorage();
  return window.location.replace(`${process.env.REACT_APP_BASE_URL}/login`);
};

const clearAuthStorage = () => {
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('refreshToken');
  window.localStorage.removeItem('email');
};

const setNewAuthStorage = ({ accessToken, refreshToken }: AuthLocalStorage) => {
  window.localStorage.setItem('accessToken', accessToken);
  window.localStorage.setItem('refreshToken', refreshToken);
};

export { handleClearAuthAndLoginRedirect, clearAuthStorage, setNewAuthStorage };
