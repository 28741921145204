interface CircleProgressBarProps {
  percent: number;
}

const CircleProgressBar = ({ percent }: CircleProgressBarProps) => (
  <div className="w-full h-full flex justify-center items-center">
    <svg
      className="w-full h-full max-w-full max-h-full"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid meet"
    >
      <circle
        cx="50"
        cy="50"
        r="45"
        stroke="currentColor"
        strokeWidth="10"
        fill="transparent"
        className="text-gray-300"
      />
      <circle
        cx="50"
        cy="50"
        r="45"
        stroke="currentColor"
        strokeWidth="10"
        fill="transparent"
        strokeDasharray={2 * Math.PI * 45}
        strokeDashoffset={(1 - percent / 100) * 2 * Math.PI * 45}
        strokeLinecap="round"
        className="transition-all text-purple-800 duration-300 origin-center"
        transform="rotate(-90)"
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dominantBaseline="central"
        className="font-bold fill-gray-900 text-[140%]"
      >
        {percent}%
      </text>
    </svg>
  </div>
);

export default CircleProgressBar;
