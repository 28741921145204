import { useQuery } from '@tanstack/react-query';

import {
  SubscriptionDetails,
  SubscriptionStatus
} from 'features/customer/account/subscription';

import { getAuthenticatedMemberSubscriptionInfo } from '../../services/member';

function CustomerSubscription() {
  const { data: subscription, isSuccess } = useQuery({
    queryKey: ['authenticated-member-subscription-info'],
    queryFn: () => getAuthenticatedMemberSubscriptionInfo()
  });

  if (!isSuccess) return null;

  const isSubscriptionTerminated = subscription.status === 'terminated';

  return (
    <div className="max-w-[720px]">
      <div className="mb-6">
        <SubscriptionStatus subscription={subscription} />
      </div>
      {!isSubscriptionTerminated && (
        <div className="mb-6">
          <SubscriptionDetails subscription={subscription} />
        </div>
      )}
    </div>
  );
}

export default CustomerSubscription;
