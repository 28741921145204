import { ComboboxInput, ComboboxInputProps } from '@headlessui/react';
import classNames from 'classnames';

export type ComboboxAutocompleteInputProps<T> = {
  displayValue: (item: T | null) => string;
} & Omit<ComboboxInputProps, 'displayValue'>;

const ComboboxAutocompleteInput = <T,>({
  children,
  displayValue,
  ...props
}: ComboboxAutocompleteInputProps<T>) => (
  <div className="relative">
    <ComboboxInput
      className={classNames(
        'w-full rounded-md py-2 pr-2 pl-3 text-sm',
        'focus:outline-none focus:border-purple-600 focus:ring-purple-600 placeholder:text-gray-700'
      )}
      displayValue={(item) => displayValue(item as unknown as T | null)}
      {...props}
    />
    {children}
  </div>
);

export default ComboboxAutocompleteInput;
