import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

import { Button } from 'features/shared/buttons';

const Back = ({ label }: { label: string }) => {
  const navigate = useNavigate();

  return (
    <Button variant="transparent" size="small" onClick={() => navigate(-1)}>
      <div className="flex items-center tracking-wider uppercase gap-2 my-2">
        <div className="h-8 w-8 rounded-full bg-gray-200 stroke-black flex justify-center items-center hover:opacity-80">
          <ArrowLeftIcon className="w-4 h-4" />
        </div>
        {label}
      </div>
    </Button>
  );
};

export default Back;
