import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders
} from 'axios';
import QueryString from 'qs';
import { getAccessTokenByRefreshToken } from 'services/authentication/refreshToken';

import { handleClearAuthAndLoginRedirect } from 'context/auth/auth.utils';

const PYTHON_API_AXIOS_CONFIG: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_NEW_API_EKIE_URL,
  paramsSerializer: (params: any) =>
    QueryString.stringify(params, { arrayFormat: 'repeat' })
};

export const apiPublicPythonClient: AxiosInstance = axios.create(
  PYTHON_API_AXIOS_CONFIG
);

export const apiPrivatePythonClient: AxiosInstance = axios.create(
  PYTHON_API_AXIOS_CONFIG
);

export const onResponseError = async (
  error: any,
  clientAxios: AxiosInstance
) => {
  const originalRequest = error.config as AxiosError['config'];
  if (originalRequest?.url === '/auth/token/refresh') {
    handleClearAuthAndLoginRedirect();
  }

  if (originalRequest && error?.response?.status === 401) {
    await getAccessTokenByRefreshToken();

    return clientAxios(originalRequest);
  }

  return Promise.reject(error);
};

apiPrivatePythonClient.interceptors.request.use(
  (config) => ({
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`
    } as AxiosRequestHeaders
  }),
  (error) => Promise.reject(error)
);

apiPrivatePythonClient.interceptors.response.use(
  (response) => response,
  (error) => onResponseError(error, apiPrivatePythonClient)
);
