import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocale } from 'hooks';

import { AnsweredIcon } from 'features/shared/icons';
import { LoadingSpinner } from 'features/shared/loading';

interface AutomaticSaveLoaderProps {
  isLoading: boolean;
  date?: Date;
}

const AutomaticSaveLoader = ({ isLoading, date }: AutomaticSaveLoaderProps) => {
  const { t } = useTranslation('lawyer');
  const { dateLocale } = useLocale();
  const [loader, setLoader] = useState({
    isLoading,
    date
  });

  useEffect(() => {
    if (isLoading) {
      return setLoader((prev) => ({ ...prev, isLoading: true }));
    }

    const timeout = setTimeout(() => setLoader({ date, isLoading: false }));

    return () => clearTimeout(timeout);
  }, [isLoading, date]);

  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <div className="pr-2">
          {loader.isLoading ? <LoadingSpinner purple /> : <AnsweredIcon />}
        </div>
        <span className="text-sm">
          {t('account.lawyer.question.answer.save.automatic')}
        </span>
      </div>

      <span className="text-xs self-end text-gray-700 min-h-4">
        {loader.date &&
          format(loader.date, "d MMM 'à' HH'h'mm", { locale: dateLocale })}
      </span>
    </div>
  );
};

export default AutomaticSaveLoader;
