import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { Chip } from 'components';
import { getLawyerSimilarQuestions } from 'services/lawyerQuestion';

interface LawyerQuestionsCountProps {
  questionId: number;
  className?: string;
}

const LawyerSimilarQuestionsCount = ({
  questionId,
  className
}: LawyerQuestionsCountProps) => {
  const {
    data: questionsResponse,
    isLoading: isQuestionsLoading,
    isSuccess: isQuestionsSuccess
  } = useQuery({
    queryKey: ['lawyer-similar-questions', { questionId }],
    queryFn: () =>
      getLawyerSimilarQuestions({
        questionId
      }),
    refetchOnWindowFocus: true,
    placeholderData: (prev) => prev
  });

  if (isQuestionsLoading) return <div />;
  if (!isQuestionsSuccess) return <div />;
  if (questionsResponse.questions.length === 0) return null;

  return (
    <span className={classNames('relative flex ml-2', className)}>
      <span
        className={classNames(
          'absolute inline-flex z-10 size-5 rounded-full bg-purple-600'
        )}
      />
      <Chip color="purple" className="z-20">
        1
      </Chip>
    </span>
  );
};

export default LawyerSimilarQuestionsCount;
