import {
  CheckCircleIcon,
  EllipsisHorizontalCircleIcon
} from '@heroicons/react/24/outline';

export interface CseProfileCompletionStepProps {
  status: 'empty' | 'partial' | 'complete';
}

const CircleIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="2.4"
    stroke="currentColor"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
    />
  </svg>
);

const CseProfileCompletionStep = ({
  status
}: CseProfileCompletionStepProps) => {
  const statusIcons = {
    empty: <CircleIcon className="size-5 text-gray-600" />,
    partial: (
      <EllipsisHorizontalCircleIcon
        strokeWidth={2.4}
        className="size-5 text-gray-600"
      />
    ),
    complete: (
      <CheckCircleIcon strokeWidth={2} className="size-5 text-green-800" />
    )
  };

  return statusIcons[status];
};

export default CseProfileCompletionStep;
