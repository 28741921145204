import FormHelper from 'components/FormHelper';
import { useTranslation } from 'react-i18next';

const AnswerHelper = () => {
  const { t } = useTranslation('lawyer');

  return (
    <FormHelper
      title={t('account.lawyer.question.answer.advices.howTo.title')}
      items={[
        t('account.lawyer.question.answer.advices.howTo.advice1'),
        t('account.lawyer.question.answer.advices.howTo.advice2'),
        t('account.lawyer.question.answer.advices.howTo.advice3'),
        t('account.lawyer.question.answer.advices.howTo.advice4')
      ]}
    />
  );
};

export default AnswerHelper;
