import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translateFromFrenchToEnglish } from 'services/translate';

import SanitizedContent from 'features/customer/question/SanitizedContent';
import { LayoutModal } from 'features/shared/layout';
import { LoadingSpinner } from 'features/shared/loading';

interface TranslateAnswerProps {
  content: string;
}

const TranslateAnswer = ({ content }: TranslateAnswerProps) => {
  const [isTranslateAnswerOpen, setIsTranslateAnswerOpen] = useState(false);
  const { t } = useTranslation('lawyer');
  const {
    mutate: translateAnswerMutate,
    data: translateAnswerData,
    isPending,
    isSuccess,
    error
  } = useMutation({
    mutationFn: (data: string) => translateFromFrenchToEnglish(data)
  });

  useEffect(() => {
    if (isTranslateAnswerOpen) translateAnswerMutate(content);
  }, [content, isTranslateAnswerOpen, translateAnswerMutate]);

  return (
    <>
      <button
        type="button"
        onClick={() => setIsTranslateAnswerOpen(true)}
        className="text-sm text-purple-900 mt-2 font-medium font-sans hover:opacity-70 justify-end self-end"
      >
        {t('account.lawyer.question.answer.textarea.translate')}
      </button>
      <LayoutModal
        isModalOpen={isTranslateAnswerOpen}
        handleModalClose={() => setIsTranslateAnswerOpen(false)}
      >
        {isPending && <LoadingSpinner />}
        {error && (
          <div>{t('account.lawyer.question.answer.translate.error')}</div>
        )}
        {isSuccess && <SanitizedContent content={translateAnswerData!.text} />}
      </LayoutModal>
    </>
  );
};

export default TranslateAnswer;
