import { Tab, TabList } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { filter } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  getCompletionStatusByFields,
  getCustomerCseProfile
} from 'services/cse';

import {
  CseProfileCompletion,
  CseProfileCompletionStep
} from 'pages/cse/components';

import { FUNCTIONING_FIELDS, GENERAL_FIELDS, RULES_FIELDS } from './constants';

const CseProfileCompletionStepper = () => {
  const { t } = useTranslation('customer');
  const { data: cseProfile } = useQuery({
    queryKey: ['customer-cse-profile'],
    queryFn: () => getCustomerCseProfile()
  });

  if (!cseProfile) return <div />;

  const generalStatus = getCompletionStatusByFields(GENERAL_FIELDS, cseProfile);
  const rulesStatus = getCompletionStatusByFields(RULES_FIELDS, cseProfile);
  const functioningStatus = getCompletionStatusByFields(
    FUNCTIONING_FIELDS,
    cseProfile
  );

  const countCompletedStep = filter(
    [generalStatus, functioningStatus, rulesStatus],
    (item) => item === 'complete'
  ).length;

  return (
    <TabList className="flex md:flex-col min-w-72 bg-white font-medium md:h-screen">
      <CseProfileCompletion
        percent={cseProfile?.completion_percentage || 0}
        totalStepCompleted={countCompletedStep}
      />
      <Tab className="flex gap-2 text-left p-4 outline-none hover:bg-gray-200 border-purple-800 data-[selected]:border-b-2 sm:data-[selected]:border-b-0 sm:data-[selected]:bg-gray-200 data-[selected]:font-bold w-full sm:w-auto">
        {t('admin.cseProfile.update.general.title')}
        <CseProfileCompletionStep status={generalStatus} />
      </Tab>
      <Tab className="flex gap-2 text-left p-4 outline-none hover:bg-gray-200 border-purple-800 data-[selected]:border-b-2 sm:data-[selected]:border-b-0 sm:data-[selected]:bg-gray-200 data-[selected]:font-bold w-full sm:w-auto">
        {t('admin.cseProfile.update.functioning.title')}
        <CseProfileCompletionStep status={functioningStatus} />
      </Tab>
      <Tab className="flex gap-2 text-left p-4 outline-none hover:bg-gray-200 border-purple-800 data-[selected]:border-b-2 sm:data-[selected]:border-b-0 sm:data-[selected]:bg-gray-200 data-[selected]:font-bold w-full sm:w-auto">
        {t('admin.cseProfile.update.rules.title')}
        <CseProfileCompletionStep status={rulesStatus} />
      </Tab>
    </TabList>
  );
};

export default CseProfileCompletionStepper;
