import { TFunctionTranslationFormValidation } from 'schemas';
import * as yup from 'yup';

export const ACCEPTED_CSE_FILE_EXTENSIONS = {
  'application/msword': ['.doc'],
  'application/pdf': ['.pdf'],
  'application/x-pdf': ['.pdf'],
  'application/vnd.oasis.opendocument.text': ['.odt'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx'
  ]
};

const luhnCheck = (num?: string) => {
  if (!num) {
    return false;
  }
  const arr = `${num}`
    .split('')
    .reverse()
    .map((x) => parseInt(x, 10));
  const lastDigit = arr.splice(0, 1)[0];
  const sum = arr.reduce(
    (acc, val, i) => (i % 2 === 0 ? acc + ((val * 2) % 9) : acc + val),
    0
  );
  return (sum + lastDigit) % 10 === 0;
};

export const updateCseProfileSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    siren: yup
      .string()
      .nullable()
      .matches(/^[0-9]{9}$/, t('schema.cseProfile.siren.invalid'))
      .test('is-valid-siren', t('schema.cseProfile.siren.invalid'), (value) =>
        value ? luhnCheck(value) : false
      )
      .nullable(),
    employeesCount: yup
      .number()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      )
      .min(1)
      .nullable(),
    cseMembersCount: yup
      .number()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      )
      .min(1)
      .nullable(),
    mandateDurationYear: yup
      .number()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      )
      .min(1)
      .nullable(),
    unionDelegateCount: yup
      .number()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      )
      .min(0)
      .nullable(),
    unionSectionRepresentativesCount: yup
      .number()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      )
      .min(0)
      .nullable()
  });
