import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Title } from '../../../../components';
import {
  BulkUpdateMembersResponse,
  bulkUpdateMembers
} from '../../../../services/admin/members';
import { SetState } from '../../../../types/core';
import { DocumentDropzone } from '../../../customer/account/consultations/booking';
import { Document } from '../../../customer/account/consultations/booking/DocumentDropzone';
import { Paragraph } from '../../../shared/body';
import { Button } from '../../../shared/buttons';
import { ImportSteps } from './steps';

const FileSelectStep = ({
  setStep,
  file,
  setFile,
  setBulkUpdateMembersResponse
}: {
  setStep: SetState<ImportSteps>;
  file?: Document;
  setFile: SetState<Document | undefined>;
  setBulkUpdateMembersResponse: SetState<BulkUpdateMembersResponse | undefined>;
}) => {
  const { t } = useTranslation('customer');
  const [error, setError] = useState<string>();
  useEffect(() => {
    setError(undefined);
  }, [file]);
  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      bulkUpdateMembers({
        customerMembersFile: file!.file,
        dryRun: true
      }),
    onSuccess: (data: BulkUpdateMembersResponse) => {
      setBulkUpdateMembersResponse(data);
      setStep('import-recap');
    },
    onError: (e: any) => {
      if (e?.response?.status === 400) {
        setError(e?.response?.data.message);
      } else {
        toast.success(t('admin.collaborators.form.add.error.generic'));
      }
    }
  });
  return (
    <>
      <Title
        className="!font-bold"
        variant="h3"
        text={t('admin.collaborators.form.bulk.step.fileSelect.title')}
      />
      <div className="my-8">
        <Paragraph size="sm">
          {t('admin.collaborators.form.bulk.step.fileSelect.description1')}
        </Paragraph>
        <Paragraph size="sm">
          {t('admin.collaborators.form.bulk.step.fileSelect.description2')}
        </Paragraph>
        <div className="flex flex-col gap-6 mt-4">
          <DocumentDropzone
            required
            label=""
            typeLabel="file.upload.4"
            document={file}
            setDocument={setFile}
            accept={{ 'application/xlsx': ['.xlsx'] }}
          />
        </div>
        <div className="text-left text-sm text-red-800">{error}</div>
      </div>
      <div className="flex gap-4 justify-end mt-2">
        <Button
          onClick={() => setStep('extract-download')}
          variant="secondary"
          size="medium"
          label={t('admin.collaborators.form.bulk.button.previous')}
        />
        <Button
          submit
          isLoading={isPending}
          onClick={mutate}
          disabled={!file}
          variant="custom"
          size="medium"
          label={t('admin.collaborators.form.bulk.step.fileSelect.submit')}
          customClassNames="bg-purple-900 hover:bg-purple-900 text-white justify-center font-medium w-fit"
        />
      </div>
    </>
  );
};

export default FileSelectStep;
