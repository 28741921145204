import { Cog6ToothIcon } from '@heroicons/react/24/outline';

import { useLawyer } from 'hooks';

import { useAuth } from 'context';

import { ProfileMenu } from './components';
import { IMenuItem, PageName } from './constants';

const LawyerProfileMenu = () => {
  const { logout } = useAuth();
  const { first_name: firstName, last_name: lastName } = useLawyer();
  const accountTabs: IMenuItem[] = [
    {
      name: PageName.Profile,
      href: '/account/profile',
      icon: Cog6ToothIcon
    }
  ];

  return (
    <ProfileMenu
      logout={logout}
      label={(firstName ?? '') + (lastName ?? '')}
      items={accountTabs}
    />
  );
};

export default LawyerProfileMenu;
