import { Fade } from 'components/transition';
import { range } from 'lodash';

const CollaboratorListSkeleton = () => (
  <Fade>
    <div className="animate-pulse p-4">
      <div className="grid grid-cols-5 gap-4">
        {range(50).map((key) => (
          <div key={key} className="h-6 bg-gray-300 rounded" />
        ))}
      </div>
    </div>
  </Fade>
);

export default CollaboratorListSkeleton;
