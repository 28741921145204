import { Card } from 'components';
import { PropsWithChildren, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { LawyerMember } from 'services/lawyerMember';
import { LawyerTag } from 'services/tag';
import { useDebounceValue } from 'usehooks-ts';

import TagAutocomplete from 'features/tag/LawyerTagAutocomplete';

import MemberAutocomplete from '../member/LawyerMemberAutocomplete';

export type QuestionsFiltersInputs = {
  content: string;
  page: number;
  member: LawyerMember | null;
  tag: LawyerTag | null;
};

const formatToUrl = (filters: QuestionsFiltersInputs) => {
  const params: Record<string, string> = {
    content: filters.content,
    page: String(filters.page),
    member: filters.member?.id ? JSON.stringify(filters.member) : '',
    tag: filters.tag?.id ? JSON.stringify(filters.tag) : ''
  };

  const cleanParams = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value)
  );

  return cleanParams;
};

const LawyerQuestionsFilters = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation('lawyer');
  const [searchParams, setSearchParams] = useSearchParams();
  const urlContent = searchParams.get('content') || '';
  const defaultMember = searchParams.get('member')
    ? JSON.parse(searchParams.get('member') || '')
    : null;
  const defaultTag = searchParams.get('tag')
    ? JSON.parse(searchParams.get('tag') || '')
    : null;

  const { register, watch, setValue, reset, control } =
    useForm<QuestionsFiltersInputs>({
      defaultValues: {
        content: urlContent,
        page: Number(searchParams.get('page')) || 1,
        member: defaultMember,
        tag: defaultTag
      }
    });

  const filters = watch();
  const [debouncedSearch] = useDebounceValue(filters.content, 500);

  useEffect(() => {
    if (
      filters.content !== urlContent ||
      filters.member !== defaultMember ||
      filters.tag !== defaultTag
    ) {
      setValue('page', 1);
    }
  }, [filters.content, filters.member, filters.tag, setValue]);

  useEffect(() => {
    setSearchParams(formatToUrl(filters), { replace: true });
  }, [JSON.stringify(filters), setSearchParams]);

  const cleanFilters = () => {
    reset({ content: '', page: 1, member: null, tag: null });
  };

  return (
    <>
      <Card className="rounded-none !border !border-b-gray-600">
        <form className="flex gap-8">
          <div>
            <label htmlFor="search" className="font-medium text-gray-700 mb-1">
              {t('account.lawyer.requests.filters.search.label')}
            </label>
            <input
              type="text"
              id="content"
              className="block w-52 rounded-md shadow-sm focus:border-purple-600 focus:ring-purple-600 sm:text-sm"
              {...register('content')}
            />
          </div>

          <div>
            <label htmlFor="member" className="font-medium text-gray-700 mb-1">
              {t('account.lawyer.requests.filters.member.label')}
            </label>
            <Controller
              control={control}
              name="member"
              render={({ field }) => <MemberAutocomplete {...field} />}
            />
          </div>

          <div>
            <label htmlFor="tag" className="font-medium text-gray-700 mb-1">
              {t('account.lawyer.requests.filters.tag.label')}
            </label>
            <Controller
              control={control}
              name="tag"
              render={({ field }) => <TagAutocomplete {...field} />}
            />
          </div>
        </form>
      </Card>
      {typeof children === 'function'
        ? children({
            ...filters,
            content: debouncedSearch,
            onChangePage: (page: number) => setValue('page', page),
            cleanFilters
          })
        : children}
    </>
  );
};

export default LawyerQuestionsFilters;
