import { ClockIcon } from '@heroicons/react/24/outline';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MemberConsultation } from 'services/consultation';

interface ConsultationDropdownProps {
  canReschedule: MemberConsultation['can_reschedule'];
  canCancel: MemberConsultation['can_cancel'];
  questionId: MemberConsultation['question_id'];
  consultationId: MemberConsultation['id'];
  onCancelClick: () => void;
}

const ConsultationDropdown = ({
  questionId,
  consultationId,
  canReschedule,
  canCancel,
  onCancelClick
}: ConsultationDropdownProps) => {
  const { t } = useTranslation('customer');

  return (
    <div className="flex flex-col md:flex-row justify-end pt-6 gap-2 self-end text-sm">
      {canReschedule && (
        <Link
          to={`/account/consultations/booking?questionId=${questionId}&consultationId=${consultationId}`}
          className="group flex w-fit border border-gray-800 items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-gray-800/10"
        >
          <ClockIcon className="size-4 fill-white/30" />
          {t('account.consultation.button.reschedule')}
        </Link>
      )}
      {canCancel && (
        // eslint-disable-next-line react/button-has-type
        <button
          onClick={onCancelClick}
          className="border border-red-800 text-red-800 w-fit flex items-center gap-2 rounded-lg py-1 px-3 data-[focus]:bg-red-800/10"
        >
          <XCircleIcon className="size-4 fill-red-800" />
          {t('account.consultation.button.cancel')}
        </button>
      )}
    </div>
  );
};

export default ConsultationDropdown;
