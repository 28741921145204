import { Section } from 'components';
import { useTranslation } from 'react-i18next';

import { CollaboratorsList } from 'features/admin/members';
import { PageLayout } from 'features/customer/account/content';
import { Paragraph } from 'features/shared/body';

const CollaboratorsPage = () => {
  const { t } = useTranslation('customer');

  return (
    <PageLayout>
      <Section title={t('admin.collaborators.title')}>
        <Paragraph
          className="text-gray-550 font-normal"
          text={t('admin.collaborators.section.upload.title')}
        />
        <CollaboratorsList />
      </Section>
    </PageLayout>
  );
};

export default CollaboratorsPage;
