import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Button } from 'features/shared/buttons';

import { Fade } from '../../../components/transition';
import { notifyIncompleteProfile } from '../../../services/cse';

const NotifyMissingCustomerCseProfileButton = () => {
  const { t } = useTranslation('customer');
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: notifyIncompleteProfile,
    onSuccess: () =>
      toast.success(t('account.request.missingCseProfile.notify.success')),
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message ??
        t('account.request.missingCseProfile.notify.error');
      toast.error(errorMessage);
    }
  });
  return (
    <Fade>
      {!isSuccess && (
        <Button
          variant="outline"
          size="small"
          label={t('account.request.missingCseProfile.notify.button')}
          isLoading={isPending}
          onClick={mutate}
        />
      )}
    </Fade>
  );
};

export default NotifyMissingCustomerCseProfileButton;
