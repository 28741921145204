import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { Title } from 'components';
import { GuaranteesSkeleton } from 'components/skeleton';
import { THEMATICS } from 'constants/thematics';
import { useTranslation } from 'react-i18next';
import { getAuthenticatedMemberInfo } from 'services/member';

import { Paragraph } from 'features/shared/body';

const ThematicList = () => {
  const { t } = useTranslation('customer');
  const { data: member, isLoading: isMemberLoading } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });

  if (isMemberLoading) return <GuaranteesSkeleton />;

  return (
    <div className="flex gap-6 flex-col max-w-4xl mt-12">
      {THEMATICS.map(
        (thematic) =>
          (thematic.id !== 'labor' ||
            (thematic.id === 'labor' && member?.has_labor_law_access)) && (
            <div>
              <Title variant="h2">{t(thematic.title as any)}</Title>
              <Paragraph className="prose -mt-2 text-gray-550">
                {t(thematic.description as any)}
              </Paragraph>
              <Disclosure as="div">
                {({ open }) => (
                  <>
                    <DisclosureButton className="flex gap-6 items-center justify-between rounded-lg py-6 text-left font-semibold">
                      <span>
                        Quelques exemples de questions que vous pourriez vous
                        poser
                      </span>
                      {open ? (
                        <MinusIcon className="h-5 w-5" />
                      ) : (
                        <PlusIcon className="h-5 w-5" />
                      )}
                    </DisclosureButton>
                    <DisclosurePanel className="prose">
                      <ul className="-mt-4 list-decimal">
                        {thematic.samples.map((sample) => (
                          <li>{t(sample as any)}</li>
                        ))}
                      </ul>
                    </DisclosurePanel>
                  </>
                )}
              </Disclosure>
            </div>
          )
      )}
    </div>
  );
};

export default ThematicList;
