import { CseProfile } from 'services/cse';

export const GENERAL_FIELDS: (keyof CseProfile)[] = [
  'siren',
  'employees_count',
  'cse_members_count'
];

export const FUNCTIONING_FIELDS: (keyof CseProfile | (keyof CseProfile)[])[] = [
  'mandate_duration_year',
  'last_election_date',
  'union_delegate_count',
  'union_section_representatives_count',
  ['cse_documents', 'cse_documents_not_applicable']
];

export const RULES_FIELDS: (keyof CseProfile | (keyof CseProfile)[])[] = [
  ['company_documents', 'company_documents_not_applicable'],
  ['internal_rules_documents', 'internal_rules_documents_not_applicable']
];
