import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/solid';
import { Section, Title } from 'components';
import { Fade } from 'components/transition';
import FrequentlyAskedQuestions from 'containers/faq/FrequentlyAskedQuestions';
import { useTranslation } from 'react-i18next';

import { PageLayout } from 'features/customer/account/content';
import { Paragraph } from 'features/shared/body';
import { LinkedButton } from 'features/shared/buttons';
import { WhatsappIcon } from 'features/shared/icons';

export default function ContactPage() {
  const { t: tForm } = useTranslation('form');
  const { t } = useTranslation('customer');

  return (
    <PageLayout>
      <Section color="white">
        <Fade>
          <FrequentlyAskedQuestions />
          <Title variant="h1">{t('account.contact.title')}</Title>
          <Paragraph weight="normal" className="my-4 max-w-4xl md:my-8">
            {t('account.contact.paragraph1')}
            {t('account.contact.paragraph2')}
          </Paragraph>
          <div className="flex flex-col gap-4 justify-center px-4">
            <LinkedButton
              variant="secondary"
              className="md:max-w-md self-center"
              size="large"
              fullWidth
              label={tForm('label.email')}
              href="mailto:service-client@ekie.co"
              icon={<EnvelopeIcon className="h-5 w-5" />}
            />
            <LinkedButton
              className="md:max-w-md"
              variant="secondary"
              size="large"
              fullWidth
              label={t('account.contact.phone')}
              href="sms:+33644644109"
              icon={<PhoneIcon className="h-5 w-5" />}
            />
            <LinkedButton
              className="md:max-w-md"
              variant="secondary"
              size="large"
              fullWidth
              isExternal
              label={t('account.contact.whatsapp')}
              href="https://api.whatsapp.com/send?phone=33644644109"
              icon={<WhatsappIcon className="h-5 w-5" />}
            />
          </div>
        </Fade>
      </Section>
    </PageLayout>
  );
}
