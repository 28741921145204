import { ConsultationCancelIntent } from 'types/consultation';

export const consultationCancelIntents: {
  label: string;
  code: ConsultationCancelIntent;
}[] = [
  {
    label: 'Avocat non disponible',
    code: ConsultationCancelIntent.LAWYER_UNAVAILABLE
  },
  {
    label: 'Avocat refuse la consultation',
    code: ConsultationCancelIntent.LAWYER_RECUSED
  },
  // {
  //   label: "Avocat ne s'est pas présenté",
  //   code: ConsultationCancelIntent.LAWYER_NOSHOW
  // },
  {
    label: 'Utilisateur non disponible',
    code: ConsultationCancelIntent.CUSTOMER_UNAVAILABLE
  },
  // {
  //   label: 'Utilisateur non satisfait',
  //   code: ConsultationCancelIntent.CUSTOMER_DISPUTE
  // },
  {
    label: "Utilisateur ne s'est pas présenté",
    code: ConsultationCancelIntent.CUSTOMER_NOSHOW
  },
  {
    label: 'Autre',
    code: ConsultationCancelIntent.OTHER
  }
];
