import { useQuery } from '@tanstack/react-query';
import { getFormalNoticeByQuestion } from 'services/formal-notice';
import { LawyerQuestion } from 'services/lawyerQuestion';

import { LoadingSpinner } from 'features/shared/loading';

import PreviewFormalNotice from './components/PreviewFormalNotice';

interface LawyerPreviewFormalNoticeProps {
  questionId: LawyerQuestion['id'];
}

const LawyerPreviewFormalNotice = ({
  questionId
}: LawyerPreviewFormalNoticeProps) => {
  const {
    data: formalNotice,
    isPending,
    isSuccess
  } = useQuery({
    queryKey: ['question-formal-notice', questionId],
    queryFn: () => getFormalNoticeByQuestion(questionId),
    refetchOnMount: true
  });

  if (isPending || !isSuccess) return <LoadingSpinner />;

  return <PreviewFormalNotice formalNotice={formalNotice} />;
};

export default LawyerPreviewFormalNotice;
