import { apiPrivatePythonClient } from './axios';
import { LawyerQuestion } from './lawyerQuestion';
import { Note } from './rating';

export type QuestionAnswer = {
  id: number;
  question_id: number;
  published_at: string;
  content: string;
  additional_request_id: string | null;
  rating_link_id: string | null;
  note: Note | null;
  rating_comment: string | null;
};

type AnswerStatus = 'draft' | 'published' | 'taken_down' | 'pending_moderation';

export type LawyerQuestionAnswer = {
  id: number;
  question_id: number;
  published_at: string;
  content: string;
  additional_request_id: string | null;
  note: number | null;
  rating_comment: string | null;
  status: AnswerStatus;
};

export type LawyerDraftAnswer = {
  id: number;
  has_legal_grounds: boolean;
};

export type LawyerDraftAnswerResponse = {
  saved_at: Date;
} & LawyerDraftAnswer;

export type SaveDraftAnswerPayload = {
  content: string;
};

export async function getMemberQuestionAnswer(
  answerId: QuestionAnswer['id']
): Promise<QuestionAnswer> {
  const { data } = await apiPrivatePythonClient.get(
    `/member/answers/${answerId}`
  );

  return data;
}

export async function getLawyerQuestionAnswer(
  questionId: LawyerQuestion['id']
): Promise<LawyerQuestionAnswer> {
  const { data } = await apiPrivatePythonClient.get(
    `/lawyer/questions/${questionId}/answer`
  );

  return data;
}

export async function saveDraftAnswer(
  questionId: LawyerQuestion['id'],
  payload: SaveDraftAnswerPayload
): Promise<LawyerDraftAnswerResponse> {
  const { data } = await apiPrivatePythonClient.put<LawyerDraftAnswer>(
    `/lawyer/questions/${questionId}/answer`,
    payload
  );

  return {
    ...data,
    saved_at: new Date()
  };
}

export async function submitAnswer(
  questionId: LawyerQuestion['id']
): Promise<void> {
  const { data } = await apiPrivatePythonClient.post(
    `/lawyer/questions/${questionId}/answer/submit`,
    { requires_formal_notice_letter: false }
  );

  return data;
}
