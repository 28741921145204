import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Section, Title } from '../../components';
import CircleProgressBar from '../../components/CircleProgressBar';
import { Fade } from '../../components/transition';
import { getCustomerCseProfile } from '../../services/cse';

const HomeCseProfileCompletionBanner = () => {
  const { t } = useTranslation('customer');
  const { data: cseProfile } = useQuery({
    queryKey: ['customer-cse-profile'],
    queryFn: () => getCustomerCseProfile()
  });

  if (!cseProfile || cseProfile.is_completed) return <div />;

  return (
    <Fade>
      <Section color="white" gutterBottom>
        <div className="flex items-center justify-between w-full max-w-full gap-6 py-6">
          <div className="flex flex-col items-start">
            <Title variant="h2">
              {t('account.home.cseProfileBanner.title')}
            </Title>
            <p className="text-gray-600 text-sm">
              {t('account.home.cseProfileBanner.description')}
            </p>
            <Link
              className="text-left border border-purple-900 text-purple-900 hover:bg-purple-900 hover:text-white rounded text-mg px-4 py-2 mt-4 focus:outline-none"
              to="/admin/cse-identity-management"
            >
              {t('account.home.cseProfileBanner.button')}
            </Link>
          </div>
          <div className="w-28 h-28">
            <CircleProgressBar percent={cseProfile.completion_percentage} />
          </div>
        </div>
      </Section>
    </Fade>
  );
};

export default HomeCseProfileCompletionBanner;
