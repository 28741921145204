import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { profileSchema } from 'schemas';
import {
  AuthenticatedMemberInfo,
  MemberUpdate,
  updateMember
} from 'services/member';

import { Alert } from 'features/shared/alerts';
import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';

import { ProfileFormInputs } from 'types/inputs';

interface MemberEditProfileProps {
  onFinish: () => void;
  defaultValues: AuthenticatedMemberInfo;
}

const MemberEditProfile = ({
  defaultValues,
  onFinish
}: MemberEditProfileProps) => {
  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isDirty },
    reset
  } = useForm<ProfileFormInputs>({
    resolver: yupResolver(profileSchema(tForm)),
    defaultValues: {
      firstName: defaultValues.first_name || '',
      lastName: defaultValues.last_name || '',
      phoneCellphone: defaultValues.phone_cellphone || '',
      secondaryEmail: defaultValues.secondary_email || null
    }
  });

  const { mutate, isPending } = useMutation({
    mutationKey: ['member-update'],
    mutationFn: (memberUpdate: MemberUpdate) => updateMember(memberUpdate),
    onSuccess: () => {
      toast.success(t('account.profile.update.success'));
      onFinish();
      queryClient.invalidateQueries({
        queryKey: ['authenticated-member-info'],
        refetchType: 'all'
      });
    },
    onError: (error: any) => {
      if (
        error?.response?.status === 400 &&
        error?.response?.data?.code in
          ['email_already_exists', 'secondary_email_equals_primary']
      ) {
        setError('secondaryEmail' as any, {
          message: error?.response?.data?.message,
          type: 'focus'
        });
      } else {
        toast.error(t('error.occurred'));
      }
    }
  });

  const onSubmit = async (data: ProfileFormInputs) => {
    mutate({
      firstName: data.firstName as string,
      lastName: data.lastName as string,
      phoneCellphone: data.phoneCellphone as string,
      secondaryEmail: data.secondaryEmail || null
    });
  };

  const handleResetForm = () => {
    reset();
    onFinish();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
      <div className="grid grid-cols-6 gap-3 sm:gap-6">
        <div className="col-span-6 sm:col-span-3">
          <TextInputGroup
            name="firstName"
            label={tForm('label.firstName')}
            register={register}
            placeholder={tForm('placeholder.firstName')}
            error={errors.firstName}
            disabled={isPending}
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <TextInputGroup
            name="lastName"
            label={tForm('label.lastName')}
            register={register}
            placeholder={tForm('placeholder.lastName')}
            error={errors.lastName}
            disabled={isPending}
          />
        </div>
      </div>
      <TextInputGroup
        name="phoneCellphone"
        label={tForm('label.phone')}
        register={register}
        placeholder="+33601010101"
        error={errors.phoneCellphone}
        disabled={isPending}
      />
      <TextInputGroup
        name="email"
        label={tForm('label.email')}
        register={register}
        defaultValue={defaultValues.email}
        error={undefined}
        disabled
      />
      <TextInputGroup
        name="secondaryEmail"
        label={tForm('label.email.perso')}
        register={register}
        error={errors.secondaryEmail}
        disabled={isPending}
      />
      <Alert className="mt-2">{tForm('description.email.perso')}</Alert>
      <div className="flex justify-end">
        <Button
          onClick={handleResetForm}
          variant="transparent"
          size="small"
          label={t('general.cancel')}
          disabled={isPending}
        />
        <div className="ml-3">
          <Button
            submit
            disabled={!isDirty}
            variant="secondary"
            size="small"
            label={t('general.update')}
            isLoading={isPending}
          />
        </div>
      </div>
    </form>
  );
};

export default MemberEditProfile;
