import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/solid';
import { Title } from 'components';
import { useTranslation } from 'react-i18next';

import { PageLayout } from 'features/customer/account/content';
import { LinkedButton } from 'features/shared/buttons';
import { WhatsappIcon } from 'features/shared/icons';

export default function LawyerCarePage() {
  const { t: tForm } = useTranslation('form');
  const { t } = useTranslation('lawyer');

  return (
    <PageLayout>
      <Title component="h1" variant="h2">
        {t('account.sidebar.lawyercare')}
      </Title>
      <div className="flex flex-col lg:flex-row gap-4">
        <LinkedButton
          label={tForm('label.email')}
          href="mailto:service-lawyercare@ekie.co"
          variant="primary"
          size="large"
          fullWidth
          icon={<EnvelopeIcon className="h-5 w-5" />}
        />
        <LinkedButton
          label={t('account.contact.phone')}
          href="sms:+33644644109"
          variant="primary"
          size="large"
          fullWidth
          icon={<PhoneIcon className="size-5" />}
        />
        <LinkedButton
          isExternal
          label={t('account.contact.whatsapp')}
          href="https://api.whatsapp.com/send?phone=33644644109"
          variant="primary"
          size="large"
          fullWidth
          icon={<WhatsappIcon fill="white" className="h-5 w-5" />}
        />
      </div>
    </PageLayout>
  );
}
