import { useQuery } from '@tanstack/react-query';
import { HeroBanner, Section, Title } from 'components';
import HomeFooter from 'components/HomeFooter';
import { Fade } from 'components/transition';
import { LastArticlesList, LastCSEArticles } from 'containers/articles';
import LastArticlesRead from 'containers/articles/LastArticlesRead';
import RandomUserReview from 'containers/user-review/RandomUserReview';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { getAuthenticatedMemberInfo } from 'services/member';

import { PageLayout } from 'features/customer/account/content';
import useMemberOnboardingChecker from 'features/member/hooks/useMemberOnboardingChecker';
import { LastMemberQuestionReminder } from 'features/questions';

import HomeCseProfileCompletionBanner from '../../features/cse/HomeCseProfileCompletionBanner';

export const SKIP_ALPHABET_BANNER = 'skipAlphabetBanner';

function HomePage() {
  const { t } = useTranslation('customer');
  useMemberOnboardingChecker();
  const { data: member } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });

  const skipAlphabetBanner = localStorage.getItem(SKIP_ALPHABET_BANNER);

  return (
    <PageLayout>
      <Fade>
        {!skipAlphabetBanner && (
          <HeroBanner
            title={t('account.home.h2.alphabet')}
            to="/account/resources/alphabet"
            label={t('account.home.h2.alphabet.link')}
            imgSrc="/images/illu_alphabet.png"
            onClick={() => localStorage.setItem(SKIP_ALPHABET_BANNER, 'true')}
          />
        )}
        {skipAlphabetBanner &&
          member?.is_active_cse_member &&
          member.is_admin &&
          isEqual(
            process.env.REACT_APP_EKIE_CSE_IDENTITY_AVAILIBILITY,
            '1'
          ) && <HomeCseProfileCompletionBanner />}
        <LastMemberQuestionReminder />
        <Section color="white" gutterBottom>
          <Title className="ml-1.5" variant="h2">
            {t('account.home.h2.articles')}
          </Title>
          <LastArticlesList withLaborLaw={member?.has_labor_law_access} />
          <RandomUserReview />
          <LastArticlesRead />
          {member?.is_active_cse_member && (
            <Fade>
              <Title className="ml-1.5" variant="h2">
                {t('account.home.h2.cse')}
              </Title>
              <LastCSEArticles />
            </Fade>
          )}
        </Section>
        <Section color="white">
          <HomeFooter />
        </Section>
      </Fade>
    </PageLayout>
  );
}

export default HomePage;
