import { Switch, SwitchProps } from '@headlessui/react';

interface SwitchInputProps extends SwitchProps {
  label: string;
}

const SwitchInput = ({ label, ...rest }: SwitchInputProps) => (
  <div className="flex group items-center">
    <Switch
      {...rest}
      className="relative group bg-gray-500 inline-flex h-[24px] w-[48px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 data-[checked]:bg-purple-800 data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50"
    >
      <span
        aria-hidden="true"
        className="pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out translate-x-0 group-data-[checked]:translate-x-6"
      />
    </Switch>
    <label className="font-sans text-black ml-2.5">{label}</label>
  </div>
);

export default SwitchInput;
