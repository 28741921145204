import { useInfiniteQuery } from '@tanstack/react-query';

import { Videos } from 'types/cms';

import { graphQLRequest } from './dato';

export const YOUTUBE_VIDEOS_BY_PAGE = 10;

const GET_PAGINATED_VIDEOS_QUERY = `
  query GetVideos($skip: IntType, $first: IntType = 8) {
    allVideos(
      first: $first,
      skip: $skip
    ) {
      id
      video {
        thumbnailUrl
        title
        providerUid
      }
    }
  }
`;

export const useGetVideos = () =>
  useInfiniteQuery({
    queryKey: ['videos'],
    queryFn: ({ pageParam = 0 }): Promise<Videos | null> =>
      graphQLRequest(GET_PAGINATED_VIDEOS_QUERY, {
        skip: pageParam,
        first: YOUTUBE_VIDEOS_BY_PAGE
      }),
    initialPageParam: 0,
    getNextPageParam: (_, pages) => {
      const nextCursor =
        pages.length === 1
          ? YOUTUBE_VIDEOS_BY_PAGE
          : pages.length * YOUTUBE_VIDEOS_BY_PAGE;

      return nextCursor;
    }
  });
