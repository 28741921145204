import { useTranslation } from 'react-i18next';
import { Document } from 'services/cse';

interface FilesListProps {
  files: Document[];
  onDelete: (id: string) => void;
  onClick: (id: string) => void;
}

const FilesList = ({ files, onDelete, onClick }: FilesListProps) => {
  const { t } = useTranslation('customer');

  return (
    <ul className="space-y-2">
      {files.map((file) => (
        <li
          key={file.external_id}
          className="flex justify-between items-center p-2 bg-gray-100 rounded-lg"
        >
          <button
            type="button"
            onClick={() => onClick(file.external_id)}
            className="text-sm text-gray-700"
          >
            {file.file_name} {(file.file_size / (1024 * 1024)).toFixed(2)} Mo
          </button>
          <button
            onClick={() => onDelete(file.external_id)}
            type="button"
            className="ml-2 px-2 py-1 text-xs rounded text-red-800"
          >
            {t('admin.cseProfile.update.form.files.delete')}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default FilesList;
