import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { Chip, Title } from 'components';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { getLawyerConsultations } from 'services/lawyerConsultation';

import {
  LawyerPastConsultations,
  LawyerScheduledConsultations,
  LawyerToCompleteConsultations
} from 'features/consultation';
import { PageLayout } from 'features/customer/account/content';

import { ConsultationStatus, ConsultationsFilter } from 'types/consultation';

const TABS = ['scheduled', 'toComplete', 'past'];

const ConsultationsPage = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('lawyer');
  const defaultStatus =
    (searchParams.get('tab') as ConsultationsFilter) ||
    ConsultationsFilter.SCHEDULED;

  const { data: consultationsScheduledResponse } = useQuery({
    queryKey: ['lawyer-consultations-scheduled'],
    queryFn: () =>
      getLawyerConsultations({
        completable: false,
        status: [ConsultationStatus.SCHEDULED]
      })
  });
  const { data: consultationsToCompleteResponse } = useQuery({
    queryKey: ['lawyer-consultations-to-complete'],
    queryFn: () =>
      getLawyerConsultations({
        completable: true,
        status: [ConsultationStatus.SCHEDULED]
      })
  });

  return (
    <PageLayout>
      <Title variant="h2" gutterBottom component="h1">
        {t('account.consultations.title')}
      </Title>
      <TabGroup className="w-full" defaultIndex={TABS.indexOf(defaultStatus)}>
        <TabList className="flex border-b border-gray-300">
          <Tab className="flex items-center px-4 py-2 gap-2 text-sm font-semibold rounded-t-lg transition-all duration-200 border-b-2 border-transparent focus:outline-none data-[selected]:border-purple-800 data-[selected]:text-purple-800 data-[selected]:bg-white hover:text-purple-600 data-[selected]:shadow-md data-[selected]:translate-y-[-2px]">
            {t('account.consultations.tabs.scheduled')}
            <Chip color="purple" className="z-20">
              {consultationsScheduledResponse?.total}
            </Chip>
          </Tab>
          <Tab className="flex items-center px-4 py-2 gap-2 text-sm font-semibold rounded-t-lg transition-all duration-200 border-b-2 border-transparent focus:outline-none data-[selected]:border-purple-800 data-[selected]:text-purple-800 data-[selected]:bg-white hover:text-purple-600 data-[selected]:shadow-md data-[selected]:translate-y-[-2px]">
            {t('account.consultations.tabs.toComplete')}
            <Chip color="purple" className="z-20">
              {consultationsToCompleteResponse?.total}
            </Chip>
          </Tab>
          <Tab className="flex items-center px-4 py-2 text-sm font-semibold rounded-t-lg transition-all duration-200 border-b-2 border-transparent focus:outline-none data-[selected]:border-purple-800 data-[selected]:text-purple-800 data-[selected]:bg-white hover:text-purple-600 data-[selected]:shadow-md data-[selected]:translate-y-[-2px]">
            {t('account.consultations.tabs.past')}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel className="py-4 -mt-1">
            <LawyerScheduledConsultations />
          </TabPanel>
          <TabPanel className="py-4 -mt-1">
            <LawyerToCompleteConsultations />
          </TabPanel>
          <TabPanel className="py-4 -mt-1">
            <LawyerPastConsultations />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </PageLayout>
  );
};

export default ConsultationsPage;
