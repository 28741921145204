import { useMutation } from '@tanstack/react-query';
import { Document, getLawyerCseDocument } from 'services/cse';
import { LawyerQuestion } from 'services/lawyerQuestion';

interface CseDocumentProps {
  questionId: LawyerQuestion['id'];
  document: NonNullable<Document>;
}

const CseDocument = ({ questionId, document }: CseDocumentProps) => {
  const { mutate, isError } = useMutation({
    mutationKey: ['lawyer-cse-document', document.external_id],
    mutationFn: async () => {
      const data = await getLawyerCseDocument(questionId, document.external_id);
      window.open(data.url, '_blank');

      return data;
    },
    throwOnError: false
  });

  if (!document) return <div />;
  if (isError) return <span>'Impossible de récupérer le document.'</span>;

  return (
    <button type="button" onClick={() => mutate()} className="underline">
      {document.file_name}
    </button>
  );
};

export default CseDocument;
