import { TabGroup } from '@headlessui/react';
import { ErrorBoundary } from '@sentry/react';
import { Title } from 'components';
import FeatureErrorCard from 'components/card/FeatureErrorCard';
import { useTranslation } from 'react-i18next';

import CseProfileCompletionStepper from 'features/cse/CseProfileCompletionStepper';
import UpdateCseProfile from 'features/cse/UpdateCseProfile';
import { PageLayout } from 'features/customer/account/content';
import { Alert } from 'features/shared/alerts';

const CseProfileManagementPage = () => {
  const { t } = useTranslation('customer');

  return (
    <PageLayout noPadding>
      <TabGroup vertical className="md:flex border-x border-gray-400">
        <ErrorBoundary
          fallback={
            <FeatureErrorCard
              title={t('admin.cseProfile.update.error.title')}
            />
          }
        >
          <CseProfileCompletionStepper />
        </ErrorBoundary>
        <ErrorBoundary
          fallback={
            <FeatureErrorCard
              title={t('admin.cseProfile.update.error.title')}
            />
          }
        >
          <div className="p-4 w-full">
            <Title
              gutterBottom
              variant="h1"
              text={t('admin.cseProfile.update.title')}
            />
            <Alert
              title={t('admin.cseProfile.update.protection.title')}
              type="info"
              className="my-4"
            >
              {t('admin.cseProfile.update.protection.description')}
            </Alert>
            <UpdateCseProfile />
          </div>
        </ErrorBoundary>
      </TabGroup>
    </PageLayout>
  );
};

export default CseProfileManagementPage;
