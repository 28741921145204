import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { LogoutUnfilledIcon } from 'features/shared/icons';

import { IMenuItem } from '../constants';
import LinkNav from './LinkNav';

interface ProfileMenuProps {
  label: string;
  items: IMenuItem[];
  logout: MouseEventHandler<HTMLButtonElement>;
}

const ProfileMenu = ({ label, items, logout }: ProfileMenuProps) => {
  const { t } = useTranslation('customer');

  return (
    <Menu as="div" className="relative w-full py-4 mb-4 block text-left">
      <MenuButton className="flex w-full items-center justify-between rounded-md bg-gray-paper px-4 py-2 text-sm font-medium text-black">
        {({ open }) => (
          <>
            <div className="flex flex-col font-sans font-bold text-base truncate overflow-hidden whitespace-nowrap">
              {label}
            </div>
            {open ? (
              <XMarkIcon strokeWidth={1.5} className="size-5" />
            ) : (
              <ChevronUpDownIcon className="size-5" />
            )}
          </>
        )}
      </MenuButton>
      <MenuItems
        anchor={{ gap: 16, to: 'top' }}
        className={classNames(
          'w-64 px-4 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
        )}
      >
        <MenuItem>
          <button
            type="button"
            onClick={logout}
            className="flex w-full my-1 px-3 py-2 text-base items-center stroke-black rounded-lg max-w-64 hover:bg-gray-300"
          >
            <LogoutUnfilledIcon />
            <span className="ml-2 flex gap-2 items-center">
              {t(`account.sidebar.logout`)}
            </span>
          </button>
        </MenuItem>

        {items.map((item) => (
          <MenuItem key={item.name}>
            <LinkNav translation="customer" item={item} />
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
};

export default ProfileMenu;
