import { PropsWithChildren } from 'react';
import { FormalNotice } from 'services/formal-notice';

import SanitizedContent from 'features/customer/question/SanitizedContent';

interface PreviewFormalNoticeProps {
  formalNotice: FormalNotice;
}

const PreviewFormalNotice = ({
  formalNotice,
  children
}: PropsWithChildren<PreviewFormalNoticeProps>) => (
  <div className="w-full text-xs aspect-[210/297] bg-white p-8 shadow-lg border border-gray-300 mx-auto flex flex-col gap-6">
    <div className="whitespace-pre-line font-serif">
      {formalNotice.sender_paragraph}
      <div className="text-right">{formalNotice?.recipient_paragraph}</div>
      <div className="text-right mt-4">
        {formalNotice?.date_and_place_paragraph}
      </div>
      <span className="underline">
        {formalNotice?.formal_notice_label_paragraph}
      </span>
    </div>
    <SanitizedContent
      className="text-xs font-serif text-justify max-w-full"
      content={formalNotice?.body}
    />
    <div className="whitespace-pre-line font-serif">
      {formalNotice?.signature_paragraph}
    </div>
    {children}
  </div>
);

export default PreviewFormalNotice;
