import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Card } from 'components';
import { CardContent } from 'components/card';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { generateFormalNotice } from 'services/formal-notice';
import { LawyerQuestion } from 'services/lawyerQuestion';

import { LoadingSpinner } from 'features/shared/loading';

interface GenerateFormalNoticeProps {
  questionId: LawyerQuestion['id'];
  onError?: () => void;
}

const GenerateFormalNotice = ({
  questionId,
  onError
}: GenerateFormalNoticeProps) => {
  const { t } = useTranslation('lawyer');
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: () => generateFormalNotice(questionId),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['lawyer-question', questionId],
        refetchType: 'all'
      }),
    onError: () => {
      toast.error(t('account.lawyer.request.formalNotice.step.loading.error'));
      onError?.();
    }
  });

  useEffect(() => {
    mutate();
  }, []);

  return (
    <Card>
      <CardContent>
        {isPending && (
          <div className="flex flex-col items-center justify-center min-h-96 mx-16 space-y-8">
            <LoadingSpinner large />
            <p className="text-2xl text-center font-semibold text-gray-900 whitespace-pre-line">
              {t('account.lawyer.request.formalNotice.step.loading.title')}
            </p>
            <p className="text-gray-800 text-base text-center">
              {t(
                'account.lawyer.request.formalNotice.step.loading.description'
              )}
            </p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default GenerateFormalNotice;
