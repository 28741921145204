import { XMarkIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';
import toast, { ToastBar, Toaster } from 'react-hot-toast';

const StyledToaster: FC = () => (
  <Toaster
    position="bottom-left"
    toastOptions={{
      position: 'top-right'
    }}
  >
    {(t) => (
      <ToastBar toast={t}>
        {({ icon, message }) => (
          <>
            {icon}
            <div className="m-2">{message}</div>
            {t.type !== 'loading' && (
              <button
                type="button"
                className="inline-flex rounded-md bg-white text-gray-700 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                onClick={() => toast.dismiss(t.id)}
              >
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
          </>
        )}
      </ToastBar>
    )}
  </Toaster>
);

export default StyledToaster;
