import { format } from 'date-fns';
import { isArray, isNil } from 'lodash';

import { apiPrivatePythonClient } from './axios';

export type Document = {
  external_id: string;
  file_name: string;
  file_size: number;
  content_type: string;
  url: string;
};

type ConventionCollective = {
  short_title: string;
  title: string;
  url: string;
};

export type CseProfile = {
  completion_percentage: number;
  is_completed: boolean;
  siren: string | null;
  company_name: string | null;
  employees_count: number | null;
  cse_members_count: number | null;
  convention_collective: ConventionCollective | null;
  mandate_duration_year: number | null;
  last_election_date: string | null;
  union_delegate_count: number | null;
  union_section_representatives_count: number | null;
  cse_documents: Document[];
  cse_documents_not_applicable: boolean;
  company_documents: Document[];
  company_documents_not_applicable: boolean;
  internal_rules_documents: Document[];
  internal_rules_documents_not_applicable: boolean;
};

export async function getCustomerCseProfile(): Promise<CseProfile> {
  const { data } = await apiPrivatePythonClient.get<CseProfile>(
    `member/customers/me/cse-profile`
  );

  return data;
}

type UpdateCseProfilePayload = {
  siren?: string | null;
  employeesCount?: number | null;
  cseMembersCount?: number | null;
  mandateDurationYear?: number | null;
  lastElectionDate?: Date | null;
  unionDelegateCount?: number | null;
  unionSectionRepresentativesCount?: number | null;
  cse?: number | null;
  cseDocumentsNotApplicable: boolean;
  companyDocumentsNotApplicable: boolean;
  internalRulesDocumentsNotApplicable: boolean;
};

export async function updateCseProfile(
  data: UpdateCseProfilePayload
): Promise<void> {
  await apiPrivatePythonClient.put<CseProfile>(
    `member/customers/me/cse-profile`,
    {
      siren: data.siren || null,
      employees_count: data.employeesCount,
      cse_members_count: data.cseMembersCount,
      mandate_duration_year: data.mandateDurationYear,
      last_election_date: data.lastElectionDate
        ? format(data.lastElectionDate, 'yyyy-MM-dd')
        : null,
      union_delegate_count: data.unionDelegateCount,
      union_section_representatives_count:
        data.unionSectionRepresentativesCount,
      cse_documents_not_applicable: data.cseDocumentsNotApplicable,
      company_documents_not_applicable: data.companyDocumentsNotApplicable,
      internal_rules_documents_not_applicable:
        data.internalRulesDocumentsNotApplicable
    }
  );
}

export async function deleteCseFile(id: string): Promise<void> {
  await apiPrivatePythonClient.delete<CseProfile>(
    `member/customers/me/cse-profile/document-files/${id}`
  );
}

type CseFileResponse = {
  url: string;
};

export async function getCseFile(id: string): Promise<CseFileResponse> {
  const { data } = await apiPrivatePythonClient.get(
    `member/customers/me/cse-profile/document-files/${id}`
  );

  return data;
}

type UploadCseFilesPayload = {
  fileName: string;
  type: 'internal_rules_document' | 'company_document' | 'cse_document';
  file: File;
};

export async function uploadCseFile(
  data: UploadCseFilesPayload
): Promise<void> {
  const bin = await data.file.arrayBuffer();
  await apiPrivatePythonClient.post<CseProfile>(
    `member/customers/me/cse-profile/document-files?file_name=${data.fileName}&document_type=${data.type}`,
    bin,
    { headers: { 'Content-Type': data.file.type } }
  );
}

export async function getLawyerCseDocument(
  questionId: number,
  documentId: string
): Promise<Document> {
  const { data } = await apiPrivatePythonClient.get<Document>(
    `lawyer/questions/${questionId}/cse-profile/document-files/${documentId}`
  );

  return data;
}

export async function lawyerNotifyIncompleteCseProfile(
  questionId: number
): Promise<void> {
  await apiPrivatePythonClient.post<Document>(
    `lawyer/questions/${questionId}/notify-incomplete-cse-profile`
  );
}

const isFilled = (value: any): boolean =>
  !(
    isNil(value) ||
    value === false ||
    value === '' ||
    (isArray(value) && value.length === 0)
  );

export const getCompletionStatusByFields = (
  fields: (keyof CseProfile | (keyof CseProfile)[])[],
  profile: CseProfile
): 'complete' | 'empty' | 'partial' => {
  const filledCount = fields.filter((key) => {
    if (isArray(key)) {
      return key.some((field) => isFilled(profile[field]));
    }
    return isFilled(profile[key]);
  }).length;

  if (filledCount === fields.length) return 'complete';
  if (filledCount === 0) return 'empty';

  return 'partial';
};

export async function notifyIncompleteProfile(): Promise<void> {
  await apiPrivatePythonClient.post(
    `member/customers/me/cse-profile/notify-incomplete-profile`
  );
}
