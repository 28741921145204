import { TFunctionTranslationFormValidation } from 'schemas';
import * as yup from 'yup';

export const requestAnswerSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    answerContent: yup
      .string()
      .required(t('schema.requestAnswer.answerContent.required'))
      .min(200, t('schema.requestAnswer.answerContent.tooShort'))
      .max(15000, t('schema.requestAnswer.answerContent.tooLong')),
    formalNoticeContent: yup.string().when('mustCreateFormalNotice', {
      is: true,
      then: (schema) =>
        schema
          .required(t('schema.requestAnswer.formalNoticeContent.required'))
          .min(200, t('schema.requestAnswer.formalNoticeContent.tooShort'))
          .max(15000, t('schema.requestAnswer.formalNoticeContent.tooLong')),
      otherwise: (schema) => schema.notRequired()
    })
  });
