import { Tab, TabProps } from '@headlessui/react';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';

interface RequestPanelTabProps extends TabProps {
  children: ReactNode;
  className?: string;
}

const RequestPanelTab: FC<RequestPanelTabProps> = ({
  children,
  className = '',
  ...props
}) => (
  <Tab
    {...props}
    className={classNames(
      'flex w-[5rem] h-[5rem] p-1 gap-2 flex-col justify-center items-center text-sm font-semibold rounded-r-lg transition-all duration-200 border-l-2 border-transparent focus:outline-none data-[selected]:border-purple-800 data-[selected]:text-purple-800 bg-white hover:text-purple-600 data-[selected]:translate-y-[-2px]',
      className
    )}
  >
    {children}
  </Tab>
);

export default RequestPanelTab;
