import { TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import {
  BuildingOfficeIcon,
  ChatBubbleLeftIcon,
  LinkIcon,
  SparklesIcon
} from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { Divider, Title } from 'components';
import { isEqual } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getLawyerQuestion } from 'services/lawyerQuestion';

import LawyerCseProfile from 'features/cse/LawyerCseProfile';
import LawyerNotifyIncompleteCseProfile from 'features/cse/LawyerNotifyIncompleteCseProfile';
import {
  LawyerMemberQuestionHistory,
  LawyerMemberSimilarQuestions,
  LawyerQuestionsCount
} from 'features/questions';
import { QUESTIONS_STATUSES } from 'features/questions/LawyerQuestionHistory';
import LawyerSimilarQuestionsCount from 'features/questions/LawyerSimilarQuestionsCount';
import QuestionSidePanel from 'features/questions/components/QuestionSidePanel';

import { RequestPanelTab } from './components';

const RequestAnswerPanel = () => {
  const { t } = useTranslation('lawyer');
  const navigate = useNavigate();
  const { questionId = '' } = useParams();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectQuestionId, setSelectedQuestionId] = useState<null | number>(
    null
  );

  const { data: question } = useQuery({
    queryKey: ['lawyer-question', Number(questionId)],
    queryFn: () => getLawyerQuestion(Number(questionId)),
    refetchOnMount: true,
    enabled: !!questionId
  });

  const onClosePanel = () => {
    setSelectedQuestionId(null);
    navigate(`/account/questions/questions-to-answer/${questionId}`);
  };

  if (!question) return <div />;

  const cseProfile = question.customer?.cse_profile || null;

  return (
    <TabGroup
      selectedIndex={selectedIndex}
      onChange={setSelectedIndex}
      className="flex flex-row-reverse"
    >
      <TabList className="flex flex-col border-b border-gray-300">
        {question.ai_suggestion && (
          <RequestPanelTab>
            <SparklesIcon className="size-6" />
            <span className="text-[8px] uppercase leading-tight">
              {t('account.lawyer.question.answer.helper.title')}
            </span>
          </RequestPanelTab>
        )}
        <RequestPanelTab className="relative">
          <ChatBubbleLeftIcon className="size-6" />
          <LawyerQuestionsCount
            status={QUESTIONS_STATUSES}
            memberId={question.member.id}
            className="!absolute top-1 right-4"
          />
          <span className="text-[8px] uppercase leading-tight">
            {t('account.lawyer.questions.tabs.member.title')}
          </span>
        </RequestPanelTab>
        <RequestPanelTab className="relative">
          <LinkIcon className="size-6" />
          <LawyerSimilarQuestionsCount
            questionId={question.id}
            className="!absolute top-1 right-4"
          />
          <span className="text-[8px] uppercase leading-tight">
            {t('account.lawyer.questions.tabs.similar.title')}
          </span>
        </RequestPanelTab>
        {cseProfile &&
          isEqual(
            process.env.REACT_APP_EKIE_CSE_IDENTITY_AVAILIBILITY,
            '1'
          ) && (
            <RequestPanelTab>
              <BuildingOfficeIcon className="size-6" />
              <span className="text-[8px] uppercase leading-tight">
                {t('account.lawyer.request.panel.cseProfile.title')}
              </span>
            </RequestPanelTab>
          )}
      </TabList>
      <TabPanels className="text-sm w-full min-h-96 lg:h-[calc(100vh-22vh)] overflow-auto">
        {question.ai_suggestion && (
          <TabPanel className="p-4 bg-white rounded-l-lg min-h-96">
            <Title variant="h3" className="!text-base">
              {t('account.lawyer.question.answer.helper.title')}
            </Title>
            <Divider />
            <div className="prose text-[14px] whitespace-pre-line">
              {question.ai_suggestion}
            </div>
          </TabPanel>
        )}
        <TabPanel className="p-4 bg-white rounded-l-lg min-h-96">
          <Title variant="h3" className="!text-base">
            {t('account.lawyer.questions.tabs.member.title')}
          </Title>
          <Divider />
          <LawyerMemberQuestionHistory
            onQuestionClick={(id) => setSelectedQuestionId(id)}
            member={question.member}
          />
        </TabPanel>
        <TabPanel className="p-4 bg-white rounded-l-lg min-h-96">
          <Title variant="h3" className="!text-base">
            {t('account.lawyer.questions.tabs.similar.title')}
          </Title>
          <Divider />
          <LawyerMemberSimilarQuestions
            onQuestionClick={(id) => setSelectedQuestionId(id)}
            questionId={question.id}
          />
        </TabPanel>
        {cseProfile && (
          <TabPanel className="p-4 bg-white rounded-l-lg min-h-96">
            <Title variant="h3" className="!text-base">
              {t('account.lawyer.request.panel.cseProfile.title')}
            </Title>
            <Divider />
            <LawyerCseProfile
              questionId={question.id}
              cseProfile={cseProfile}
            />
            {question.customer.can_notify_incomplete_cse_profile && (
              <LawyerNotifyIncompleteCseProfile questionId={question.id} />
            )}
          </TabPanel>
        )}
      </TabPanels>
      <QuestionSidePanel questionId={selectQuestionId} onClose={onClosePanel} />
    </TabGroup>
  );
};

export default RequestAnswerPanel;
