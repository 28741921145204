import { useQuery } from '@tanstack/react-query';
import { Title } from 'components';
import ArrowButtonPagination from 'components/pagination/ArrowButtonPagination';
import BreakPagePagination from 'components/pagination/BreakPagePagination';
import { LawyerRequestsListSkeleton } from 'components/skeleton';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { Link, useSearchParams } from 'react-router-dom';
import {
  LawyerQuestion,
  QuestionStatus,
  getLawyerQuestions
} from 'services/lawyerQuestion';

import {
  LawyerEmptyAssignedQuestions,
  LawyerQuestionOverview
} from './components';

const PAGE_SIZE = 5;
const QUESTIONS_STATUSES: QuestionStatus[] = ['assignment_accepted'];

const LawyerQuestionsToAnswer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('lawyer');
  const currentPage = Number(searchParams.get('page')) || 1;
  const {
    data: questionsResponse,
    isLoading: isQuestionsLoading,
    isSuccess: isQuestionsSuccess
  } = useQuery({
    queryKey: [
      'lawyer-questions',
      { status: QUESTIONS_STATUSES },
      currentPage,
      PAGE_SIZE
    ],
    queryFn: () =>
      getLawyerQuestions({
        status: QUESTIONS_STATUSES,
        pageSize: PAGE_SIZE,
        page: currentPage
      }),
    refetchOnWindowFocus: true,
    placeholderData: (prev) => prev
  });

  if (isQuestionsLoading) return <LawyerRequestsListSkeleton />;
  if (!isQuestionsSuccess) return <div />;

  const isEmpty =
    questionsResponse.total === 0 || questionsResponse.items.length === 0;

  return (
    <div className="flex flex-col gap-4 max-w-[1080px]">
      {isEmpty ? (
        <LawyerEmptyAssignedQuestions />
      ) : (
        <Title component="h1" variant="h2" className="!font-bold">
          {t('account.lawyer.requests.toAnswer.title', {
            count: questionsResponse.total
          })}
        </Title>
      )}
      {questionsResponse.items.map((question) => (
        <Link
          key={question.id}
          to={`/account/questions/questions-to-answer/${question.id}`}
        >
          <LawyerQuestionOverview question={question as LawyerQuestion} />
        </Link>
      ))}
      <ReactPaginate
        renderOnZeroPageCount={() => null}
        initialPage={currentPage - 1}
        breakLabel={<BreakPagePagination />}
        nextLabel={<ArrowButtonPagination direction="next" />}
        containerClassName="flex justify-center items-center last:mr-0 first:ml-0 transition-all max-w-full gap-2"
        pageClassName="flex justify-center items-center px-1.5 cursor-pointer font-semibold"
        activeClassName="rounded-md text-white bg-purple-800 font-semibold"
        pageLinkClassName="p-1.5"
        pageRangeDisplayed={2}
        pageCount={Math.ceil(questionsResponse.total / PAGE_SIZE)}
        previousLabel={<ArrowButtonPagination />}
        onPageChange={(page) => {
          setSearchParams({ page: String(page.selected + 1) });
        }}
      />
    </div>
  );
};

export default LawyerQuestionsToAnswer;
