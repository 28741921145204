import { Title } from 'components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'features/shared/buttons';

export default function ConsultationRescheduleBlockedBooking() {
  const { t } = useTranslation('customer');
  const naviagte = useNavigate();

  return (
    <>
      <div className="max-w-[688px] px-4 sm:px-6 lg:pr-8 lg:ml-[120px]">
        <div className="mb-4 md:mb-6">
          <Title variant="h1">
            {t('account.consultations.reschedule.title')}
          </Title>
        </div>
      </div>
      <div className="mb-6 border-b border-[#D0D0D0]" />
      <div className="px-4 sm:px-6 lg:mr-8 lg:ml-[120px] max-w-[688px]">
        <div className="mb-4">
          <p>{t('account.consultations.reschedule.blocked')}</p>
        </div>
        <div className="mb-4">
          <p>{t('account.consultations.reschedule.blocked.text')}</p>
        </div>

        <div className="grid place-items-center">
          <Button
            onClick={() => naviagte('/account/consultations')}
            variant="primary"
            size="medium"
            fullWidth
            label={t('account.consultations.reschedule.blocked.cta')}
          />
        </div>
      </div>
    </>
  );
}
