import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { submitAnswer } from 'services/answer';
import { LawyerQuestion } from 'services/lawyerQuestion';

import { ModalAnswerSuccess } from 'features/answer/components';
import { Button } from 'features/shared/buttons';

interface SubmitAnswerRequestProps {
  questionId: LawyerQuestion['id'];
}

const SubmitAnswerRequest = ({ questionId }: SubmitAnswerRequestProps) => {
  const { t: tForm } = useTranslation('form');
  const { t } = useTranslation('lawyer');
  const queryClient = useQueryClient();
  const [isModalAnswerSuccessOpen, setIsModalAnswerSuccessOpen] =
    useState(false);

  const { mutate: submitRequest, isPending: isSubmitRequestLoading } =
    useMutation({
      mutationFn: () => submitAnswer(questionId),
      onError: () => toast.error(tForm('template.lawyer.answer.draft.error')),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['lawyer-questions'],
          refetchType: 'all'
        });
        setIsModalAnswerSuccessOpen(true);
      }
    });

  return (
    <>
      <Button
        onClick={submitRequest}
        variant="tertiary"
        size="large"
        isLoading={isSubmitRequestLoading}
        disabled={isSubmitRequestLoading}
        icon={<PaperAirplaneIcon className="size-5" />}
        label={t('account.lawyer.request.step.button.submit')}
        className="self-end !w-fit"
      />
      <ModalAnswerSuccess
        questionId={questionId}
        isOpen={isModalAnswerSuccessOpen}
        setIsOpen={setIsModalAnswerSuccessOpen}
      />
    </>
  );
};

export default SubmitAnswerRequest;
