import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LawyerQuestion } from 'services/lawyerQuestion';

import { RequestInputs } from 'features/request/context/RequestFormProvider';

interface CreateFormalNoticeProps {
  question: LawyerQuestion;
}

const CreateFormalNotice = ({ question }: CreateFormalNoticeProps) => {
  const { t } = useTranslation('lawyer');
  const { control } = useFormContext<RequestInputs>();

  const isFormalNoticeAvailable = question.member.can_use_formal_notice;
  return (
    <>
      <div
        className={classNames(
          'flex flex-row items-center mt-4',
          !isFormalNoticeAvailable && 'opacity-50'
        )}
      >
        <Controller
          name="mustCreateFormalNotice"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Switch
              onChange={onChange}
              disabled={!isFormalNoticeAvailable}
              className={`${!value ? 'bg-gray-500' : 'bg-purple-800'}
relative inline-flex h-[24px] w-[48px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className="sr-only">
                {t('account.lawyer.request.formalNotice.checkbox.label')}
              </span>
              <span
                aria-hidden="true"
                className={`${value ? 'translate-x-6' : 'translate-x-0'}
  pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          )}
        />
        <label className="font-sans text-black ml-2.5">
          {t('account.lawyer.request.formalNotice.checkbox.label')}
        </label>
      </div>
      {!isFormalNoticeAvailable && (
        <span className="italic text-xs font-bold mt-1 text-gray-800">
          {t('account.lawyer.request.formalNotice.unavailable')}
        </span>
      )}
    </>
  );
};

export default CreateFormalNotice;
