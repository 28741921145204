import { Combobox, ComboboxOptions } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import ComboboxAutocompleteInput from 'components/ComboboxAutocompleteInput';
import ComboboxAutocompleteOption from 'components/ComboboxAutocompleteOption';
import InputClearButton from 'components/InputClearButton';
import { useTranslation } from 'react-i18next';
import { Member, getMembers } from 'services/admin/members';
import { useDebounceValue } from 'usehooks-ts';

export type AdminMemberAutocompleteProps = {
  selectedMember?: Member | null;
  onChange: (member: Member | null) => void;
};

const AdminMemberAutocomplete = ({
  selectedMember,
  onChange
}: AdminMemberAutocompleteProps) => {
  const { t } = useTranslation('customer');

  const [queryMember, setQueryMember] = useDebounceValue('', 500);

  const { data } = useQuery({
    queryKey: ['customer-members', { search: queryMember, is_admin: false }],
    queryFn: () => getMembers({ search: queryMember, is_admin: false }),
    enabled: !!queryMember,
    placeholderData: (prev) => prev
  });
  const onClear = () => {
    setQueryMember('');
    onChange(null);
  };

  return (
    <Combobox value={selectedMember} onChange={onChange}>
      <ComboboxAutocompleteInput<Member>
        displayValue={(m) => m?.full_name || ''}
        onChange={(event) => setQueryMember(event.target.value)}
        placeholder={t(
          'admin.collaborators.modal.addAdminPrivilege.placeholder'
        )}
      >
        {(selectedMember || queryMember) && (
          <InputClearButton onClear={onClear} />
        )}
      </ComboboxAutocompleteInput>
      <ComboboxOptions
        anchor="bottom"
        transition
        className={classNames(
          'w-[var(--input-width)] bg-white border border-gray-300 mt-1 px-1 py-2 rounded-md shadow-md [--anchor-gap:var(--spacing-1)] empty:invisible',
          'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
        )}
      >
        {data && data?.items?.length === 0 && (
          <div className="group flex items-center gap-2 bg-white rounded-md py-1.5 px-2 select-none">
            {t('admin.collaborators.modal.addAdminPrivilege.noResult')}
          </div>
        )}
        {data?.items.map((member) => (
          <ComboboxAutocompleteOption key={member.id} value={member}>
            <div className={classNames('flex justify-between w-full')}>
              <span className={classNames('text-sm text-left')}>
                {member.full_name}
              </span>
              <span className={classNames('text-sm text-right italic')}>
                {member.email}
              </span>
            </div>
          </ComboboxAutocompleteOption>
        ))}
      </ComboboxOptions>
    </Combobox>
  );
};

export default AdminMemberAutocomplete;
