import { Combobox, ComboboxOptions } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import ComboboxAutocompleteInput from 'components/ComboboxAutocompleteInput';
import ComboboxAutocompleteOption from 'components/ComboboxAutocompleteOption';
import InputClearButton from 'components/InputClearButton';
import { useTranslation } from 'react-i18next';
import { LawyerTag, getLawyerTags } from 'services/tag';
import { useDebounceValue } from 'usehooks-ts';

export type LawyerTagAutocompleteProps = {
  value?: LawyerTag | null;
  onChange: (value: LawyerTag | null) => void;
};

const LawyerTagAutocomplete = ({
  value,
  onChange
}: LawyerTagAutocompleteProps) => {
  const [queryTag, setQueryTag] = useDebounceValue('', 500);
  const { t } = useTranslation('lawyer');

  const { data } = useQuery({
    queryKey: ['lawyer-tags', queryTag],
    queryFn: () => getLawyerTags({ search: queryTag }),
    enabled: !!queryTag,
    placeholderData: (prev) => prev
  });

  const onClear = () => {
    setQueryTag('');
    onChange(null);
  };

  return (
    <Combobox as="div" by="id" value={value} onChange={onChange}>
      <ComboboxAutocompleteInput<LawyerTag>
        placeholder={t('account.lawyer.requests.filters.tag.placeholder')}
        displayValue={(tag) => tag?.name || ''}
        onChange={(event) => setQueryTag(event.target.value)}
      >
        {(value || queryTag) && <InputClearButton onClear={onClear} />}
      </ComboboxAutocompleteInput>

      <ComboboxOptions
        anchor="bottom"
        transition
        className={classNames(
          'w-[var(--input-width)]  bg-white border border-gray-300 mt-1 px-1 py-2 rounded-md shadow-md [--anchor-gap:var(--spacing-1)] empty:invisible',
          'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
        )}
      >
        {data?.items.map((tag) => (
          <ComboboxAutocompleteOption<LawyerTag> key={tag.id} value={tag}>
            <span className={classNames('text-sm text-left')}>{tag.name}</span>
          </ComboboxAutocompleteOption>
        ))}
      </ComboboxOptions>
    </Combobox>
  );
};

export default LawyerTagAutocomplete;
