import { ClockIcon, UserIcon } from '@heroicons/react/24/outline';

interface ArticleProps {
  readingTime: string;
  author: { name: string };
  publishedAt: string;
}

const ArticleHeader = ({ readingTime, author, publishedAt }: ArticleProps) => (
  <div className="flex flex-col gap-2 justify-between mb-4">
    <div className="flex items-center gap-2">
      <ClockIcon className="h-6 w-6" />
      <span className="font-bold">Temps de lecture : </span>
      <span className="font-normal">{readingTime} minutes</span>
    </div>
    <div className="flex md:items-center gap-2">
      <div className="flex gap-2">
        <UserIcon className="h-6 w-6" />
        <span className="font-bold">Par : </span>
        <span className="font-normal">{author.name}</span>
      </div>
    </div>
    <div className="flex items-center gap-2">
      <span className="italic">{publishedAt}</span>
    </div>
  </div>
);

export default ArticleHeader;
