import { PaperClipIcon } from '@heroicons/react/24/outline';
import { Divider, Title } from 'components';
import { Card, CardContent } from 'components/card';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Paragraph } from 'features/shared/body';
import { Button, LinkedButton } from 'features/shared/buttons';

const LA_POSTE_LINK = 'https://www.laposte.fr/lettre-recommandee-en-ligne';

interface MemberDownloadFormalNoticeProps {
  onClick: () => void;
  isLoading: boolean;
}

const SendFormalNoticeCard = ({
  onClick,
  isLoading
}: MemberDownloadFormalNoticeProps) => {
  const { t } = useTranslation('customer');

  return (
    <Card compact>
      <CardContent>
        <Title variant="h4">
          {t('account.question.formalNotice.send.title')}
        </Title>
        <Divider />
        <Paragraph className="text-sm text-gray-800" gutterBottom>
          <Trans
            ns="customer"
            i18nKey="account.question.formalNotice.send.description"
            components={{
              1: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <Link
                  to="/account/formal-notice-help"
                  aria-label="formal notice help"
                  className="text-purple-900 underline"
                />
              )
            }}
          />
        </Paragraph>
        <Paragraph
          className="text-xs text-gray-800 bg-gray-200 rounded-sm p-2"
          gutterBottom
        >
          {t('account.question.formalNotice.warning')}
        </Paragraph>
        <div className="flex gap-4 self-end">
          <Button
            variant="secondary"
            size="small"
            icon={<PaperClipIcon className="size-4" />}
            onClick={onClick}
            label={t('account.question.formalNotice.send.download')}
            isLoading={isLoading}
            disabled={isLoading}
          />

          <LinkedButton
            variant="tertiary"
            size="small"
            isExternal
            href={LA_POSTE_LINK}
            className="w-fit self-end font-sans"
            label={t('account.question.formalNotice.send.link')}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default SendFormalNoticeCard;
