/* eslint-disable jsx-a11y/no-autofocus */
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { HTMLProps, useState } from 'react';
import { FieldError, UseFormRegister } from 'react-hook-form';

import InputErrorMessage from './InputErrorMessage';

interface TextInputGroupProps {
  type?: string;
  autofocus?: boolean;
  name: string;
  label: string;
  error: FieldError | undefined;
  register: UseFormRegister<any>;
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  maxLength?: number;
  additionalInputProps?: HTMLProps<HTMLInputElement>;
  className?: string;
  min?: number;
}

export default function TextInputGroup({
  type = 'text',
  autofocus = false,
  name,
  label,
  register,
  error,
  placeholder = '',
  defaultValue = '',
  disabled = false,
  fullWidth = false,
  maxLength = undefined,
  className = '',
  min = undefined,
  additionalInputProps = {}
}: TextInputGroupProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onShowPassword = () => setShowPassword(!showPassword);

  return (
    <div
      id="input-group"
      className={classNames(
        { 'w-full': fullWidth },
        'flex flex-col justify-end'
      )}
    >
      <label htmlFor={name} className="input-label">
        {label}
      </label>
      <div className="relative mt-1">
        <input
          maxLength={maxLength}
          type={showPassword ? 'text' : type}
          id={name}
          min={min}
          disabled={disabled}
          className={classNames(
            {
              'input-text h-12': true,
              'focus:drop-shadow-input focus:border-purple-900 focus:shadow-purple-900':
                !error,
              'text-red-800 border-red-800 bg-red-200': error,
              'text-gray-700': disabled
            },
            className
          )}
          onClick={(e) => type === 'date' && e.preventDefault()}
          placeholder={!error ? placeholder : ''}
          defaultValue={defaultValue}
          autoFocus={autofocus}
          {...register(name)}
          {...additionalInputProps}
        />
        {type === 'password' && (
          <div className="absolute right-4 top-1/2 -translate-y-1/2 flex justify-center items-center">
            <button
              type="button"
              aria-label="Voir le mot de passe"
              onClick={onShowPassword}
            >
              {!showPassword ? (
                <EyeIcon className="size-5" />
              ) : (
                <EyeSlashIcon className="size-5" />
              )}
            </button>
          </div>
        )}
      </div>
      {error && <InputErrorMessage error={error} />}
    </div>
  );
}
