import { Title } from 'components';

import { Paragraph } from 'features/shared/body';
import { MagnifierIllustration } from 'features/shared/illustrations';

interface LawyerMemberEmptyQuestionsProps {
  title: string;
  description: string;
}

const LawyerMemberEmptyQuestions = ({
  title,
  description
}: LawyerMemberEmptyQuestionsProps) => (
  <div className="flex flex-col max-w-96 my-8 items-center self-center">
    <MagnifierIllustration />
    <Title variant="h4" component="h2" className="!font-bold mt-2">
      {title}
    </Title>
    <Paragraph className="mt-2 text-center text-gray-800">
      {description}
    </Paragraph>
  </div>
);

export default LawyerMemberEmptyQuestions;
