import { Menu } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as EkieLogo } from 'assets/ekie.svg';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { getAuthenticatedMemberInfo } from 'services/member';

import { MemberProfileMenu } from 'features/menu';
import LinkNav from 'features/menu/components/LinkNav';

import { MobileNav } from './components';
import SubMenu from './components/SubMenu';
import {
  MEMBER_MENU_ITEMS,
  MEMBER_PROFILE_MENU_ITEMS,
  PageName
} from './constants';

const MemberMenu = () => {
  const { t } = useTranslation('customer');
  const { pathname } = useLocation();
  const {
    data: member,
    isLoading,
    isSuccess
  } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });

  if (isLoading || !isSuccess) return <div />;

  const menuItems = MEMBER_MENU_ITEMS.map((item) => {
    if (item.subMenus && member.is_active_cse_member) {
      return <SubMenu item={item} items={item.subMenus} />;
    }

    return <LinkNav translation="customer" item={item} key={item.name} />;
  });

  return (
    <>
      <div className="hidden md:fixed md:flex flex-col justify-between px-4 h-screen w-72">
        <div>
          <div className="pt-4 md:pb-12">
            <Link to="/account/home">
              <EkieLogo />
            </Link>
          </div>
          <div className="flex flex-col justify-between">
            <Menu as="nav" className="flex flex-col w-full">
              <Link
                className="font-bold self-center text-center mb-3 bg-purple-900 hover:bg-purple-800 text-white rounded text-lg px-4 py-2 focus:outline-none"
                to="/account/ask"
              >
                {t('account.sidebar.ask')}
              </Link>
              {menuItems}
            </Menu>
          </div>
        </div>
        <MemberProfileMenu />
      </div>
      <MobileNav
        // Mobile nav is used by lawyer too, we should probably create a new LawyerMobileNav component.
        // This component should be called in AccountLayout instead, so we can have DesktopMenu and MobileMenu.
        isActiveMemberCse={member.is_active_cse_member}
        translation="customer"
        buttonLink="/account/ask"
        accountTabs={[...MEMBER_MENU_ITEMS, ...MEMBER_PROFILE_MENU_ITEMS]
          .filter((item) => item.name !== PageName.Admin || member.is_admin)
          .map((item) => ({
            ...item,
            current: pathname.includes(item.name)
          }))}
      />
    </>
  );
};

export default MemberMenu;
