import { TabPanel, TabPanels } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Section, Title } from 'components';
import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  CseProfile,
  deleteCseFile,
  getCseFile,
  getCustomerCseProfile,
  updateCseProfile,
  uploadCseFile
} from 'services/cse';

import { useLocale } from 'hooks';

import { TextInputGroup } from 'features/shared/forms';
import { LoadingSpinner } from 'features/shared/loading';

import FileInput from '../../components/FileInput';
import SwitchInput from '../../components/SwitchInput';
import FilesList from './components/FilesList';
import {
  ACCEPTED_CSE_FILE_EXTENSIONS,
  updateCseProfileSchema
} from './validation';

type CseProfileInputs = {
  siren: CseProfile['siren'];
  companyName: CseProfile['company_name'];
  employeesCount: CseProfile['employees_count'];
  cseMembersCount: CseProfile['cse_members_count'];
  mandateDurationYear: CseProfile['mandate_duration_year'];
  lastElectionDate: Date | null;
  unionDelegateCount: CseProfile['union_delegate_count'];
  unionSectionRepresentativesCount: CseProfile['union_section_representatives_count'];
  cseDocuments: CseProfile['cse_documents'];
  cseDocumentsNotApplicable: boolean;
  companyDocumentsNotApplicable: boolean;
  internalRulesDocumentsNotApplicable: boolean;
};

const UpdateCseProfile = () => {
  const { t } = useTranslation('customer');
  const queryClient = useQueryClient();
  const { locale } = useLocale();
  const { t: tForm } = useTranslation('form');
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
    trigger
  } = useForm<CseProfileInputs>({
    resolver: yupResolver(updateCseProfileSchema(tForm))
  });
  const {
    data: cseProfile,
    isPending,
    isSuccess
  } = useQuery({
    queryKey: ['customer-cse-profile'],
    queryFn: () => getCustomerCseProfile()
  });

  const { mutate } = useMutation({
    mutationFn: (formData: CseProfileInputs) => updateCseProfile(formData),
    mutationKey: ['customer-cse-profile'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer-cse-profile'] });
    },
    onError: (error: any) => {
      toast.success(t('admin.collaborators.modal.addAdminPrivilege.success'));
      const errorMessage =
        error?.response?.data?.message ?? t('error.occurred');
      toast.error(errorMessage);
    }
  });

  const { mutate: uploadCseFileMutate, isPending: isUploadCseFilePending } =
    useMutation({
      mutationFn: ({ type, file }: any) =>
        uploadCseFile({ type, fileName: file.name, file }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['customer-cse-profile'] });
      },
      onError: () => {
        toast.error(t('general.error'));
      }
    });

  const { mutate: deleteCseFileFileMutate } = useMutation({
    mutationFn: deleteCseFile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer-cse-profile'] });
    },
    onError: () => {
      toast.error(t('general.error'));
    }
  });

  const { mutate: downloadCseFileMutate } = useMutation({
    mutationFn: async (id: string) => {
      const data = await getCseFile(id);
      window.open(data.url, '_blank');

      return data;
    }
  });

  useEffect(() => {
    if (isSuccess) {
      reset({
        siren: cseProfile.siren,
        companyName: cseProfile.company_name,
        employeesCount: cseProfile.employees_count,
        cseMembersCount: cseProfile.cse_members_count,
        mandateDurationYear: cseProfile.mandate_duration_year,
        lastElectionDate: cseProfile.last_election_date
          ? new Date(cseProfile.last_election_date)
          : null,
        unionDelegateCount: cseProfile.union_delegate_count,
        unionSectionRepresentativesCount:
          cseProfile.union_section_representatives_count,
        cseDocumentsNotApplicable: cseProfile.cse_documents_not_applicable,
        companyDocumentsNotApplicable:
          cseProfile.company_documents_not_applicable,
        internalRulesDocumentsNotApplicable:
          cseProfile.internal_rules_documents_not_applicable
      });
    }
  }, [isSuccess, cseProfile, reset]);

  if (isPending || !isSuccess) return <LoadingSpinner />;

  const submit = (fieldName: keyof CseProfileInputs) => async () => {
    const isValid = await trigger(fieldName);
    if (isValid) {
      await handleSubmit((data) => {
        mutate({
          ...data,
          siren: data.siren || null,
          companyName: data.companyName || null,
          employeesCount: data.employeesCount || null,
          cseMembersCount: data.cseMembersCount || null,
          mandateDurationYear: data.mandateDurationYear || null,
          unionDelegateCount: data.unionDelegateCount || null,
          unionSectionRepresentativesCount:
            data.unionSectionRepresentativesCount || null
        });
      })();
    }
  };

  return (
    <TabPanels>
      <form>
        <TabPanel className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <Section className="bg-white flex flex-col gap-4">
            <Title
              gutterBottom
              variant="h3"
              text={t('admin.cseProfile.update.form.companyGeneral.title')}
            />
            <TextInputGroup
              name="siren"
              label={tForm('label.siren')}
              register={register}
              additionalInputProps={{
                onBlur: submit('siren')
              }}
              placeholder={tForm('placeholder.siren')}
              error={errors.siren}
              className="w-48"
            />
            <TextInputGroup
              name="companyName"
              label={tForm('label.companyName')}
              register={register}
              placeholder={tForm('placeholder.companyName')}
              error={errors.companyName}
              disabled
            />
            <TextInputGroup
              type="number"
              name="employeesCount"
              min={1}
              label={tForm('label.employeesCount')}
              register={register}
              placeholder={tForm('placeholder.employeesCount')}
              additionalInputProps={{
                onBlur: submit('employeesCount')
              }}
              error={errors.employeesCount}
              className="w-48"
            />
          </Section>
          <Section className="bg-white flex flex-col gap-4">
            <Title
              variant="h3"
              gutterBottom
              text={t('admin.cseProfile.update.form.cseGeneral.title')}
            />
            <TextInputGroup
              type="number"
              name="cseMembersCount"
              min={1}
              label={tForm('label.cseMembersCount')}
              register={register}
              placeholder={tForm('placeholder.cseMembersCount')}
              additionalInputProps={{
                onBlur: submit('cseMembersCount')
              }}
              error={errors.cseMembersCount}
              className="w-48"
            />
          </Section>
        </TabPanel>
        <TabPanel className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <Section className="bg-white flex flex-col gap-4">
            <Title
              variant="h3"
              gutterBottom
              text={t('admin.cseProfile.update.form.election.title')}
            />
            <TextInputGroup
              type="number"
              name="mandateDurationYear"
              min={1}
              label={tForm('label.mandateDurationYear')}
              register={register}
              additionalInputProps={{ onBlur: submit('mandateDurationYear') }}
              placeholder={tForm('placeholder.mandateDurationYear')}
              error={errors.mandateDurationYear}
              className="w-48"
            />

            <Controller
              name="lastElectionDate"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div>
                  <label className="input-label mb-2">
                    {tForm('label.lastElectionDate')}
                  </label>
                  <DatePicker
                    selected={value || null}
                    onChange={onChange}
                    className="input-text h-12"
                    locale={locale}
                    onBlur={submit('lastElectionDate')}
                  />
                </div>
              )}
            />
          </Section>
          <Section className="bg-white flex flex-col gap-4">
            <Title
              variant="h3"
              gutterBottom
              text={t('admin.cseProfile.update.form.syndicate.title')}
            />
            <TextInputGroup
              type="number"
              name="unionDelegateCount"
              label={tForm('label.unionDelegateCount')}
              register={register}
              min={0}
              additionalInputProps={{ onBlur: submit('unionDelegateCount') }}
              placeholder={tForm('placeholder.unionDelegateCount')}
              error={errors.unionDelegateCount}
              className="w-48"
            />
            <TextInputGroup
              type="number"
              name="unionSectionRepresentativesCount"
              min={0}
              label={tForm('label.unionSectionRepresentativesCount')}
              register={register}
              additionalInputProps={{
                onBlur: submit('unionSectionRepresentativesCount')
              }}
              placeholder={tForm(
                'placeholder.unionSectionRepresentativesCount'
              )}
              error={errors.unionSectionRepresentativesCount}
              className="w-48"
            />
          </Section>
          <Section className="bg-white flex flex-col gap-4 col-span-2">
            <Title
              variant="h3"
              gutterBottom
              text={t('admin.cseProfile.update.form.functioning.title')}
            />
            <div className="grid grid-cols-1 gap-4 mt-4 mb-12">
              <label className="input-label">
                {tForm('label.cseDocuments')}
              </label>
              <FileInput
                accept={ACCEPTED_CSE_FILE_EXTENSIONS}
                disabled={
                  cseProfile.cse_documents_not_applicable ||
                  isUploadCseFilePending
                }
                onDropSuccess={(file) => {
                  uploadCseFileMutate({ type: 'cse_document', file });
                }}
              />
              <FilesList
                files={cseProfile.cse_documents}
                onClick={downloadCseFileMutate}
                onDelete={deleteCseFileFileMutate}
              />
              {!cseProfile.cse_documents.length && (
                <Controller
                  name="cseDocumentsNotApplicable"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <SwitchInput
                      label={tForm('label.allowUploadDocuments')}
                      checked={value}
                      disabled={cseProfile.cse_documents.length > 0}
                      onChange={(selectedValue) => {
                        onChange(selectedValue);
                        submit('cseDocumentsNotApplicable')();
                      }}
                    />
                  )}
                />
              )}
            </div>
          </Section>
        </TabPanel>
        <TabPanel className="grid grid-cols-1 gap-4">
          <Section className="bg-white flex flex-col gap-4">
            <Title
              variant="h3"
              gutterBottom
              text={t('admin.cseProfile.update.form.rules.title')}
            />
            <label className="input-label">
              {tForm('label.companyDocuments')}
            </label>
            <FileInput
              accept={ACCEPTED_CSE_FILE_EXTENSIONS}
              disabled={
                cseProfile.company_documents_not_applicable ||
                isUploadCseFilePending
              }
              onDropSuccess={(file) => {
                uploadCseFileMutate({ type: 'company_document', file });
              }}
            />
            <FilesList
              files={cseProfile.company_documents}
              onClick={downloadCseFileMutate}
              onDelete={deleteCseFileFileMutate}
            />
            {!cseProfile.company_documents.length && (
              <Controller
                name="companyDocumentsNotApplicable"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SwitchInput
                    label={tForm('label.allowUploadDocuments')}
                    checked={value}
                    disabled={cseProfile.company_documents.length > 0}
                    onChange={(selectedValue) => {
                      onChange(selectedValue);
                      submit('companyDocumentsNotApplicable')();
                    }}
                  />
                )}
              />
            )}
          </Section>
          <Section className="bg-white flex flex-col gap-4">
            <Title
              variant="h3"
              gutterBottom
              text={t('admin.cseProfile.update.form.agreements.title')}
            />
            <label className="input-label">
              {tForm('label.internalRulesDocuments')}
            </label>
            <FileInput
              accept={ACCEPTED_CSE_FILE_EXTENSIONS}
              disabled={
                cseProfile.internal_rules_documents_not_applicable ||
                isUploadCseFilePending
              }
              onDropSuccess={(file) => {
                uploadCseFileMutate({
                  type: 'internal_rules_document',
                  file
                });
              }}
            />
            <FilesList
              files={cseProfile.internal_rules_documents}
              onClick={downloadCseFileMutate}
              onDelete={deleteCseFileFileMutate}
            />
            {!cseProfile.internal_rules_documents.length && (
              <Controller
                name="internalRulesDocumentsNotApplicable"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SwitchInput
                    label={tForm('label.allowUploadDocuments')}
                    disabled={cseProfile.internal_rules_documents.length > 0}
                    checked={value}
                    onChange={(selectedValue) => {
                      onChange(selectedValue);
                      submit('internalRulesDocumentsNotApplicable')();
                    }}
                  />
                )}
              />
            )}
          </Section>
        </TabPanel>
      </form>
    </TabPanels>
  );
};

export default UpdateCseProfile;
