import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';

interface SanitizedContentProps {
  content: string;
  className?: string;
}

export default function SanitizedContent({
  content,
  className
}: SanitizedContentProps) {
  return (
    <ReactMarkdown className={classNames('prose', className)}>
      {content}
    </ReactMarkdown>
  );
}
