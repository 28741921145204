import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Title } from 'components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { cancelConsultationIntentSchema } from 'schemas';
import {
  LawyerConsultation,
  cancelConsultation
} from 'services/lawyerConsultation';

import { Button } from 'features/shared/buttons';
import { BalanceIcon } from 'features/shared/icons';
import { LayoutModal } from 'features/shared/layout';

import { ConsultationCancelIntent } from 'types/consultation';
import { SetState } from 'types/core';

import { SelectConsultationCancelIntent } from './components';

export interface ConsultationCancelIntentInput {
  intent: ConsultationCancelIntent;
}

interface ModalCancelAvailabilityProps {
  consultationId: LawyerConsultation['id'];
  isModalCancelConsultationOpen: boolean;
  setIsModalCancelConsultationOpen: SetState<boolean>;
}

export default function ModalCancelConsultation({
  consultationId,
  isModalCancelConsultationOpen,
  setIsModalCancelConsultationOpen
}: ModalCancelAvailabilityProps) {
  const queryClient = useQueryClient();
  const { t } = useTranslation('lawyer');

  const { t: tForm } = useTranslation('form');
  const { register, handleSubmit } = useForm<ConsultationCancelIntentInput>({
    resolver: yupResolver(cancelConsultationIntentSchema(tForm))
  });

  const { mutate: cancelConsultationMutate, isPending } = useMutation({
    mutationKey: ['member-consultation-cancel', consultationId],
    mutationFn: () => cancelConsultation(consultationId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lawyer-consultations-scheduled']
      });
      handleModalQuestionAcceptedClose();
    }
  });

  const handleModalQuestionAcceptedClose = () => {
    setIsModalCancelConsultationOpen(false);
  };

  const onSubmit = (formData: ConsultationCancelIntentInput) => {
    cancelConsultationMutate();
  };

  return (
    <LayoutModal
      isModalOpen={isModalCancelConsultationOpen}
      handleModalClose={handleModalQuestionAcceptedClose}
    >
      <div>
        <div className="flex flex-col items-center">
          <BalanceIcon />
          <Title variant="h3">
            {t('account.consultations.cancellation.title')}
          </Title>
        </div>
        <div className="text-left">
          <p className="text-md mt-2 font-medium ">
            {t('account.consultations.cancellation.p')}
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4">
              <SelectConsultationCancelIntent register={register} />
            </div>
            <div className="mt-4">
              <div className="flex gap-4 justify-end">
                <Button
                  variant="secondary"
                  size="medium"
                  label={t('general.back')}
                  onClick={() => setIsModalCancelConsultationOpen(false)}
                />
                <Button
                  submit
                  variant="custom"
                  customClassNames="bg-red-800 text-white"
                  size="medium"
                  label={t('account.consultations.cancellation.confirm')}
                  isLoading={isPending}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </LayoutModal>
  );
}
