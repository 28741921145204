import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'features/shared/buttons';

import Card from './Card';

export default function GenericErrorCard() {
  const { t } = useTranslation('customer');
  const navigate = useNavigate();
  const { reset } = useQueryErrorResetBoundary();

  const onClick = () => {
    reset();
    return navigate(0);
  };

  return (
    <Card>
      <div className="flex flex-col gap-4">
        <h3>{t('error.default.title')}</h3>
        <p className="text-md text-center font-medium ">
          {t('error.default.description')}
        </p>
        <div className="flex items-center justify-between gap-2">
          <Button
            variant="primary"
            size="medium"
            label={t('general.back')}
            fullWidth
            onClick={onClick}
          />
          <a className="w-full" href="mailto:service-client@ekie.co">
            <Button
              variant="secondary"
              size="medium"
              label={t('general.contactus')}
              fullWidth
            />
          </a>
        </div>
      </div>
    </Card>
  );
}
