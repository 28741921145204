import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Title } from 'components';
import { formatISO } from 'date-fns';
import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { LawyerConsultation } from 'services/lawyerConsultation';
import 'styles/calendar.css';

import FullCalendar from '@fullcalendar/react';

import {
  AllowFunc,
  DateRangeInput,
  DateSelectArg,
  DatesSetArg,
  EventClickArg
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

import { useLocale } from 'hooks';

import ModalLawyerConsultation from 'features/consultation/ModalLawyerConsultation';
import { PageLayout } from 'features/customer/account/content';
import ModalDeleteAvailability from 'features/lawyer/account/calendar/ModalDeleteAvailability';
import { Alert } from 'features/shared/alerts';

import Legend from '../../features/lawyer/account/calendar/Legend';
import {
  createAvailability,
  getLawyerCalendarEvents
} from '../../services/lawyers/calendar';

type DateRange = {
  start: Date;
  end: Date;
};

export type Availability = {
  range: DateRange;
  id: string;
};

export default function CalendarPage() {
  const { t } = useTranslation('lawyer');

  const [isModalDeleteAvailabilityOpen, setIsModalDeleteAvailabilityOpen] =
    useState<boolean>(false);

  const [isModalDisplayConsultationOpen, setIsModalDisplayConsultationOpen] =
    useState<boolean>(false);
  const [selectedAvailability, setSelectedAvailability] =
    useState<Availability | null>();
  const [displayedInCalendarDateRange, setDisplayedInCalendarDateRange] =
    useState<DateRange | null>();
  const [currentConsultation, setCurrentConsultation] =
    useState<LawyerConsultation['id']>();
  const { locale } = useLocale();

  const calendarRef = useRef<FullCalendar>(null);

  const queryClient = useQueryClient();

  const queryKey = ['lawyer-calendar-events'];
  if (displayedInCalendarDateRange) {
    queryKey.push(
      formatISO(displayedInCalendarDateRange.start),
      formatISO(displayedInCalendarDateRange.end)
    );
  }

  const { data: calendarEvents } = useQuery({
    queryKey,
    queryFn: () =>
      getLawyerCalendarEvents(
        formatISO(displayedInCalendarDateRange!.start),
        formatISO(displayedInCalendarDateRange!.end)
      ),
    enabled: displayedInCalendarDateRange != null
  });

  const handleDateSet = (datesSet: DatesSetArg) => {
    setDisplayedInCalendarDateRange({
      start: datesSet.start,
      end: datesSet.end
    });
  };

  const { mutate: createAvailabilitySlot } = useMutation({
    mutationFn: (range: DateRange) =>
      createAvailability(formatISO(range.start), formatISO(range.end)),
    onSettled: () => {
      calendarRef?.current?.getApi().unselect();
      queryClient.invalidateQueries({ queryKey });
    },
    onError: () => toast.error(t('account.availability.create.error'))
  });
  const handleDateSelect = (selectInfo: DateSelectArg) => {
    createAvailabilitySlot({ start: selectInfo.start, end: selectInfo.end });
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    const { start, end, extendedProps } = clickInfo.event;
    if (extendedProps.event_type === 'appointment') {
      setCurrentConsultation(extendedProps.consultation_id);
      setIsModalDisplayConsultationOpen(true);
    } else {
      setSelectedAvailability({
        range: { start: start!, end: end! },
        id: extendedProps.availability_id
      });
      setIsModalDeleteAvailabilityOpen(true);
    }
  };

  const handleSelectAllow: AllowFunc = (info: DateRangeInput) =>
    info.start !== undefined && info.start > new Date();

  return (
    <PageLayout>
      <Title component="h1" variant="h2" className="!font-bold">
        {t('account.calendar.title')}
      </Title>
      <div className="pb-6">
        <Alert title={t('account.dashboard.alert.title')}>
          <p>{t('account.dashboard.alert.description')}</p>
        </Alert>
      </div>
      <div className="bg-white p-6 rounded">
        <Legend />
        <FullCalendar
          height="auto"
          ref={calendarRef}
          allDaySlot={false}
          slotMinTime="08:00"
          slotMaxTime="21:00"
          slotLabelInterval={30}
          defaultTimedEventDuration="00:30"
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit'
          }}
          timeZone="local"
          firstDay={1}
          locale={locale}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridDay,timeGridWeek,dayGridMonth'
          }}
          buttonText={{
            today: t('general.today'),
            day: t('general.day'),
            week: t('general.week'),
            month: t('general.month')
          }}
          datesSet={handleDateSet}
          events={calendarEvents}
          initialView="timeGridWeek"
          selectOverlap={false}
          dayMaxEvents
          weekends={false}
          selectable
          nowIndicator
          selectAllow={handleSelectAllow}
          select={handleDateSelect}
          eventClick={handleEventClick}
          eventOverlap={false}
          displayEventTime
        />
      </div>

      {selectedAvailability && (
        <ModalDeleteAvailability
          isModalDeleteAvailabilityOpen={isModalDeleteAvailabilityOpen}
          setIsModalDeleteAvailabilityOpen={setIsModalDeleteAvailabilityOpen}
          availability={selectedAvailability}
        />
      )}

      {isModalDisplayConsultationOpen && (
        <ModalLawyerConsultation
          isModalDisplayConsultationOpen={isModalDisplayConsultationOpen}
          setIsModalDisplayConsultationOpen={setIsModalDisplayConsultationOpen}
          consultationId={currentConsultation!}
        />
      )}
    </PageLayout>
  );
}
