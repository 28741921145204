import { TabPanel, TabPanels } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import Back from 'components/Back';
import { Fade } from 'components/transition';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getLawyerQuestionAnswer } from 'services/answer';
import { getFormalNoticeByQuestion } from 'services/formal-notice';
import { getLawyerQuestion } from 'services/lawyerQuestion';

import { PageLayout } from 'features/customer/account/content';
import FormalNoticeStep from 'features/request/FormalNoticeStep';
import RequestAnswerStep from 'features/request/RequestAnswerStep';
import SubmitAnswerRequest from 'features/request/SubmitAnswerRequest';
import {
  RequestAnswerStepper,
  RequestSummary
} from 'features/request/components';
import RequestFormProvider from 'features/request/context/RequestFormProvider';
import { LayoutLoadingSkeleton } from 'features/shared/layout';

import RequestPanel from '../../features/request/RequestPanel';

const RequestAnswerPage = () => {
  const { questionId = '' } = useParams();
  const { t } = useTranslation('lawyer');
  const { data: question, isPending: isQuestionPending } = useQuery({
    queryKey: ['lawyer-question', Number(questionId)],
    queryFn: () => getLawyerQuestion(Number(questionId)),
    refetchOnMount: true,
    enabled: !!questionId
  });

  const hasAnswer = !!question && question.answer_id !== null;
  const hasFormalNotice = !!question && question.formal_notice !== null;

  const { data: answer, isPending: isAnswerPending } = useQuery({
    queryKey: ['lawyer-answer', questionId],
    queryFn: () => getLawyerQuestionAnswer(Number(questionId)),
    refetchOnMount: true,
    gcTime: Infinity,
    enabled: hasAnswer
  });

  const { data: formalNotice, isPending: isFormalNoticePending } = useQuery({
    queryKey: ['question-formal-notice', Number(questionId)],
    queryFn: () => getFormalNoticeByQuestion(Number(questionId)),
    refetchOnMount: true,
    staleTime: 0,
    gcTime: 0,
    enabled: hasFormalNotice
  });

  if (
    isQuestionPending ||
    (hasAnswer && isAnswerPending) ||
    (hasFormalNotice && isFormalNoticePending)
  )
    return <LayoutLoadingSkeleton />;

  return (
    <PageLayout>
      <Back label={t('account.lawyer.requests.toAnswer.back')} />
      {question && (
        <RequestFormProvider
          draftFormalNotice={formalNotice}
          draftAnswer={answer}
          question={question}
        >
          <RequestAnswerStepper>
            <TabPanels className="flex gap-4 flex-col xl:flex-row">
              <TabPanel as={Fade} className="flex xl:w-3/5 flex-col gap-4">
                <RequestAnswerStep question={question} />
              </TabPanel>
              <TabPanel
                as={Fade}
                className="w-full lg:w-3/5 flex flex-col gap-4"
              >
                <FormalNoticeStep
                  formalNoticeId={question.formal_notice?.id || null}
                  questionId={question.id}
                />
              </TabPanel>
              <TabPanel as={Fade} className="flex xl:w-2/3 flex-col gap-4">
                <RequestSummary question={question} />
                <SubmitAnswerRequest questionId={question.id} />
              </TabPanel>
              <div className="w-full xl:w-2/5 relative">
                <div className="xl:sticky top-4">
                  <RequestPanel />
                </div>
              </div>
            </TabPanels>
          </RequestAnswerStepper>
        </RequestFormProvider>
      )}
    </PageLayout>
  );
};

export default RequestAnswerPage;
