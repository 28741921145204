import { DocumentIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

interface DocumentListItemProps {
  variant?: 'normal' | 'empty';
}

const DocumentListItem = ({
  children,
  variant = 'normal'
}: PropsWithChildren<DocumentListItemProps>) => (
  <li
    className={classNames('flex items-center gap-2 mt-2', {
      italic: variant === 'empty'
    })}
  >
    {variant === 'normal' && <DocumentIcon className="size-4 text-gray-600" />}
    {children}
  </li>
);

export default DocumentListItem;
