/* eslint-disable import/no-cycle */
import { apiPrivatePythonClient } from './axios';
import { LawyerQuestion } from './lawyerQuestion';

export type FormalNotice = {
  id: number;
  sender_paragraph: string;
  recipient_paragraph: string;
  date_and_place_paragraph: string;
  formal_notice_label_paragraph: string;
  signature_paragraph: string;
  body: string;
};

type FormalNoticePayload = {
  body: string;
};

export async function generateFormalNotice(
  questionId: LawyerQuestion['id']
): Promise<FormalNotice> {
  const { data } = await apiPrivatePythonClient.post<FormalNotice>(
    `/lawyer/questions/${questionId}/formal-notice`
  );

  return data;
}

export async function getFormalNoticeByQuestion(
  questionId: LawyerQuestion['id']
): Promise<FormalNotice> {
  const { data } = await apiPrivatePythonClient.get<FormalNotice>(
    `/lawyer/questions/${questionId}/formal-notice`
  );

  return data;
}

export async function saveFormalNotice(
  questionId: LawyerQuestion['id'],
  { body }: FormalNoticePayload
): Promise<FormalNotice> {
  const { data } = await apiPrivatePythonClient.put<FormalNotice>(
    `/lawyer/questions/${questionId}/formal-notice`,
    { body }
  );

  return data;
}

export async function deleteFormalNotice(
  questionId: LawyerQuestion['id']
): Promise<FormalNotice> {
  const { data } = await apiPrivatePythonClient.delete<FormalNotice>(
    `/lawyer/questions/${questionId}/formal-notice`
  );

  return data;
}

export async function rejectFormalNotice(
  questionId: LawyerQuestion['id'],
  comment: string
): Promise<FormalNotice> {
  const { data } = await apiPrivatePythonClient.put<FormalNotice>(
    `/lawyer/questions/${questionId}/formal-notice/ai-rating`,
    { comment }
  );

  return data;
}
