import { apiPrivatePythonClient } from '../axios';
import { MemberResponse } from './members';

export const DEFAULT_MEMBERS_PAGE_SIZE = 10;

export async function getAdminMembers(
  page: number = 1,
  pageSize: number = DEFAULT_MEMBERS_PAGE_SIZE
): Promise<MemberResponse> {
  const { data } = await apiPrivatePythonClient.get<MemberResponse>(
    `/member/members`,
    {
      params: {
        page,
        page_size: pageSize,
        is_admin: true
      }
    }
  );
  return data;
}

export async function sendAdminOnboardingNotification(
  memberId: number
): Promise<void> {
  await apiPrivatePythonClient.post(
    `/member/members/${memberId}/send-admin-onboarding-notification`
  );
}
