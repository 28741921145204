import { yupResolver } from '@hookform/resolvers/yup';
import DOMPurify from 'dompurify';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LawyerQuestionAnswer } from 'services/answer';
import { FormalNotice } from 'services/formal-notice';
import { LawyerQuestion } from 'services/lawyerQuestion';

import { useLawyer } from 'hooks';

import { requestAnswerSchema } from '../validation';

export interface RequestInputs {
  answerContent: LawyerQuestionAnswer['content'];
  mustCreateFormalNotice: boolean;
  hasLegalGrounds: boolean;
  formalNoticeContent: string;
  currentStep: FormSteps;
}

interface RequestFormProviderProps {
  question: LawyerQuestion;
  draftAnswer?: LawyerQuestionAnswer;
  draftFormalNotice?: FormalNotice;
}

export type FormSteps = 'ANSWER' | 'FORMAL_NOTICE' | 'SUMMARY';

const RequestFormProvider: FC<RequestFormProviderProps> = ({
  question,
  draftAnswer,
  draftFormalNotice,
  children
}) => {
  const { t } = useTranslation('form');
  const { first_name: firstName, last_name: lastName } = useLawyer();

  const bookingMessage = question.member.can_book_consultation
    ? t('template.lawyer.consultationBooking')
    : '';

  const answerContentDefaultValue = t('template.lawyer.default', {
    bookingMessage,
    lawyerFullName: `${firstName} ${lastName}`
  });

  const methods = useForm<RequestInputs>({
    defaultValues: {
      answerContent: draftAnswer
        ? DOMPurify.sanitize(draftAnswer.content)
        : answerContentDefaultValue,
      formalNoticeContent: draftFormalNotice
        ? DOMPurify.sanitize(draftFormalNotice.body)
        : '',
      mustCreateFormalNotice: !!question.formal_notice,
      hasLegalGrounds: false,
      currentStep: question.formal_notice ? 'FORMAL_NOTICE' : 'ANSWER'
    },
    resolver: yupResolver(requestAnswerSchema(t)),
    mode: 'all'
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default RequestFormProvider;
