import { Divider, Title } from 'components';
import { Card, CardContent } from 'components/card';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Lawyer, MemberQuestion } from 'services/question';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';

interface FormalNoticeDownloadCardProps {
  questionId: MemberQuestion['id'];
  isLoading: boolean;
  hasConsultationScheduled: boolean;
  onClick: () => void;
  lawyer: Lawyer;
}

const FormalNoticeDownloadCard = ({
  isLoading,
  onClick,
  questionId,
  hasConsultationScheduled,
  lawyer
}: FormalNoticeDownloadCardProps) => {
  const { t } = useTranslation('customer');

  return (
    <Card compact>
      <CardContent>
        <Title variant="h4">
          {hasConsultationScheduled
            ? t('account.question.formalNotice.reminder.title')
            : t('account.question.formalNotice.new.title')}
        </Title>
        <Divider />
        <Paragraph className="text-sm text-gray-800" gutterBottom>
          {t('account.question.formalNotice.new.description', {
            lawyerFullName: lawyer.full_name
          })}
        </Paragraph>
        <Paragraph
          className="text-xs text-gray-800 bg-gray-200 rounded-sm p-2"
          gutterBottom
        >
          {t('account.question.formalNotice.warning')}
        </Paragraph>
        <div className="flex gap-4 self-end">
          <Button
            variant="tertiary"
            size="small"
            onClick={onClick}
            isLoading={isLoading}
            disabled={isLoading}
            label={t('account.question.formalNotice.new.preview')}
          />
          {!hasConsultationScheduled && (
            <Link
              className="self-end"
              to={`/account/consultations/booking?questionId=${questionId}&lawyerId=${lawyer.id}`}
            >
              <Button
                variant="secondary"
                size="small"
                label={t('account.question.consultation.cta')}
              />
            </Link>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default FormalNoticeDownloadCard;
