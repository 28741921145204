import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { Article, PaginatedArticles } from 'types/cms';

import { graphQLRequest } from './client';

const CSE_TAG = ['UhrKlBQVQACPkKNRFW7bYw'];
const LABOR_LAW_TAGS = [
  '23444939',
  '23444367',
  '23444450',
  '23444542',
  '23444915',
  '23444819',
  '23444820',
  '23444818',
  '23444905',
  '23444476',
  '23444910',
  '23444911',
  '23444912',
  '23444913',
  '23444909',
  '23444926',
  '23444870',
  '23444397',
  '23444431',
  '23444433',
  '23444806',
  '23444546',
  '23444819',
  '23444820',
  '23444818',
  '23444881',
  '23444366',
  '23444859',
  '23444837',
  '23444445',
  '23444879',
  '23444488',
  '23444424',
  '23444927',
  '23444803'
];

const GET_ARTICLE_BY_SLUG_QUERY = `
  query GetArticleBySlug($slug: String!) {
    article(
      filter: {
        slug: { eq: $slug }
      }
    ) {
      id
      title
      slug
      content
      updatedAt
      tags {
        id
        name
      }
      author {
        name
      } 
      featuredMedia {
        responsiveImage {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          bgColor
          base64
        }
      }
    }
  }
`;

const GET_LAST_ARTICLES_QUERY = `
  query AllArticles($linksFilter: LinksFilter) {
    allArticles(
      first: 6,
      filter: {
        tags: $linksFilter
      },
    ) {
      id
      title
      slug
      updatedAt
      tags {
        id
        name
      }
      featuredMedia {
        responsiveImage(imgixParams: { fm: webp, q: 100, h: 182 }) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          bgColor
          base64
        }
      }
    }
  }
`;

const GET_LAST_CSE_ARTICLES_QUERY = `{
  allArticles(
    first: 6,
    filter: {
      tags: { eq: UhrKlBQVQACPkKNRFW7bYw }
    }
  ) {
    id
    title
    slug
    updatedAt
    tags {
      id
      name
    }
    featuredMedia {
      responsiveImage(imgixParams: {fm: webp, q: 100, h: 182 }) {
        srcSet
        webpSrcSet
        sizes
        src
        width
        height
        aspectRatio
        alt
        title
        bgColor
        base64
      }
    }
  }
}`;

const GET_SUGGESTED_ARTICLES_QUERY = `
  query AllArticles($linksFilter: LinksFilter, $idsFilter: ItemIdFilter, $first: IntType = 3) {
    allArticles(
      first: $first,
      filter: {
        id: $idsFilter
        tags: $linksFilter
      }
    ) {
      id
      title
      slug
      updatedAt
      tags {
        id
        name
      }
      featuredMedia {
        responsiveImage(imgixParams: {fm: webp, q: 100, h: 182 }) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          bgColor
          base64
        }
      }
    }
  }
`;

const GET_PAGINATED_ARTICLES_QUERY = `
  query GetPaginatedArticles($skip: IntType, $first: IntType = 8, $search: String!, $linksFilter: LinksFilter) {
    allArticlesMeta: _allArticlesMeta (
      filter: {
        OR: [
          {
            title: {
              matches: { pattern: $search }
            }
          },
          {
            content: {
               matches: { pattern: $search }
            }
          }
        ],
        AND:{
            tags: $linksFilter
        },
      },
    ) {
      count
    }
    allArticles(
      first: $first,
      filter: {
        OR: [
          {
            title: {
              matches: { pattern: $search }
            }
          },
          {
            content: {
               matches: { pattern: $search }
            }
          }
        ],
        AND: {
          tags: $linksFilter
        }
      },
      skip: $skip
    ) {
      id
      title
      slug
      updatedAt
      tags {
        id
        name
      }
      featuredMedia {
        responsiveImage(imgixParams: {fm: webp, q: 70, h: 182 }) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          bgColor
          base64
        }
      }
    }
  }
`;

export const useGetLastArticles = ({ withLaborLaw = false }) => {
  const toExcludeLaborLawTags = withLaborLaw ? [] : LABOR_LAW_TAGS;

  return useQuery({
    queryKey: ['articles'],
    queryFn: (): Promise<PaginatedArticles | null> =>
      graphQLRequest(GET_LAST_ARTICLES_QUERY, {
        linksFilter: {
          notIn: ['UhrKlBQVQACPkKNRFW7bYw', ...toExcludeLaborLawTags]
        }
      })
  });
};

export const useGetLastArticlesRead = ({
  articlesIds = null,
  enabled = true
}: {
  articlesIds?: string[] | null;
  enabled?: boolean;
}) => {
  const idsFilter = articlesIds ? { in: articlesIds } : undefined;

  return useQuery({
    queryKey: ['last-articles-read', articlesIds],
    queryFn: (): Promise<PaginatedArticles | null> =>
      graphQLRequest(GET_SUGGESTED_ARTICLES_QUERY, {
        linksFilter: undefined,
        idsFilter
      }),
    enabled
  });
};

export const ARTICLE_BY_PAGE = 12;

export const useGetPaginatedArticles = (
  search: string | null,
  isCustomerCSE = false,
  withLaborLaw = true,
  enabled: boolean = true
) => {
  const toExcludeCseTags = isCustomerCSE ? [] : CSE_TAG;
  const toExcludeLaborLawTags = withLaborLaw ? [] : LABOR_LAW_TAGS;

  return useInfiniteQuery({
    queryKey: ['paginated-articles', search, withLaborLaw],
    queryFn: ({ pageParam = 0 }): Promise<PaginatedArticles | null> =>
      graphQLRequest(GET_PAGINATED_ARTICLES_QUERY, {
        search,
        skip: pageParam,
        first: ARTICLE_BY_PAGE,
        linksFilter: { notIn: [...toExcludeCseTags, ...toExcludeLaborLawTags] }
      }),
    initialPageParam: 0,
    gcTime: 0,
    enabled,
    getNextPageParam: (_, pages) => {
      const nextCursor =
        pages.length === 1 ? ARTICLE_BY_PAGE : pages.length * ARTICLE_BY_PAGE;

      return nextCursor;
    }
  });
};

export const useGetArticleBySlug = (slug: string) =>
  useQuery({
    queryKey: ['article', slug],
    queryFn: (): Promise<Article | null> =>
      graphQLRequest(GET_ARTICLE_BY_SLUG_QUERY, { slug })
  });

export const useGetLastCSEArticles = () =>
  useQuery({
    queryKey: ['articles-cse'],
    queryFn: (): Promise<PaginatedArticles | null> =>
      graphQLRequest(GET_LAST_CSE_ARTICLES_QUERY, {})
  });
