import { EyeIcon, PaperClipIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { Card, Title } from 'components';
import Back from 'components/Back';
import { CardContent } from 'components/card';
import { RatingStars } from 'components/statistic';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getLawyerQuestionAnswer } from 'services/answer';
import { getLawyerQuestion } from 'services/lawyerQuestion';

import { LawyerAnswerCard } from 'features/answer/components';
import LawyerPreviewFormalNotice from 'features/formal-notice/LawyerPreviewFormalNotice';
import { LawyerQuestionCard } from 'features/questions/components';
import { Button } from 'features/shared/buttons';
import { LayoutLoadingSkeleton, LayoutModal } from 'features/shared/layout';

const QuestionAnsweredPage = () => {
  const { questionId = '' } = useParams();
  const [isFormalNoticeOpen, setIsFormalNoticeOpen] = useState(false);
  const { t } = useTranslation('lawyer');
  const { data: answer } = useQuery({
    queryKey: ['lawyer-answer', questionId],
    queryFn: () => getLawyerQuestionAnswer(Number(questionId)),
    refetchOnWindowFocus: true
  });

  const { data: question } = useQuery({
    queryKey: ['lawyer-question', questionId],
    queryFn: () => getLawyerQuestion(Number(questionId)),
    refetchOnWindowFocus: true
  });

  if (!answer || !question) return <LayoutLoadingSkeleton />;

  return (
    <div className="overflow-y-auto max-h-screen">
      <Back label={t('account.lawyer.requests.toAnswer.back')} />
      <div className="container mx-auto">
        <div className="flex flex-col gap-4 px-6">
          <div>
            <LawyerQuestionCard question={question} />
          </div>
          <div>
            <LawyerAnswerCard answer={answer} />
          </div>
          {question.formal_notice !== null && (
            <Card>
              <CardContent className="flex !flex-row justify-between !mb-0">
                <Title
                  gutterBottom
                  variant="h3"
                  className="flex gap-2 items-center"
                >
                  <PaperClipIcon className="size-5" />
                  {t('account.questions.answered.formalNotice.title')}
                </Title>
                <Button
                  variant="outline"
                  size="small"
                  label={t('account.questions.answered.formalNotice.view')}
                  icon={<EyeIcon className="size-5" />}
                  onClick={() => setIsFormalNoticeOpen(true)}
                />
              </CardContent>
              <LayoutModal
                isModalOpen={isFormalNoticeOpen}
                withDefaultPadding={false}
                handleModalClose={() => setIsFormalNoticeOpen(false)}
              >
                <LawyerPreviewFormalNotice questionId={question.id} />
              </LayoutModal>
            </Card>
          )}
          {answer.note !== null && (
            <div className="self-end">
              <div className="italic text-sm text-gray-600">
                {answer.rating_comment}
              </div>
              <Trans
                parent="div"
                ns="lawyer"
                className="flex items-center text-sm font-semibold mt-1"
                i18nKey="account.questions.answered.votedBy"
                components={{
                  Stars: <RatingStars className="ml-2" value={answer.note} />
                }}
                values={{ fullName: question.member.full_name }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionAnsweredPage;
