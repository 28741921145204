import { HomeIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { ProfileMenuSkeleton } from 'components/skeleton';
import { useLocation } from 'react-router-dom';
import { getAuthenticatedMemberInfo } from 'services/member';

import { useAuth } from 'context';

import { ProfileMenu } from './components';
import { MEMBER_PROFILE_MENU_ITEMS, PageName } from './constants';

const MemberProfileMenu = () => {
  const { pathname } = useLocation();
  const { logout } = useAuth();
  const {
    data: member,
    isLoading,
    isSuccess
  } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });

  if (isLoading) return <ProfileMenuSkeleton />;
  if (!isSuccess) return <div />;

  const label =
    member.first_name && member.last_name
      ? `${member.first_name} ${member.last_name[0]}`
      : member.email;

  return (
    <ProfileMenu
      logout={logout}
      label={label}
      items={[
        ...MEMBER_PROFILE_MENU_ITEMS.filter(
          (item) => item.name !== PageName.Admin || member.is_admin
        ),
        ...(pathname.includes(PageName.Admin)
          ? [
              {
                name: PageName.Home,
                href: '/account/home',
                icon: HomeIcon
              }
            ]
          : [])
      ]}
    />
  );
};

export default MemberProfileMenu;
