import { Outlet } from 'react-router-dom';

import { MemberMenu } from 'features/menu';

const MemberLayout = () => (
  <div className="min-h-screen bg-gray-200 md:flex">
    <div id="sidebar" className="bg-white w-72 min-w-72">
      <MemberMenu />
    </div>
    <div className="flex-1 mt-[72px] md:mt-0">
      <Outlet />
    </div>
  </div>
);

export default MemberLayout;
