import { useFormContext } from 'react-hook-form';
import { LawyerQuestion } from 'services/lawyerQuestion';

import DraftFormalNotice from 'features/formal-notice/DraftFormalNotice';
import FeedbackFormalNotice from 'features/formal-notice/FeedbackFormalNotice';
import GenerateFormalNotice from 'features/formal-notice/GenerateFormalNotice';
import { RequestInputs } from 'features/request/context/RequestFormProvider';

import ValidateFormalNoticeStep from './ValidateFormalNoticeStep';

interface FormalNoticeStepProps {
  questionId: LawyerQuestion['id'];
  formalNoticeId: number | null;
}

const FormalNoticeStep = ({
  questionId,
  formalNoticeId
}: FormalNoticeStepProps) => {
  const { setValue } = useFormContext<RequestInputs>();

  if (!formalNoticeId) {
    return (
      <GenerateFormalNotice
        questionId={questionId}
        onError={() => setValue('currentStep', 'ANSWER')}
      />
    );
  }

  return (
    <>
      <DraftFormalNotice questionId={questionId} />
      <FeedbackFormalNotice questionId={questionId} />
      <ValidateFormalNoticeStep questionId={questionId} />
    </>
  );
};

export default FormalNoticeStep;
