import * as yup from 'yup';

import { TFunctionTranslationFormValidation } from '../../schemas';

export const feedbackFormalNoticeSchema = (
  t: TFunctionTranslationFormValidation
) =>
  yup.object({
    comment: yup
      .string()
      .max(500, t('schema.feedbackFormalNotice.reason.tooShort'))
      .max(500, t('schema.feedbackFormalNotice.reason.tooLong'))
      .required(t('schema.feedbackFormalNotice.reason.required'))
  });
