import { XMarkIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Title } from 'components';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Member, updateMember } from 'services/admin/members';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';

const RemoveAdminPrivilegeDialogButton = ({ member }: { member: Member }) => {
  const { t } = useTranslation('customer');
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate, reset, isPending, isIdle } = useMutation({
    mutationFn: () =>
      updateMember({
        memberId: member.id,
        is_admin: false,
        is_cse_member: member.is_cse_member
      }),
    mutationKey: ['customer-admins', member.id],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer-admins'] });
      toast.success(t('admin.collaborators.form.removeAdminPrivilege.success'));
      closeModal();
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message ?? t('error.occurred');
      toast.error(errorMessage);
      closeModal();
    }
  });

  const closeModal = () => {
    setIsModalOpen(false);
    reset();
  };

  return (
    <div>
      <Button
        variant="custom"
        size="small"
        customClassNames="!p-0 text-white"
        onClick={() => setIsModalOpen(true)}
        tooltip={t('admin.collaborators.modal.removeAdminPrivilege.tooltip')}
        icon={<XMarkIcon className="size-6" strokeWidth={2} stroke="red" />}
      />
      <LayoutModal isModalOpen={isModalOpen} handleModalClose={closeModal}>
        <Title
          variant="h3"
          className="!font-bold"
          text={t('admin.collaborators.modal.removeAdminPrivilege.title')}
        />
        {(isIdle || isPending) && (
          <div className="flex flex-col gap-5 my-5">
            <Paragraph>
              {t('admin.collaborators.modal.removeAdminPrivilege.text', {
                fullName: member.full_name
              })}
            </Paragraph>
            <div className="flex gap-4 justify-end">
              <Button
                onClick={closeModal}
                variant="secondary"
                size="medium"
                label={t('general.cancel')}
              />
              <Button
                onClick={() => mutate()}
                isLoading={isPending}
                variant="custom"
                size="medium"
                label={t(
                  'admin.collaborators.modal.removeAdminPrivilege.submit'
                )}
                customClassNames="bg-purple-900 hover:bg-purple-900 text-white justify-center font-medium w-fit"
              />
            </div>
          </div>
        )}
      </LayoutModal>
    </div>
  );
};

export default RemoveAdminPrivilegeDialogButton;
