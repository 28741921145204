import { useTranslation } from 'react-i18next';

import { Title } from '../../../../components';
import { Paragraph } from '../../../shared/body';
import { Button } from '../../../shared/buttons';
import { ImportSteps } from './steps';

const ImportSuccessStep = ({
  setStep,
  onCloseClicked
}: {
  setStep: (step: ImportSteps) => void;
  onCloseClicked: () => void;
}) => {
  const { t } = useTranslation('customer');
  return (
    <>
      <Title
        className="!font-bold"
        variant="h3"
        text={t('admin.collaborators.form.bulk.step.success.title')}
      />
      <Paragraph size="sm" className="my-8">
        {t('admin.collaborators.form.bulk.step.success.description')}
      </Paragraph>
      <div className="flex gap-4 justify-end">
        <Button
          onClick={onCloseClicked}
          variant="secondary"
          size="medium"
          label={t('admin.collaborators.form.bulk.step.success.close')}
        />
        <Button
          onClick={() => setStep('onboarding-emails')}
          variant="tertiary"
          size="medium"
          label={t(
            'admin.collaborators.form.bulk.step.success.scheduleOnboardingEmails'
          )}
          customClassNames="bg-purple-900 hover:bg-purple-900 text-white justify-center font-medium w-fit"
        />
      </div>
    </>
  );
};

export default ImportSuccessStep;
