import * as Sentry from '@sentry/react';
import ScrollToTop from 'components/ScrollToTop';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';

import { ErrorPage } from 'pages/error';

import {
  AuthProvider,
  ReactQueryProvider,
  TrackingProvider
} from 'context/index';

import { StyledToaster } from '../components';

const AppProviders: React.FC = ({ children }) => (
  <BrowserRouter>
    <ErrorBoundary
      onError={(error) => Sentry.captureException(error)}
      FallbackComponent={ErrorPage}
    >
      <ReactQueryProvider>
        <TrackingProvider>
          <AuthProvider>
            <ScrollToTop />
            {children}
            <StyledToaster />
          </AuthProvider>
        </TrackingProvider>
      </ReactQueryProvider>
    </ErrorBoundary>
  </BrowserRouter>
);

export default AppProviders;
