import { useQuery } from '@tanstack/react-query';

import data from './documentData.json';

export interface AllDocuments {
  documents: Document[];
}

type Document = {
  id: string;
  name: string;
  cseOnly: boolean;
  file: {
    size: number;
    url: string;
    format: string;
  };
};

export function useGetDocuments() {
  return useQuery({
    queryKey: ['documents'],
    queryFn: (): AllDocuments | null => ({
      documents: data
    })
  });
}
