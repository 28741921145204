import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { rejectFormalNotice } from 'services/formal-notice';
import { LawyerQuestion } from 'services/lawyerQuestion';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';
import { InputErrorMessage } from 'features/shared/forms';
import { LayoutModal } from 'features/shared/layout';

import { feedbackFormalNoticeSchema } from './validation';

interface FeedbackFormalNoticeProps {
  questionId: LawyerQuestion['id'];
  onSuccess?: () => void;
}

type FeedbackFormalNoticeInput = {
  comment: string;
};

const FeedbackFormalNotice = ({
  questionId,
  onSuccess
}: FeedbackFormalNoticeProps) => {
  const { t } = useTranslation('lawyer');
  const { t: tForm } = useTranslation('form');
  const [isOpen, setIsOpen] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<FeedbackFormalNoticeInput>({
    resolver: yupResolver(feedbackFormalNoticeSchema(tForm)),
    defaultValues: { comment: '' }
  });

  const { mutate, isPending: isRejectFormalNoticeLoading } = useMutation({
    mutationFn: (formData: FeedbackFormalNoticeInput) =>
      rejectFormalNotice(questionId, formData.comment),
    onSuccess: () => {
      setIsOpen(false);
      onSuccess?.();
      toast.success(
        'account.lawyer.request.formalNotice.feedback.form.success.description'
      );
    },
    onError: () => setError('root', { message: t('general.error.occurred') })
  });

  const onSubmit = (formData: FeedbackFormalNoticeInput) => mutate(formData);

  return (
    <div className="text-sm">
      <Paragraph className="leading-[4px]">
        {t('account.lawyer.request.formalNotice.feedback.description')}
        <Button
          size="small"
          variant="transparent"
          className="text-red-800 ml-2 !pl-0 !inline-flex hover:text-red-800 hover:underline"
          onClick={() => setIsOpen(true)}
          label={t('account.lawyer.request.formalNotice.feedback.link')}
        />
      </Paragraph>
      <LayoutModal
        isModalOpen={isOpen}
        handleModalClose={() => setIsOpen(false)}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-4 w-full">
            <label className="input-label">{tForm('label.message')}</label>
            <textarea
              rows={3}
              className="w-full"
              minLength={20}
              maxLength={1200}
              placeholder={tForm('placeholder.message')}
              {...register('comment')}
            />
            {errors.comment && <InputErrorMessage error={errors.comment} />}
          </div>
          {errors.root && (
            <p className="mt-2 text-red-800 mb-4">{errors.root}</p>
          )}
          <div className="mt-4">
            <div className="flex gap-4 justify-end">
              <Button
                variant="secondary"
                size="medium"
                label={t('general.back')}
                onClick={() => setIsOpen(false)}
              />
              <Button
                submit
                variant="custom"
                customClassNames="bg-red-800 text-white"
                size="medium"
                label={t(
                  'account.lawyer.request.formalNotice.feedback.form.submit'
                )}
                isLoading={isRejectFormalNoticeLoading}
              />
            </div>
          </div>
        </form>
      </LayoutModal>
    </div>
  );
};

export default FeedbackFormalNotice;
