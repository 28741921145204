import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

const QuestionAssignationDisclosure = ({
  children,
  defaultOpen = false
}: PropsWithChildren<{ defaultOpen?: boolean }>) => {
  const { t } = useTranslation('lawyer');

  return (
    <Disclosure as="div" defaultOpen={defaultOpen} key={String(defaultOpen)}>
      <DisclosureButton className="group data-open flex w-full text-purple-900 items-center gap-2 rounded-lg text-left font-semibold">
        <span className="hidden group-data-[open]:block">
          {t('account.lawyer.requests.assignation.question.close')}
        </span>
        <span className="block group-data-[open]:hidden">
          {t('account.lawyer.requests.assignation.question.show')}
        </span>
        <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
      </DisclosureButton>
      <div className="overflow-hidden">
        <DisclosurePanel
          unmount={defaultOpen}
          transition
          className="origin-top flex py-8 transition duration-200 ease-out data-[closed]:opacity-0"
        >
          {children}
        </DisclosurePanel>
      </div>
    </Disclosure>
  );
};

export default QuestionAssignationDisclosure;
