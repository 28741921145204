import classNames from 'classnames';
import { Title } from 'components';
import CircleProgressBar from 'components/CircleProgressBar';
import { useTranslation } from 'react-i18next';

import { Paragraph } from 'features/shared/body';

interface CseProfileCompletionProps {
  percent: number;
  totalStepCompleted: number;
}

const CseProfileCompletion = ({
  percent,
  totalStepCompleted
}: CseProfileCompletionProps) => {
  const { t } = useTranslation('customer');

  return (
    <div className="hidden lg:flex m-4 ">
      <div className="w-24 h-24">
        <CircleProgressBar percent={percent} />
      </div>
      <div className="flex flex-col w-full justify-center p-4">
        <Title variant="h3">{t('admin.cseProfile.completion')}</Title>
        <Paragraph
          className={classNames(
            'text-sm',
            [
              'text-red-400',
              'text-gray-600',
              'text-gray-600',
              'text-green-800'
            ][totalStepCompleted]
          )}
        >
          {t('admin.cseProfile.completed', { current: totalStepCompleted })}
        </Paragraph>
      </div>
    </div>
  );
};

export default CseProfileCompletion;
