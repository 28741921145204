import { Menu } from '@headlessui/react';
import {
  CalendarDaysIcon,
  ChartBarSquareIcon,
  ChatBubbleBottomCenterTextIcon,
  DocumentTextIcon,
  PhoneIcon
} from '@heroicons/react/24/outline';
import { ReactComponent as EkieLogo } from 'assets/ekie.svg';
import { Link, useLocation } from 'react-router-dom';

import SubMenu from 'features/menu/components/SubMenu';
import { LawyerQuestionsCount } from 'features/questions';
import { LawyerCareIcon } from 'features/shared/icons';

import LawyerProfileMenu from './LawyerProfileMenu';
import { LinkNav, MobileNav } from './components';
import { IMenuItem, PageName } from './constants';

const LAWYER_MENU_ITEMS: IMenuItem[] = [
  {
    name: PageName.Dashboard,
    href: '/account/dashboard',
    icon: ChartBarSquareIcon
  },
  {
    name: PageName.Calendar,
    href: '/account/calendar',
    icon: CalendarDaysIcon
  },
  {
    name: PageName.Questions,
    icon: ChatBubbleBottomCenterTextIcon,
    href: '/account/questions',
    subMenus: [
      {
        name: PageName.QuestionsAssignation,
        href: '/account/questions-assignation',
        extra: <LawyerQuestionsCount status={['assigned']} animate />
      },
      {
        name: PageName.QuestionsToAnswer,
        href: '/account/questions-to-answer',
        extra: <LawyerQuestionsCount status={['assignment_accepted']} />
      }
    ]
  },
  {
    name: PageName.Consultations,
    href: '/account/consultations',
    icon: PhoneIcon
  },
  {
    name: PageName.Requests,
    href: '/account/requests',
    icon: DocumentTextIcon
  },
  {
    name: PageName.LawyerCare,
    href: '/account/lawyercare',
    icon: LawyerCareIcon
  }
];

const LAWYER_MENU_MOBILE_ITEMS: IMenuItem[] = [
  {
    name: PageName.Profile,
    href: '/account/profile'
  }
];

const LawyerMenu = () => {
  const { pathname } = useLocation();

  const menuItems = LAWYER_MENU_ITEMS.map((item) => {
    if (item.subMenus) {
      return (
        <SubMenu
          key={item.name}
          translation="lawyer"
          item={item}
          items={item.subMenus}
        />
      );
    }

    return <LinkNav key={item.name} translation="lawyer" item={item} />;
  });

  return (
    <>
      <div className="hidden md:fixed md:flex flex-col justify-between px-4 h-screen w-72">
        <div>
          <div className="pt-4 md:pb-12">
            <Link to="/account/dashboard">
              <EkieLogo className="md:ml-3" />
            </Link>
          </div>
          <div className="flex flex-col justify-between">
            <Menu as="nav" className="flex flex-col w-full">
              {menuItems}
            </Menu>
          </div>
        </div>
        <LawyerProfileMenu />
      </div>
      <MobileNav
        translation="lawyer"
        isActiveMemberCse
        accountTabs={[...LAWYER_MENU_ITEMS, ...LAWYER_MENU_MOBILE_ITEMS].map(
          (item) => ({
            ...item,
            current: pathname.includes(item.name)
          })
        )}
      />
    </>
  );
};

export default LawyerMenu;
