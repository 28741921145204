import { Tab, TabGroup, TabList } from '@headlessui/react';
import classNames from 'classnames';
import { findIndex } from 'lodash';
import { FC, useLayoutEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FormSteps,
  RequestInputs
} from 'features/request/context/RequestFormProvider';

const RequestAnswerStepper: FC = ({ children }) => {
  const { t } = useTranslation('lawyer');
  const { watch, setValue } = useFormContext<RequestInputs>();

  const [currentStep, mustCreateFormalNotice] = watch([
    'currentStep',
    'mustCreateFormalNotice'
  ]);

  const TABS = [
    {
      number: 1,
      key: 'ANSWER',
      label: t('account.lawyer.request.tab.answer.label')
    },
    {
      number: 2,
      key: 'FORMAL_NOTICE',
      label: t('account.lawyer.request.tab.formalNotice.label'),
      disabled: !mustCreateFormalNotice,
      hidden: !mustCreateFormalNotice
    },
    {
      key: 'SUMMARY',
      number: !mustCreateFormalNotice ? 2 : 3,
      label: t('account.lawyer.request.tab.summary.label')
    }
  ];

  const currentIndex = findIndex(TABS, { key: currentStep });

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentStep]);

  return (
    <TabGroup
      selectedIndex={currentIndex}
      className="flex flex-col gap-4"
      onChange={(index) =>
        setValue('currentStep', TABS[index].key as FormSteps)
      }
    >
      <TabList className="flex max-w-2/3 justify-between text-md bg-white border-gray-200 border-2">
        {TABS.map((tab, index) => (
          <Tab
            key={tab.key}
            disabled={tab.disabled || index > currentIndex}
            className={classNames(
              'flex flex-1 flex-col gap-1 text-left px-6 mr-4 py-4 font-semibold transition-all duration-200 border-purple-800 data-[selected]:text-purple-800 hover:text-purple-800 data-[selected]:bg-white data-[selected]:border-b-2 data-[disabled]:opacity-40',
              tab.hidden && 'hidden'
            )}
          >
            {tab.number}. {tab.label}
          </Tab>
        ))}
      </TabList>
      {children}
    </TabGroup>
  );
};

export default RequestAnswerStepper;
