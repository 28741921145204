import classNames from 'classnames';
import { Title } from 'components';

interface LayoutCardProps {
  title: string;
  body: JSX.Element;
  size?: 'base' | 'xl';
}

export default function LayoutCard({
  title,
  body,
  size = 'base'
}: LayoutCardProps) {
  return (
    <div
      className={classNames(
        'bg-gray-100 rounded shadow-base sm:mx-auto sm:w-full',
        size === 'base' && 'sm:max-w-lg',
        size === 'xl' && 'sm:max-w-2xl'
      )}
    >
      <div className="flex flex-col items-center overflow-hidden text-center px-6 py-5 sm:p-6">
        <div className="pb-4">
          <Title variant="h1">{title}</Title>
        </div>
        {body}
      </div>
    </div>
  );
}
