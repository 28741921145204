import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Title } from 'components';
import { useTranslation } from 'react-i18next';
import { deleteFormalNotice } from 'services/formal-notice';
import { LawyerQuestion } from 'services/lawyerQuestion';

import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';

import { ConsultationCancelIntent } from 'types/consultation';

export interface ConsultationCancelIntentInput {
  intent: ConsultationCancelIntent;
}

interface DeleteFormalNoticeProps {
  questionId: LawyerQuestion['id'];
  isOpen: boolean;
  onSuccess: () => void;
  onCancel: () => void;
  description: string;
}

const DeleteFormalNotice = ({
  isOpen,
  questionId,
  description,
  onSuccess,
  onCancel
}: DeleteFormalNoticeProps) => {
  const { t } = useTranslation('lawyer');
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () => deleteFormalNotice(questionId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['lawyer-question'],
        refetchType: 'all'
      });
      onSuccess();
    }
  });

  const deleteFormalNoticeClick = async () => {
    await mutateAsync();
  };

  return (
    <LayoutModal isModalOpen={isOpen} handleModalClose={onCancel}>
      <div className="flex flex-col gap-4">
        <Title gutterBottom variant="h3">
          {t('account.lawyer.request.formalNotice.delete.title')}
        </Title>
        <p className="text-md mt-2 font-medium">{description}</p>
        <div className="mt-4">
          <div className="flex gap-4 justify-end">
            <Button
              variant="secondary"
              size="medium"
              label={t('account.lawyer.request.formalNotice.delete.pass')}
              disabled={isPending}
              onClick={onCancel}
            />
            <Button
              onClick={deleteFormalNoticeClick}
              variant="custom"
              customClassNames="bg-red-800 text-white"
              size="medium"
              label={t('account.lawyer.request.formalNotice.delete.submit')}
              disabled={isPending}
            />
          </div>
        </div>
      </div>
    </LayoutModal>
  );
};

export default DeleteFormalNotice;
