import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from '@headlessui/react';
import { CheckIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Chip, Title } from 'components';
import { SelectInputSkeleton } from 'components/skeleton';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  QuestionSharedMember,
  getQuestionSharedMembers,
  shareQuestion
} from 'services/question';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';

interface ShareMemberQuestionProps {
  onShare?: Function;
  questionId: number;
}

type ShareQuestionInput = {
  questionId: number;
  sharedMembers: QuestionSharedMember[];
};

const ShareMemberQuestion = ({
  questionId,
  onShare
}: ShareMemberQuestionProps) => {
  const { t } = useTranslation('customer');
  const { handleSubmit, setValue, control } = useForm<ShareQuestionInput>({
    defaultValues: { sharedMembers: [], questionId }
  });

  const {
    data: questionSharedMembers,
    isLoading: isQuestionSharedMembersLoading,
    isSuccess
  } = useQuery({
    queryKey: ['question-shared-members', questionId],
    queryFn: () => getQuestionSharedMembers(questionId),
    gcTime: 0
  });

  const { mutate, isPending: isShareQuestionLoading } = useMutation({
    mutationFn: (data: ShareQuestionInput) =>
      shareQuestion(questionId, data.sharedMembers),

    onError: () => toast.error(t('general.error')),
    onSuccess: () => {
      onShare && onShare();
      toast.success(t('account.question.share.form.success'));
    }
  });

  useEffect(() => {
    if (!isSuccess) return;
    const sharedMembers =
      questionSharedMembers.shares.filter((member) => member.shared) || [];
    setValue('sharedMembers', sharedMembers);
  }, [setValue, isSuccess, questionSharedMembers]);

  const onSubmitShareQuestion = (formData: ShareQuestionInput) =>
    mutate(formData);

  return (
    <div>
      <Title gutterBottom variant="h3">
        {t('account.question.share.title')}
      </Title>
      <Paragraph gutterBottom className="!text-gray-700 text-sm">
        {t('account.question.share.description')}
      </Paragraph>
      <form
        onSubmit={handleSubmit(onSubmitShareQuestion)}
        className="flex flex-col gap-6 h-96"
      >
        {isQuestionSharedMembersLoading && <SelectInputSkeleton />}
        {isSuccess && (
          <Controller
            control={control}
            name="sharedMembers"
            render={({ field }) => (
              <>
                <Listbox {...field} multiple by="member_id">
                  <div className="relative mt-1">
                    <ListboxButton
                      placeholder={t(
                        'account.question.share.form.sharedMembers.placeholder'
                      )}
                      className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left g-white/75 input-text h-12 border border-gray-500 focus:drop-shadow-input focus:border-purple-900 focus:shadow-purple-900"
                    >
                      <span className="block truncate">
                        {field.value
                          .slice(0, 3)
                          .map((member) => member.member_name)
                          .join(', ')}
                        {field.value.length > 3 && (
                          <Chip color="purple" className="ml-2 text-sm">{`+${
                            field.value.length - 3
                          }`}</Chip>
                        )}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>
                    <ListboxOptions className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none">
                      {questionSharedMembers.shares.map((member) => (
                        <ListboxOption
                          key={member.member_id}
                          value={member}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active
                                ? 'bg-purple-100 text-purple-900'
                                : 'text-gray-900'
                            }`
                          }
                        >
                          {({ selected }) => (
                            <>
                              <div
                                className={`block truncate ml-2 ${
                                  selected ? 'font-medium' : 'font-normal'
                                }`}
                              >
                                <div>{member.member_name}</div>
                                <div className="text-gray-600 text-sm">
                                  {member.member_email}
                                </div>
                              </div>
                              {selected && (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-purple-600">
                                  <CheckIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </span>
                              )}
                            </>
                          )}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </div>
                </Listbox>
                <Button
                  size="medium"
                  onClick={() => {
                    field.onChange(questionSharedMembers.shares);
                  }}
                  className="!pl-0 text-purple-900 underline leading-3 underline-offset-2"
                  variant="transparent"
                  label={t('account.question.share.selectAll')}
                />
              </>
            )}
          />
        )}
        <Button
          isLoading={isShareQuestionLoading}
          submit
          fullWidth
          size="medium"
          variant="tertiary"
          className="self-end"
          label={t('account.question.share.submit')}
        />
      </form>
    </div>
  );
};

export default ShareMemberQuestion;
