import { UserCircleIcon } from '@heroicons/react/24/solid';
import { Card, Divider, Title } from 'components';
import { useTranslation } from 'react-i18next';
import { LawyerQuestionAnswer } from 'services/answer';

import { useLawyer } from 'hooks';

import SanitizedContent from 'features/customer/question/SanitizedContent';

import { formatDateToIntl, getDateAsHhMm } from 'utils/date';

interface AnswerCardProps {
  answer: LawyerQuestionAnswer;
}

const LawyerAnswerCard = ({ answer }: AnswerCardProps) => {
  const { t } = useTranslation('lawyer');
  const { first_name: firstName, last_name: lastName } = useLawyer();

  const hasFullName = firstName && lastName;

  const getFormattedDate = (publishedDate: Date) => {
    const day = formatDateToIntl(publishedDate);
    const time = getDateAsHhMm(publishedDate);
    return `${day} ${t('general.at')} ${time}`;
  };

  return (
    <Card>
      <>
        <div className=" border-gray-500">
          <div className="flex justify-between items-center">
            <div className="flex gap-4 items-center">
              <UserCircleIcon className="size-10 text-gray-800" />
              <Title variant="h3">
                {hasFullName
                  ? `${firstName} ${lastName}`
                  : t('general.ekieClient')}
              </Title>
            </div>
          </div>
        </div>
        <Divider />
      </>
      <SanitizedContent content={answer.content} />
      <div className="self-end">
        <span className="text-gray-700 text-sm">
          {getFormattedDate(new Date(answer.published_at))}
        </span>
      </div>
    </Card>
  );
};

export default LawyerAnswerCard;
