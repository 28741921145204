import { apiPrivatePythonClient } from '../axios';

export const DEFAULT_MEMBERS_PAGE_SIZE = 10;

export type Member = {
  id: number;
  email: string;
  collaborator_id: number;
  full_name: string;
  is_cse_member: boolean;
  is_admin: boolean;
  added_at: string;
};

export type MemberResponse = {
  total: number;
  items: Member[];
};

export type GetMembersFilter = {
  search?: string;
  is_admin?: boolean;
  is_collaborator?: boolean;
};

export async function getMembers(
  filters: GetMembersFilter,
  page: number = 1,
  pageSize: number = DEFAULT_MEMBERS_PAGE_SIZE
): Promise<MemberResponse> {
  const { data } = await apiPrivatePythonClient.get<MemberResponse>(
    `/member/members`,
    {
      params: {
        ...filters,
        page,
        page_size: pageSize
      }
    }
  );
  return data;
}

export type BulkUpdateMembersParams = {
  dryRun: boolean;
  customerMembersFile: File;
};

export type BulkUpdateMembersResponse = {
  deleted_count: number;
  updated_count: number;
  created_count: number;
  deactivated_count: number;
};

export async function bulkUpdateMembers({
  customerMembersFile,
  dryRun
}: BulkUpdateMembersParams): Promise<BulkUpdateMembersResponse> {
  const formData = new FormData();
  formData.append('file', customerMembersFile);
  const { data } = await apiPrivatePythonClient.post<BulkUpdateMembersResponse>(
    `/member/customers/me/members-bulk-update?dry_run=${dryRun}`,
    formData
  );
  return data;
}

export async function deleteMember(memberId: number): Promise<void> {
  await apiPrivatePythonClient.delete(`/member/members/${memberId}`);
}

export type CreateMemberPayload = {
  email: string;
  firstName: string;
  lastName: string;
  type: 'cse' | 'employee';
};

export async function createMember(
  payload: CreateMemberPayload
): Promise<void> {
  await apiPrivatePythonClient.post(`/member/members`, {
    email: payload.email,
    first_name: payload.firstName,
    last_name: payload.lastName,
    is_cse_member: payload.type === 'cse'
  });
}

export type UpdateMemberPayload = {
  memberId: number;
  is_cse_member: boolean;
  is_admin: boolean;
};

export async function updateMember(
  payload: UpdateMemberPayload
): Promise<void> {
  await apiPrivatePythonClient.put(`/member/members/${payload.memberId}`, {
    is_cse_member: payload.is_cse_member,
    is_admin: payload.is_admin
  });
}

export async function sendOnboardingEmail(memberId: number): Promise<void> {
  await apiPrivatePythonClient.post(
    `/member/members/${memberId}/send-onboarding-email`
  );
}

export async function sendBulkOnboardingEmail(
  sendingDate: Date
): Promise<void> {
  await apiPrivatePythonClient.post(
    `/member/customers/me/schedule-onboarding-emails`,
    {
      at: sendingDate.toISOString()
    }
  );
}
