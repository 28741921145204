import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { Divider } from 'components';
import { useTranslation } from 'react-i18next';
import { LawyerQuestion } from 'services/lawyerQuestion';

import CseDocument from './CseDocument';
import DocumentListItem from './components/DocumentListItem';

interface LawyerCseProfileProps {
  questionId: number;
  cseProfile: NonNullable<LawyerQuestion['customer']['cse_profile']>;
}

const LawyerCseProfile = ({
  questionId,
  cseProfile
}: LawyerCseProfileProps) => {
  const { t } = useTranslation('lawyer');

  return (
    <TabGroup className="flex flex-col">
      <TabList className="flex w-full items-stretch mb-2">
        <Tab className="px-4 py-2 text-sm font-semibold transition-all duration-200 border-b-2 border-transparent data-[selected]:border-purple-800 data-[selected]:text-purple-800 bg-white hover:text-purple-600 data-[selected]:translate-y-[-2px] outline-none">
          {t('account.lawyer.request.panel.cseProfile.company.title')}
        </Tab>
        <Tab className="px-4 py-2 text-sm font-semibold transition-all duration-200 border-b-2 border-transparent data-[selected]:border-purple-800 data-[selected]:text-purple-800 bg-white hover:text-purple-600 data-[selected]:translate-y-[-2px] outline-none">
          {t('account.lawyer.request.panel.cseProfile.cse.title')}
        </Tab>
        <Tab className="px-3 py-2 text-sm font-semibold transition-all duration-200 border-b-2 border-transparent data-[selected]:border-purple-800 data-[selected]:text-purple-800 bg-white hover:text-purple-600 data-[selected]:translate-y-[-2px] outline-none">
          {t('account.lawyer.request.panel.cseProfile.rules.title')}
        </Tab>
      </TabList>
      <TabPanels className="w-full text-sm bg-white">
        <TabPanel className="py-4 w-full text-sm flex flex-col gap-4">
          <div className="flex flex-col">
            <div className="text-gray-700">
              {t('account.lawyer.request.panel.cseProfile.siren.label')}
            </div>
            <div className="font-medium">{cseProfile.siren || '-'}</div>
          </div>
          <div className="flex flex-col">
            <div className="text-gray-700">
              {t('account.lawyer.request.panel.cseProfile.company.label')}
            </div>
            <div className="font-medium">{cseProfile.company_name || '-'}</div>
          </div>
          <div className="flex flex-col">
            <div className="text-gray-700">
              {t(
                'account.lawyer.request.panel.cseProfile.employeesCount.label'
              )}
            </div>
            <div className="font-medium">
              {cseProfile.employees_count || '-'}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-gray-700">
              {t(
                'account.lawyer.request.panel.cseProfile.cseMembersCount.label'
              )}
            </div>
            <div className="font-medium">
              {cseProfile.cse_members_count || '-'}
            </div>
          </div>
        </TabPanel>
        <TabPanel className="py-4 w-full text-sm flex flex-col gap-4">
          <div className="flex flex-col">
            <div className="text-gray-700">
              {t(
                'account.lawyer.request.panel.cseProfile.mandateDurationYear.label'
              )}
            </div>
            <div className="font-medium">
              {cseProfile.mandate_duration_year || '-'}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-gray-700">
              {t(
                'account.lawyer.request.panel.cseProfile.lastElectionDate.label'
              )}
            </div>
            <div className="font-medium">
              {cseProfile.last_election_date || '-'}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-gray-700">
              {t(
                'account.lawyer.request.panel.cseProfile.unionDelegateCount.label'
              )}
            </div>
            <div className="font-medium">
              {cseProfile.union_delegate_count || '-'}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-gray-700">
              {t(
                'account.lawyer.request.panel.cseProfile.unionSectionRepresentativesCount.label'
              )}
            </div>
            <div className="font-medium">
              {cseProfile.union_section_representatives_count || '-'}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-gray-700">
              {t('account.lawyer.request.panel.cseProfile.convention.label')}
            </div>
            <ul className="font-medium">
              {cseProfile.convention_collective ? (
                <a
                  href={cseProfile.convention_collective.url}
                  className="font-medium underline"
                >
                  {cseProfile.convention_collective.title}
                </a>
              ) : (
                <DocumentListItem variant="empty">
                  {t('account.lawyer.request.panel.cseProfile.documents.empty')}
                </DocumentListItem>
              )}
            </ul>
          </div>
          <Divider />
          <div className="flex flex-col">
            <div className="text-gray-700 mb-1">
              {t('account.lawyer.request.panel.cseProfile.cseDocuments.label')}
            </div>
            <ul className="font-medium">
              {cseProfile.cse_documents.map((document) => (
                <DocumentListItem>
                  <CseDocument questionId={questionId} document={document} />
                </DocumentListItem>
              ))}
              {!cseProfile.cse_documents.length && (
                <DocumentListItem variant="empty">
                  {t('account.lawyer.request.panel.cseProfile.documents.empty')}
                </DocumentListItem>
              )}
            </ul>
          </div>
        </TabPanel>
        <TabPanel className="py-4 w-full text-sm flex flex-col gap-4">
          <div className="flex flex-col">
            <div className="text-gray-700 mb-1">
              {t(
                'account.lawyer.request.panel.cseProfile.companyDocuments.label'
              )}
            </div>
            <ul className="font-medium">
              {cseProfile.company_documents.map((document) => (
                <DocumentListItem>
                  <CseDocument questionId={questionId} document={document} />
                </DocumentListItem>
              ))}
              {!cseProfile.company_documents.length && (
                <DocumentListItem variant="empty">
                  {t('account.lawyer.request.panel.cseProfile.documents.empty')}
                </DocumentListItem>
              )}
            </ul>
          </div>
          <Divider />
          <div className="flex flex-col">
            <div className="text-gray-700 mb-1">
              {t(
                'account.lawyer.request.panel.cseProfile.internalRulesDocuments.label'
              )}
            </div>
            <ul className="font-medium">
              {cseProfile.internal_rules_documents.map((document) => (
                <DocumentListItem>
                  <CseDocument questionId={questionId} document={document} />
                </DocumentListItem>
              ))}
              {!cseProfile.internal_rules_documents.length && (
                <DocumentListItem variant="empty">
                  {t('account.lawyer.request.panel.cseProfile.documents.empty')}
                </DocumentListItem>
              )}
            </ul>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};

export default LawyerCseProfile;
