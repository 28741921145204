import { PlusIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Title } from 'components';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { createMemberSchema } from 'schemas';
import { createMember } from 'services/admin/members';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';
import { LayoutModal } from 'features/shared/layout';

type CustomerMemberAddInput = {
  email: string;
  firstName: string;
  lastName: string;
  type: 'cse' | 'employee';
};

const AddDialogButton = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('customer');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t: tForm } = useTranslation('form');
  const {
    register,
    handleSubmit,
    reset: resetForm,
    getValues,
    setError,
    formState: { errors }
  } = useForm<CustomerMemberAddInput>({
    resolver: yupResolver(createMemberSchema(tForm)),
    defaultValues: { type: 'employee' }
  });

  const {
    mutate,
    isSuccess,
    isPending,
    reset: resetMutation
  } = useMutation({
    mutationFn: (formData: CustomerMemberAddInput) => createMember(formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer-members'] });
    },
    onError: (error: any) => {
      if (
        error?.response?.status === 400 &&
        error?.response?.data?.code === 'email_already_exists'
      ) {
        setError('email' as any, {
          message: t('admin.collaborators.form.add.error.alreadyExist')
        });
      } else {
        toast.error(t('admin.collaborators.form.add.error.generic'));
      }
    }
  });

  return (
    <div>
      <Button
        variant="tertiary"
        fullWidth
        size="small"
        label={t('admin.collaborators.button.add')}
        onClick={() => {
          setIsModalOpen(true);
          resetForm();
          resetMutation();
        }}
        icon={<PlusIcon className="w-5 h-5" />}
      />
      <LayoutModal
        isModalOpen={isModalOpen}
        handleModalClose={() => setIsModalOpen(false)}
      >
        <Title
          variant="h3"
          className="!font-bold"
          text={t('admin.collaborators.button.add')}
        />
        {isSuccess && (
          <div className="flex flex-col gap-5 my-5">
            <Paragraph className="text-lg font-semibold">
              {t('admin.collaborators.form.add1.success', {
                firstName: getValues('firstName'),
                lastName: getValues('lastName')
              })}
            </Paragraph>
            <div className="flex gap-4 justify-end">
              <Button
                onClick={() => {
                  resetForm();
                  resetMutation();
                }}
                variant="secondary"
                size="medium"
                label={t('admin.collaborators.button.addAnother')}
              />
              <Button
                onClick={() => {
                  setIsModalOpen(false);
                  resetForm();
                  resetMutation();
                }}
                isLoading={isPending}
                variant="tertiary"
                size="medium"
                label={t('general.close')}
              />
            </div>
          </div>
        )}
        {!isSuccess && (
          <form
            className="flex flex-col gap-5 my-5"
            onSubmit={handleSubmit((data: CustomerMemberAddInput) =>
              mutate(data)
            )}
          >
            <div className="w-full flex gap-8">
              <TextInputGroup
                type="string"
                placeholder={tForm('placeholder.firstName')}
                label={tForm('label.firstName')}
                name="firstName"
                register={register}
                fullWidth
                error={errors.firstName}
              />
              <TextInputGroup
                type="string"
                placeholder={tForm('placeholder.lastName')}
                label={tForm('label.lastName')}
                name="lastName"
                register={register}
                fullWidth
                error={errors.lastName}
              />
            </div>
            <TextInputGroup
              type="string"
              placeholder={tForm('placeholder.email')}
              label={tForm('label.email')}
              name="email"
              register={register}
              error={errors.email}
            />
            <div>
              <label htmlFor="type" className="input-label">
                {t('admin.collaborators.form.add.role.label')}
              </label>
              <div className="flex items-center border border-gray-500 rounded-md py-3 px-3 mb-3">
                <input
                  id="type-1"
                  value="employee"
                  type="radio"
                  {...register('type')}
                  className="w-4 h-4 bg-gray-100 border-gray-300"
                />
                <label
                  htmlFor="type-1"
                  className="ml-6 font-medium text-gray-900"
                >
                  {t('admin.collaborators.form.add.role.value2')}
                </label>
              </div>
              <div className="flex items-center border border-gray-500 rounded-md py-3 px-3">
                <input
                  id="type-2"
                  type="radio"
                  value="cse"
                  {...register('type')}
                  className="w-4 h-4 bg-gray-100 border-gray-300 "
                />
                <label
                  htmlFor="type-2"
                  className="ml-6 font-medium text-gray-900"
                >
                  {t('admin.collaborators.form.add.role.value1')}
                </label>
              </div>
            </div>
            <div className="flex gap-4 justify-end mt-2">
              <Button
                onClick={() => setIsModalOpen(false)}
                variant="secondary"
                size="medium"
                label={t('general.cancel')}
              />
              <Button
                submit
                isLoading={isPending}
                variant="custom"
                size="medium"
                label={t('admin.collaborators.form.add.submit')}
                customClassNames="bg-purple-900 hover:bg-purple-900 text-white justify-center font-medium w-fit"
              />
            </div>
          </form>
        )}
      </LayoutModal>
    </div>
  );
};

export default AddDialogButton;
