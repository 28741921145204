import { useQuery } from '@tanstack/react-query';
import { graphQLRequest } from 'services/dato';

import { AllFrequentlyAskedQuestions } from 'types/cms';

const GET_FREQUENTLY_ASKED_QUESTIONS = `{
  allFrequentlyAskedQuestions (
    orderBy: order_ASC
  ) {
    id
    question
    content
  }
}`;

export function useGetFrequentlyAskedQuestions() {
  return useQuery({
    queryKey: ['frequently-asked-questions'],
    queryFn: (): Promise<AllFrequentlyAskedQuestions | null> =>
      graphQLRequest(GET_FREQUENTLY_ASKED_QUESTIONS, {})
  });
}
