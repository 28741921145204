import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { saveDraftAnswer } from 'services/answer';
import { LawyerQuestion } from 'services/lawyerQuestion';

import { RequestInputs } from 'features/request/context/RequestFormProvider';

import TranslateAnswer from './TranslateAnswer';
import { AnswerTextArea, AutomaticSaveLoader } from './components';

interface SaveAnswerProps {
  question: LawyerQuestion;
}

const SaveAnswer = ({ question }: SaveAnswerProps) => {
  const { t: tForm } = useTranslation('form');
  const {
    setValue,
    watch,
    control,
    formState: { errors }
  } = useFormContext<RequestInputs>();

  const {
    mutate: saveAnswer,
    isPending: isSaveAnswerLoading,
    data: savedData
  } = useMutation({
    mutationFn: (data: Pick<RequestInputs, 'answerContent'>) =>
      saveDraftAnswer(question.id, { content: data.answerContent }),
    onSuccess: (data) => setValue('hasLegalGrounds', data.has_legal_grounds),
    onError: () => toast.error(tForm('template.lawyer.answer.draft.error'))
  });

  const answerContent = watch('answerContent');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      answerContent && saveAnswer({ answerContent });
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [answerContent, saveAnswer]);

  return (
    <form className="flex flex-col gap-2">
      <div className="flex items-center justify-end">
        <AutomaticSaveLoader
          date={savedData?.saved_at}
          isLoading={isSaveAnswerLoading}
        />
      </div>
      <AnswerTextArea control={control} error={errors?.answerContent} />
      {errors.answerContent && (
        <div className="self-end text-xs text-red-800">
          {errors.answerContent.message}
        </div>
      )}
      <TranslateAnswer content={answerContent} />
    </form>
  );
};

export default SaveAnswer;
