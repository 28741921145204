import classNames from 'classnames';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { IMenuItem } from '../constants';

interface LinkNavProps {
  item: IMenuItem;
  translation: 'customer' | 'lawyer';
  isSubMenu?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const LinkNav = ({
  item,
  translation,
  isSubMenu = false,
  onClick = () => {}
}: LinkNavProps) => {
  const { pathname } = useLocation();
  const { t } = useTranslation(translation);
  const Icon = item.icon;

  const isCurrentPage = item.href === pathname;

  return (
    <Link key={item.name} to={item.href} onClick={onClick}>
      <div
        className={classNames(
          'flex my-1 px-3 py-2 items-center hover:rounded-lg hover:bg-gray-200 max-w-64',
          isSubMenu && 'ml-4',
          isCurrentPage ? 'font-semibold rounded-md bg-gray-200' : 'font-medium'
        )}
        key={item.name}
      >
        {Icon && <Icon strokeWidth="1.5" fill="transparent" className="w-5" />}
        <span
          className={classNames(
            'text-gray-900 flex items-center',
            Icon && 'ml-3'
          )}
        >
          {t(`account.sidebar.${item.name}` as any)}
        </span>
        {item.extra && (
          <div className="flex items-center justify-center">{item.extra}</div>
        )}
      </div>
    </Link>
  );
};

export default LinkNav;
