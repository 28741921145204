import { EyeIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';
import { Card, Title } from 'components';
import { CardContent } from 'components/card';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { saveFormalNotice } from 'services/formal-notice';
import { LawyerQuestion } from 'services/lawyerQuestion';

import {
  AnswerTextArea,
  AutomaticSaveLoader
} from 'features/answer/components';
import { RequestInputs } from 'features/request/context/RequestFormProvider';
import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';

import LawyerPreviewFormalNotice from './LawyerPreviewFormalNotice';

interface GenerateFormalNoticeProps {
  questionId: LawyerQuestion['id'];
}

const DraftFormalNotice = ({ questionId }: GenerateFormalNoticeProps) => {
  const { t } = useTranslation('lawyer');
  const { t: tForm } = useTranslation('form');
  const [isFormalNoticeOpen, setIsFormalNoticeOpen] = useState(false);
  const {
    control,
    watch,
    formState: { errors }
  } = useFormContext<RequestInputs>();

  const formalNoticeContent = watch('formalNoticeContent');

  const { mutate, isPending: isSaveFormalNoticeLoading } = useMutation({
    mutationFn: (data: Pick<RequestInputs, 'formalNoticeContent'>) =>
      saveFormalNotice(questionId, { body: data.formalNoticeContent }),
    onError: () => toast.error(tForm('template.lawyer.answer.draft.error'))
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      formalNoticeContent && mutate({ formalNoticeContent });
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [formalNoticeContent, mutate]);

  return (
    <Card>
      <CardContent>
        <Title gutterBottom variant="h3">
          {t('account.lawyer.request.formalNotice.step.title')}
        </Title>
        <Paragraph
          gutterBottom
          className="text-gray-550 whitespace-pre-line text-sm"
        >
          {t('account.lawyer.request.formalNotice.step.description')}
        </Paragraph>
        <form className="flex flex-col gap-2">
          <div className="flex items-center justify-end">
            <AutomaticSaveLoader isLoading={isSaveFormalNoticeLoading} />
          </div>
          <AnswerTextArea
            name="formalNoticeContent"
            control={control}
            error={errors?.answerContent}
            extendedToolbar={
              <div className="flex w-full justify-end">
                <Button
                  variant="transparent"
                  className="flex self-end"
                  size="medium"
                  label={t(
                    'account.lawyer.request.summary.step.formalNotice.preview'
                  )}
                  icon={<EyeIcon className="size-5" />}
                  onClick={() => setIsFormalNoticeOpen(true)}
                />
              </div>
            }
          />
          {errors.formalNoticeContent && (
            <div className="self-end text-xs text-red-800">
              {errors.formalNoticeContent.message}
            </div>
          )}
        </form>
        <LayoutModal
          isModalOpen={isFormalNoticeOpen}
          withDefaultPadding={false}
          handleModalClose={() => setIsFormalNoticeOpen(false)}
        >
          <LawyerPreviewFormalNotice questionId={questionId} />
        </LayoutModal>
      </CardContent>
    </Card>
  );
};

export default DraftFormalNotice;
