import { useMutation } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { saveFormalNotice } from 'services/formal-notice';
import { LawyerQuestion } from 'services/lawyerQuestion';

import { RequestInputs } from 'features/request/context/RequestFormProvider';
import { Button } from 'features/shared/buttons';

interface ValidateFormalNoticeStepProps {
  questionId: LawyerQuestion['id'];
}

const ValidateFormalNoticeStep = ({
  questionId
}: ValidateFormalNoticeStepProps) => {
  const { t } = useTranslation('lawyer');
  const { t: tForm } = useTranslation('form');
  const {
    setValue,
    trigger,
    getValues,
    formState: { errors }
  } = useFormContext<RequestInputs>();

  const { mutate, isPending } = useMutation({
    mutationFn: (data: Pick<RequestInputs, 'formalNoticeContent'>) =>
      saveFormalNotice(questionId, { body: data.formalNoticeContent }),
    onError: () => toast.error(tForm('template.lawyer.answer.draft.error'))
  });

  const onNextStepClick = async () => {
    const isFormalNoticeValid = await trigger('formalNoticeContent');
    if (!isFormalNoticeValid) return null;

    await mutate({
      formalNoticeContent: getValues('formalNoticeContent')
    });

    return setValue('currentStep', 'SUMMARY');
  };

  return (
    <Button
      onClick={onNextStepClick}
      variant="tertiary"
      disabled={!!errors.formalNoticeContent || isPending}
      size="large"
      label={t('account.lawyer.request.step.button.next')}
      className="self-end !w-fit"
    />
  );
};

export default ValidateFormalNoticeStep;
