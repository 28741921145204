import { apiPrivatePythonClient } from './axios';
import { FormalNotice } from './formal-notice';

export type MemberFormalNotice = FormalNotice & {
  last_downloaded_at: string;
};

export async function getFormalNoticeDocument(questionId: number) {
  const response = await apiPrivatePythonClient.post(
    `/member/questions/${questionId}/formal-notice/download`,
    {},
    {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type':
          'aapplication/vnd.openxmlformats-officedocument.wordprocessingml.document'
      }
    }
  );

  return response.data;
}
