import { EyeIcon, PaperClipIcon } from '@heroicons/react/24/outline';
import { Card, Divider, Title } from 'components';
import { CardContent } from 'components/card';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LawyerQuestion } from 'services/lawyerQuestion';

import SanitizedContent from 'features/customer/question/SanitizedContent';
import LawyerPreviewFormalNotice from 'features/formal-notice/LawyerPreviewFormalNotice';
import { LawyerQuestionCard } from 'features/questions/components';
import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';

import { RequestInputs } from '../context/RequestFormProvider';

interface RequestSummaryProps {
  question: LawyerQuestion;
}
const RequestSummary = ({ question }: RequestSummaryProps) => {
  const { t } = useTranslation('lawyer');
  const [isFormalNoticeOpen, setIsFormalNoticeOpen] = useState(false);

  const { getValues } = useFormContext<RequestInputs>();
  const [answerContent, hasLegalGrounds] = getValues([
    'answerContent',
    'formalNoticeContent',
    'hasLegalGrounds'
  ]);

  return (
    <div className="flex flex-col gap-4">
      <LawyerQuestionCard open={false} question={question} />
      <Card>
        <Title
          gutterBottom
          variant="h3"
          text={t('account.lawyer.request.summary.step.answer.title')}
        />
        <CardContent>
          <SanitizedContent content={answerContent} />
          {!hasLegalGrounds && (
            <div className="text-sm font-medium text-red-800">
              <Divider />
              {t(
                'account.lawyer.question.answer.modal.sent.missing_legal_grounds'
              )}
            </div>
          )}
        </CardContent>
      </Card>
      {question.formal_notice?.id && (
        <Card>
          <CardContent className="flex !flex-row justify-between !mb-0">
            <Title
              gutterBottom
              variant="h3"
              className="flex gap-2 items-center"
            >
              <PaperClipIcon className="size-5" />
              {t('account.lawyer.request.summary.step.formalNotice.title')}
            </Title>
            <Button
              variant="outline"
              size="small"
              label={t(
                'account.lawyer.request.summary.step.formalNotice.preview'
              )}
              icon={<EyeIcon className="size-5" />}
              onClick={() => setIsFormalNoticeOpen(true)}
            />
          </CardContent>
          <LayoutModal
            isModalOpen={isFormalNoticeOpen}
            withDefaultPadding={false}
            handleModalClose={() => setIsFormalNoticeOpen(false)}
          >
            <LawyerPreviewFormalNotice questionId={question.id} />
          </LayoutModal>
        </Card>
      )}
    </div>
  );
};

export default RequestSummary;
