import { apiPrivatePythonClient } from './axios';

export type ConsultationDocument = {
  id: string;
  name: string;
  url: string;
};

export async function uploadMemberDocumentByConsultation(
  consultationId: number,
  consultationFile: any
): Promise<any> {
  const { data } = await apiPrivatePythonClient.post(
    `member/documents`,
    consultationFile.content,
    {
      params: {
        consultation_id: consultationId,
        file_name: consultationFile.name
      },
      headers: { 'Content-Type': 'application/octet-stream' }
    }
  );

  return data;
}
