import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { uploadMemberDocumentByConsultation } from 'services/document';

import { DocumentDropzone } from 'features/customer/account/consultations/booking';
import { Button } from 'features/shared/buttons';

import { Document } from '../customer/account/consultations/booking/DocumentDropzone';

interface UploadConsultationDocumentProps {
  consultationId: number;
  onSuccess: () => void;
}

const UploadConsultationDocument = ({
  consultationId,
  onSuccess
}: UploadConsultationDocumentProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('customer');
  const [uploadedDocument, setDocument] = useState<Document | undefined>();
  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      uploadMemberDocumentByConsultation(consultationId, uploadedDocument),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['member-consultation', consultationId]
      });
      onSuccess?.();
    },
    onError: () => toast.error(t('general.error'))
  });

  return (
    <>
      <DocumentDropzone
        label="label.uploadFile"
        typeLabel="file.upload.3"
        required
        document={uploadedDocument}
        setDocument={setDocument}
        maxFile
        accept={{
          'image/jpg': ['.jpg'],
          'image/jpeg': ['.jpeg'],
          'image/png': ['.png'],
          'image/gif': ['.gif'],
          'image/webp': ['.webp'],
          'application/pdf': ['.pdf'],
          'application/x-pdf': ['.pdf'],
          'application/msword': ['.doc'],
          'application/vnd.ms-excel': ['.xls'],
          'application/vnd.ms-powerpoint': ['.ppt'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            ['.docx'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
            '.xlsx'
          ],
          'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            ['.pptx'],
          'application/vnd.oasis.opendocument.text': ['.odt'],
          'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
          'application/vnd.oasis.opendocument.presentation': ['.odp']
        }}
      />
      <Button
        size="small"
        variant="tertiary"
        onClick={() => mutate()}
        disabled={isPending || !uploadedDocument}
        className="w-fit self-end"
      >
        {t('file.upload.submit')}
      </Button>
    </>
  );
};

export default UploadConsultationDocument;
