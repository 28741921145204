import { useQuery } from '@tanstack/react-query';
import { find } from 'lodash';

import { AlphabetResponse, AlphabetSheetResponse } from 'types/cms';

import data from './alphabetData.json';

export const useGetAlphabet = () =>
  useQuery({
    queryKey: ['alphabet'],
    queryFn: (): AlphabetResponse => ({ alphabet: data })
  });

export const useGetAlphabetSheetBySlug = ({ slug }: { slug: string }) =>
  useQuery({
    queryKey: [`alphabet-${slug}`],
    queryFn: (): AlphabetSheetResponse => ({
      alphabetSheet: find(data, { slug })
    })
  });
