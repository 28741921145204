import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { authNewPasswordSchema } from 'schemas';

import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';

import { updatePassword } from '../../services/member';

interface NewPasswordInput {
  password: string;
  passwordConfirmation: string;
}

interface MemberUpdatePasswordProps {
  submitLabel?: string;
  onSuccess: () => void;
}

const MemberUpdatePassword = ({
  submitLabel = 'password.update.cta',
  onSuccess
}: MemberUpdatePasswordProps) => {
  const { t: tForm } = useTranslation('form');
  const { t } = useTranslation('customer');
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<NewPasswordInput>({
    resolver: yupResolver(authNewPasswordSchema(tForm))
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (formData: { password: string }) =>
      updatePassword(formData.password)
  });

  const onSubmit = (data: NewPasswordInput) => {
    mutate(
      { password: data.password },
      {
        onSuccess: () => {
          onSuccess();
          toast.success(t('account.profile.update.success'));
        },
        onError: () => toast.error(t('error.occurred'))
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-4 mt-3">
        <div>
          <TextInputGroup
            name="password"
            label={`${tForm('label.password')}`}
            type="password"
            register={register}
            error={errors.password}
            placeholder="**********"
          />
        </div>
        <div>
          <TextInputGroup
            name="passwordConfirmation"
            label={`${tForm('label.confirmPassword')}`}
            type="password"
            register={register}
            error={errors.passwordConfirmation}
            placeholder="**********"
          />
        </div>
        <div>
          <Button
            submit
            fullWidth
            isLoading={isPending}
            size="medium"
            variant="tertiary"
            label={t(submitLabel as any)}
          />
        </div>
      </div>
    </form>
  );
};

export default MemberUpdatePassword;
