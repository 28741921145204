/* eslint-disable react/no-unstable-nested-components */
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  ListsToggle,
  MDXEditor,
  UndoRedo,
  headingsPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  quotePlugin,
  toolbarPlugin
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const Toolbar = () => (
  <>
    <BoldItalicUnderlineToggles />
    <UndoRedo />
    <BlockTypeSelect />
    <ListsToggle />
  </>
);

interface AnswerTextAreaProps {
  control: any;
  error: any;
  name?: string;
  extendedToolbar?: JSX.Element;
}

const AnswerTextArea = ({
  control,
  name = 'answerContent',
  extendedToolbar: ExtendedToolbar
}: AnswerTextAreaProps) => {
  const { t } = useTranslation('form');

  return (
    <div translate="no" className="border rounded border-gray-400">
      <Controller
        render={({ field }) => (
          <MDXEditor
            {...field}
            contentEditableClassName="prose"
            markdown={field.value}
            className="z-0"
            translation={(key, defaultValue, interpolations) =>
              t(key as any, defaultValue, interpolations)
            }
            plugins={[
              headingsPlugin(),
              listsPlugin(),
              quotePlugin(),
              markdownShortcutPlugin(),
              toolbarPlugin({
                toolbarClassName: 'z-0',
                toolbarContents: () => (
                  <>
                    <Toolbar />
                    {ExtendedToolbar}
                  </>
                )
              })
            ]}
          />
        )}
        control={control}
        name={name}
      />
    </div>
  );
};

export default AnswerTextArea;
