import { useMutation } from '@tanstack/react-query';
import { Divider, Title } from 'components';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { lawyerNotifyIncompleteCseProfile } from 'services/cse';

import { Alert } from 'features/shared/alerts';
import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';

interface LawyerNotifyIncompleteCseProfileProps {
  questionId: number;
}

const LawyerNotifyIncompleteCseProfile = ({
  questionId
}: LawyerNotifyIncompleteCseProfileProps) => {
  const { t } = useTranslation('lawyer');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => setIsModalOpen(false);

  const { mutate, isPending } = useMutation({
    mutationFn: () => lawyerNotifyIncompleteCseProfile(questionId),
    onSuccess: () => {
      toast.success(
        t('account.lawyer.request.panel.cseProfile.notify.success.description')
      );
      closeModal();
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message ??
        t('account.lawyer.request.panel.cseProfile.notify.error');
      toast.error(errorMessage);

      closeModal();
    }
  });

  return (
    <Alert
      title={t('account.lawyer.request.panel.cseProfile.notify.title')}
      type="info"
      className="mb-4"
    >
      <span className="text-sm">
        {t('account.lawyer.request.panel.cseProfile.notify.description')}
      </span>
      <div className="flex gap-4 justify-end mt-2">
        <Button
          onClick={() => setIsModalOpen(true)}
          isLoading={isPending}
          variant="transparent"
          size="small"
          label={t(
            'account.lawyer.request.panel.cseProfile.notify.confirm.label'
          )}
          className="border !py-1 border-gray-800"
        />
      </div>
      <LayoutModal isModalOpen={isModalOpen} handleModalClose={closeModal}>
        <Title
          variant="h3"
          className="!font-bold"
          text={t(
            'account.lawyer.request.panel.cseProfile.notify.confirm.title'
          )}
        />
        <Divider />
        <Paragraph
          className="pt-4 pb-8 text-gray-550"
          text={t(
            'account.lawyer.request.panel.cseProfile.notify.confirm.description'
          )}
        />
        <div className="flex gap-4 justify-end">
          <Button
            onClick={() => mutate()}
            isLoading={isPending}
            variant="custom"
            size="medium"
            label={t(
              'account.lawyer.request.panel.cseProfile.notify.confirm.label'
            )}
            customClassNames="bg-purple-900 hover:bg-purple-900 text-white justify-center font-medium w-fit"
          />
        </div>
      </LayoutModal>
    </Alert>
  );
};

export default LawyerNotifyIncompleteCseProfile;
