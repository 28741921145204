import { Menu } from '@headlessui/react';
import { ReactComponent as EkieLogo } from 'assets/ekie.svg';
import { Link } from 'react-router-dom';

import { MemberProfileMenu } from 'features/menu';

import { LinkNav, MobileNav } from './components';
import { ADMIN_MENU_ITEMS } from './constants';

const AdminMenu = () => {
  const menuItems = ADMIN_MENU_ITEMS.map((item) => (
    <LinkNav translation="customer" item={item} key={item.name} />
  ));

  return (
    <>
      <div className="hidden md:fixed md:flex flex-col justify-between px-4 h-screen w-72">
        <div>
          <div className="pt-4 md:pb-12">
            <Link to="/account/home">
              <EkieLogo />
            </Link>
          </div>
          <Menu as="nav" className="flex flex-col w-full">
            {menuItems}
          </Menu>
        </div>
        <MemberProfileMenu />
      </div>
      <MobileNav translation="customer" accountTabs={ADMIN_MENU_ITEMS} />
    </>
  );
};

export default AdminMenu;
