import { Card } from 'components';
import { CardContent } from 'components/card';
import { LawyerQuestion } from 'services/lawyerQuestion';

import { SaveAnswer } from 'features/answer';
import { AnswerHelper } from 'features/answer/components';
import CreateFormalNotice from 'features/formal-notice/components/CreateFormalNotice';
import { LawyerQuestionCard } from 'features/questions/components';

import ValidateRequestAnswerStep from './ValidateRequestAnswerStep';

interface RequestAnswerStepProps {
  question: LawyerQuestion;
}

const RequestAnswerStep = ({ question }: RequestAnswerStepProps) => (
  <>
    <LawyerQuestionCard open question={question} />
    {!question.ai_suggestion && <AnswerHelper />}
    <Card>
      <CardContent>
        <SaveAnswer question={question} />
        <CreateFormalNotice question={question} />
      </CardContent>
    </Card>
    <ValidateRequestAnswerStep question={question} />
  </>
);

export default RequestAnswerStep;
