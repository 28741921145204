import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { acceptQuestionAssignation } from 'services/lawyers/assignation';

import { Button } from 'features/shared/buttons';

interface AcceptQuestionAssignationProps {
  questionId: number;
}

const AcceptQuestionAssignationButtons = ({
  questionId
}: AcceptQuestionAssignationProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('lawyer');
  const navigate = useNavigate();

  const onError = () => toast.error(t('account.questions.assignations.error'));

  const { mutate: acceptAssignationMutation, isPending: acceptIsLoading } =
    useMutation({
      onError,
      mutationFn: () => acceptQuestionAssignation(questionId),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['lawyer-questions'],
          refetchType: 'all'
        });
      }
    });
  const {
    mutate: acceptAndAnswerMutation,
    isPending: acceptAndAnswerIsLoading
  } = useMutation({
    onError,
    mutationFn: () => acceptQuestionAssignation(questionId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lawyer-questions'],
        refetchType: 'all'
      });
      navigate(`/account/questions/questions-to-answer/${questionId}`);
    }
  });

  return (
    <>
      <Button
        isLoading={acceptIsLoading}
        disabled={acceptAndAnswerIsLoading}
        variant="outline"
        size="medium"
        label={t('account.questions.inCharge.cta.answerLater')}
        onClick={acceptAssignationMutation}
      />
      <Button
        isLoading={acceptAndAnswerIsLoading}
        disabled={acceptIsLoading}
        variant="tertiary"
        size="medium"
        label={t('account.questions.inCharge.cta.answerNow')}
        onClick={acceptAndAnswerMutation}
      />
    </>
  );
};

export default AcceptQuestionAssignationButtons;
