import { useTranslation } from 'react-i18next';

import { Title } from '../../../../components';
import { Paragraph } from '../../../shared/body';
import { Button } from '../../../shared/buttons';
import BulkExportButton from '../BulkExportButton';
import { ImportSteps } from './steps';

const ExtractDownloadStep = ({
  setStep
}: {
  setStep: (step: ImportSteps) => void;
}) => {
  const { t } = useTranslation('customer');

  return (
    <>
      <Title
        className="!font-bold"
        variant="h3"
        text={t('admin.collaborators.form.bulk.step.extractDownload.title')}
      />
      <div className="my-8">
        <Paragraph size="base">
          <div className="font-bold">
            {t(
              'admin.collaborators.form.bulk.step.extractDownload.description1'
            )}
          </div>
          <ol className="list-decimal px-8 mt-2">
            <li>
              {t(
                'admin.collaborators.form.bulk.step.extractDownload.description2'
              )}
            </li>
            <li>
              {t(
                'admin.collaborators.form.bulk.step.extractDownload.description3'
              )}
              <ul className="list-disc ps-4">
                <li>
                  {t(
                    'admin.collaborators.form.bulk.step.extractDownload.description4'
                  )}
                </li>
                <li>
                  {t(
                    'admin.collaborators.form.bulk.step.extractDownload.description5'
                  )}
                </li>
              </ul>
            </li>
            <li>
              {t(
                'admin.collaborators.form.bulk.step.extractDownload.description6'
              )}
            </li>
          </ol>
        </Paragraph>
        <div className="bg-orange-200 border-l-4 border-orange-800 mt-4 p-4">
          <Paragraph className="font-bold">
            {t(
              'admin.collaborators.form.bulk.step.extractDownload.callout.title'
            )}
          </Paragraph>
          <div>
            {t(
              'admin.collaborators.form.bulk.step.extractDownload.callout.body1'
            )}
          </div>
          <div>
            {t(
              'admin.collaborators.form.bulk.step.extractDownload.callout.body2'
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-4 justify-end mt-2">
        <BulkExportButton />
        <Button
          onClick={() => setStep('file-select')}
          variant="tertiary"
          size="medium"
          label={t('admin.collaborators.form.bulk.button.next')}
        />
      </div>
    </>
  );
};

export default ExtractDownloadStep;
