import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import { Card, Divider, Title } from 'components';
import { CardContent } from 'components/card';
import { useTranslation } from 'react-i18next';
import { LawyerQuestion } from 'services/lawyerQuestion';

import SanitizedContent from 'features/customer/question/SanitizedContent';
import { Paragraph } from 'features/shared/body';

import AnswerDeadline from './AnswerDeadline';

interface LawyerQuestionCardProps {
  question: LawyerQuestion;
  compact?: boolean;
  open?: boolean;
}

const LawyerQuestionCard = ({
  question,
  open = true,
  compact = false
}: LawyerQuestionCardProps) => {
  const { t } = useTranslation('lawyer');

  return (
    <Card className="flex-1" compact={compact}>
      <div className="flex gap-2 items-center">
        <UserCircleIcon className="size-8 text-gray-800" />
        <Title variant="h3">{question.member.full_name}</Title>
      </div>
      <Paragraph className="text-gray-700 italic" size="sm">
        {question.title}
      </Paragraph>
      <Divider className="pb-1" />
      <Disclosure defaultOpen={open} as={CardContent}>
        <DisclosureButton className="group data-open text-sm flex justify-end w-full text-purple-900 items-center gap-1 rounded-lg font-semibold">
          <span className="hidden group-data-[open]:block">
            {t('account.lawyer.requests.assignation.question.close')}
          </span>
          <span className="block group-data-[open]:hidden">
            {t('account.lawyer.requests.assignation.question.show')}
          </span>
          <EyeIcon className="w-4 block group-data-[open]:hidden" />
          <EyeSlashIcon className="w-4 hidden group-data-[open]:block" />
        </DisclosureButton>
        <div className="overflow-hidden">
          <DisclosurePanel
            transition
            className="origin-top flex pt-4 transition duration-200 ease-out data-[closed]:opacity-0"
          >
            <SanitizedContent content={question.content} />
          </DisclosurePanel>
        </div>
      </Disclosure>
      {!question.answer_id && (
        <div className="self-end">
          <Divider />
          <AnswerDeadline answerDeadLine={question.answer_deadline_at} />
        </div>
      )}
    </Card>
  );
};

export default LawyerQuestionCard;
