import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFormalNoticeDocument } from 'services/memberFormalNotice';
import { Lawyer, MemberQuestion } from 'services/question';

import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';

import FormalNoticeDownloadCard from './components/FormalNoticeDownloadCard';
import PreviewFormalNotice from './components/PreviewFormalNotice';
import SendFormalNoticeCard from './components/SendFormalNoticeCard';

interface MemberDownloadFormalNoticeProps {
  question: MemberQuestion;
  isDownloaded: boolean;
  hasConsultationScheduled: boolean;
  lawyer: Lawyer;
  onDownload?: () => void;
}

const MemberDownloadFormalNotice = ({
  isDownloaded,
  onDownload,
  question,
  hasConsultationScheduled,
  lawyer
}: MemberDownloadFormalNoticeProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('customer');
  const [isOpen, setIsOpen] = useState(false);

  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      const data = await getFormalNoticeDocument(question.id);
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `formal_notice_${question.id}.docx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      return data;
    },
    onSuccess: () => {
      setIsOpen(false);
      queryClient.invalidateQueries({
        queryKey: ['member-question', question.id],
        refetchType: 'all'
      });
      onDownload?.();
    }
  });

  if (isDownloaded) {
    return <SendFormalNoticeCard onClick={mutate} isLoading={isPending} />;
  }

  return (
    <>
      <FormalNoticeDownloadCard
        onClick={() => setIsOpen(true)}
        lawyer={lawyer}
        questionId={question.id}
        hasConsultationScheduled={hasConsultationScheduled}
        isLoading={isPending}
      />
      {question.formal_notice && (
        <LayoutModal
          isModalOpen={isOpen}
          withDefaultPadding={false}
          handleModalClose={() => setIsOpen(false)}
        >
          <PreviewFormalNotice formalNotice={question.formal_notice}>
            <Button
              variant="tertiary"
              size="small"
              onClick={mutate}
              isLoading={isPending}
              disabled={isPending}
              icon={<ArrowDownTrayIcon className="size-4" />}
              className="w-fit self-end font-sans"
              label={t('account.question.formalNotice.new.download')}
            />
          </PreviewFormalNotice>
        </LayoutModal>
      )}
    </>
  );
};

export default MemberDownloadFormalNotice;
