interface ImportReportLineProps {
  title: string;
  result: number;
}

const ImportReportLine = ({ result, title }: ImportReportLineProps) => (
  <div className="my-2">
    <div className="flex items-center">
      <h4 className="font-medium mr-auto text-gray-700 flex items-center">
        {title}
      </h4>
      <span className="px-2 py-1 rounded-lg font-bold">{result}</span>
    </div>
  </div>
);

export default ImportReportLine;
