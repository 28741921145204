import { useQuery } from '@tanstack/react-query';
import { Divider } from 'components';
import { LawyerMemberHistoryQuestionsSkeleton } from 'components/skeleton';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LawyerMember } from 'services/lawyerMember';
import { QuestionStatus, getLawyerQuestions } from 'services/lawyerQuestion';

import LawyerMemberEmptyQuestions from './components/LawyerMemberEmptyQuestions';

interface LawyerMemberQuestionHistoryProps {
  member: LawyerMember;
  onQuestionClick: (id: number) => void;
}

const PAGE_SIZE = 5;
const QUESTIONS_STATUSES: QuestionStatus[] = [
  'answer_validated',
  'answered',
  'additional_request_answered',
  'reviewed',
  'archived'
];

const LawyerMemberQuestionHistory = ({
  member,
  onQuestionClick
}: LawyerMemberQuestionHistoryProps) => {
  const { t } = useTranslation('lawyer');
  const {
    data: questionsResponse,
    isLoading: isQuestionsLoading,
    isSuccess: isQuestionsSuccess
  } = useQuery({
    queryKey: [
      'lawyer-questions',
      { status: QUESTIONS_STATUSES, memberId: member.id }
    ],
    queryFn: () =>
      getLawyerQuestions({
        memberId: member.id,
        page: 1,
        status: QUESTIONS_STATUSES,
        pageSize: PAGE_SIZE,
        orderBy: '-created_at'
      }),
    refetchOnWindowFocus: true,
    placeholderData: (prev) => prev
  });

  if (isQuestionsLoading) return <LawyerMemberHistoryQuestionsSkeleton />;
  if (!isQuestionsSuccess) return <div />;

  return (
    <div className="flex mx-1 flex-col gap-4 max-w-[1080px]">
      {!questionsResponse.total && (
        <LawyerMemberEmptyQuestions
          title={t('account.lawyer.questions.member.empty.title')}
          description={t('account.lawyer.questions.member.empty.description')}
        />
      )}
      {questionsResponse.items.map((question, index) => (
        <Fragment key={question.id}>
          <Link
            to={`history/${question.id}`}
            className="text-left font-medium text-sm !py-0"
            key={question.id}
            onClick={() => onQuestionClick(question.id)}
          >
            {question.title}
          </Link>
          {questionsResponse.items.length - 1 !== index && (
            <Divider className="!py-0" />
          )}
        </Fragment>
      ))}
      {questionsResponse.total > PAGE_SIZE && (
        <Link
          target="_blank"
          className="text-purple-900 underline underline-offset-4 self-end"
          to={`/account/requests?member=${JSON.stringify({
            full_name: member.full_name,
            id: member.id
          })}`}
        >
          {t('account.lawyer.questions.member.link.label')}
        </Link>
      )}
    </div>
  );
};

export default LawyerMemberQuestionHistory;
