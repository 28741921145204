import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Trans } from 'react-i18next';

interface FileInputProps extends DropzoneOptions {
  label?: string;
  onDropSuccess: (files: File) => void;
}

const FileInput = ({
  multiple = false,
  accept,
  onDropSuccess,
  disabled
}: FileInputProps) => {
  const onDrop = (acceptedFiles: File[]) => {
    acceptedFiles.map((file) => onDropSuccess(file));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled,
    multiple,
    accept
  });

  return (
    <div
      {...getRootProps()}
      className={classNames(
        'flex items-center justify-center w-full mb-2',
        disabled && 'opacity-40'
      )}
    >
      <label
        htmlFor="dropzone-file"
        className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-400 border-dashed rounded-lg cursor-pointer bg-gray-50"
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <CloudArrowUpIcon className="size-8" />
          <Trans
            ns="customer"
            parent="p"
            className="my-2 text-sm text-gray-800"
            i18nKey="admin.cseProfile.update.form.files.label"
            components={{
              1: <span className="font-semibold" />
            }}
          />
          <Trans
            ns="customer"
            parent="p"
            className="text-xs text-gray-600"
            i18nKey="admin.cseProfile.update.form.files.helper"
          />
          <input {...getInputProps()} />
        </div>
      </label>
    </div>
  );
};

export default FileInput;
