import { useQuery } from '@tanstack/react-query';
import { Divider } from 'components';
import { LawyerMemberHistoryQuestionsSkeleton } from 'components/skeleton';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getLawyerSimilarQuestions } from 'services/lawyerQuestion';

import LawyerMemberEmptyQuestions from './components/LawyerMemberEmptyQuestions';

interface LawyerMemberSimilarQuestionsProps {
  questionId: number;
  onQuestionClick: (id: number) => void;
}

const LawyerMemberSimilarQuestions = ({
  questionId,
  onQuestionClick
}: LawyerMemberSimilarQuestionsProps) => {
  const { t } = useTranslation('lawyer');
  const {
    data: questionsResponse,
    isLoading: isQuestionsLoading,
    isSuccess: isQuestionsSuccess
  } = useQuery({
    queryKey: ['lawyer-similar-questions', { questionId }],
    queryFn: () =>
      getLawyerSimilarQuestions({
        questionId
      }),
    refetchOnWindowFocus: true,
    placeholderData: (prev) => prev
  });

  if (isQuestionsLoading) return <LawyerMemberHistoryQuestionsSkeleton />;
  if (!isQuestionsSuccess) return <div />;

  return (
    <div className="flex my-4 mx-1 flex-col gap-4 max-w-[1080px]">
      {!questionsResponse.questions.length && (
        <LawyerMemberEmptyQuestions
          title={t('account.lawyer.questions.similar.empty.title')}
          description={t('account.lawyer.questions.similar.empty.description')}
        />
      )}
      {questionsResponse.questions.map((question, index) => (
        <div className="flex flex-col gap-2" key={question.id}>
          <Link
            to={`similar/${question.id}`}
            className="text-left font-medium text-sm !py-0"
            key={question.id}
            onClick={() => onQuestionClick(question.id)}
          >
            {question.title}
          </Link>
          <div className="text-sm text-gray-600 italic">
            Par {question.member.full_name}
          </div>
          {questionsResponse.questions.length - 1 !== index && (
            <Divider className="!py-0" />
          )}
        </div>
      ))}
    </div>
  );
};

export default LawyerMemberSimilarQuestions;
