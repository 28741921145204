import { PlusIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Title } from 'components';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { sendAdminOnboardingNotification } from 'services/admin/administrators';
import { Member, updateMember } from 'services/admin/members';

import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';

import AdminMemberAutocomplete from './AdminMemberAutocomplete';

const AddAdminPrivilegeDialogButton = () => {
  const { t } = useTranslation('customer');
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState<Member | null>(null);

  const { mutate, reset, isPending, isIdle } = useMutation({
    mutationFn: async () => {
      await updateMember({
        memberId: selectedMember!.id,
        is_admin: true,
        is_cse_member: selectedMember!.is_cse_member
      });
      await sendAdminOnboardingNotification(selectedMember!.id);
    },
    mutationKey: ['customer-admins'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer-admins'] });
      toast.success(t('admin.collaborators.modal.addAdminPrivilege.success'));
      closeModal();
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message ?? t('error.occurred');
      toast.error(errorMessage);

      closeModal();
    }
  });

  const closeModal = () => {
    setSelectedMember(null);
    reset();
    setIsModalOpen(false);
  };

  return (
    <div className="w-1/4">
      <Button
        variant="tertiary"
        fullWidth
        size="small"
        label={t('admin.collaborators.modal.addAdminPrivilege.button')}
        onClick={() => {
          setIsModalOpen(true);
        }}
        icon={<PlusIcon className="w-5 h-5" />}
      />
      <LayoutModal isModalOpen={isModalOpen} handleModalClose={closeModal}>
        <Title
          variant="h3"
          className="!font-bold"
          text={t('admin.collaborators.modal.addAdminPrivilege.title')}
        />
        {(isIdle || isPending) && (
          <div className="flex flex-col mt-8 gap-8">
            <AdminMemberAutocomplete
              selectedMember={selectedMember}
              onChange={setSelectedMember}
            />
            <div className="flex gap-4 justify-end">
              <Button
                onClick={closeModal}
                variant="secondary"
                size="medium"
                label={t('general.cancel')}
              />
              <Button
                onClick={() => mutate()}
                disabled={selectedMember === null}
                isLoading={isPending}
                variant="custom"
                size="medium"
                label={t('admin.collaborators.modal.addAdminPrivilege.submit')}
                customClassNames="bg-purple-900 hover:bg-purple-900 text-white justify-center font-medium w-fit"
              />
            </div>
          </div>
        )}
      </LayoutModal>
    </div>
  );
};

export default AddAdminPrivilegeDialogButton;
