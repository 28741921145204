import { Section, Title } from 'components';
import Back from 'components/Back';
import { range } from 'lodash';
import { useTranslation } from 'react-i18next';

import { PageLayout } from 'features/customer/account/content';
import SanitizedContent from 'features/customer/question/SanitizedContent';

export default function FormalNoticeHelpPage() {
  const { t } = useTranslation('customer');

  return (
    <PageLayout>
      <Back label="Retour" />
      <Section color="white">
        <Title gutterBottom variant="h1">
          {t('account.formalNotice.title')}
        </Title>
        <div className="w-full rounded-2xl bg-white pt-4 divide-gray-400 text-lg md:my-8">
          {range(1, 11).map((value) => (
            <div className="w-full justify-between rounded-lg px-4 py-6 text-left font-semibold">
              <Title variant="h3" className="font-semibold text-lg lg:text-xl">
                {t(`account.formalNotice.question.${value}.title` as any)}
              </Title>
              <SanitizedContent
                content={t(
                  `account.formalNotice.question.${value}.description` as any
                )}
                className="max-w-none text-base lg:text-lg pt-8 text-gray-800 font-medium mb-6"
              />
            </div>
          ))}
        </div>
      </Section>
    </PageLayout>
  );
}
