import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { saveDraftAnswer } from 'services/answer';
import { LawyerQuestion } from 'services/lawyerQuestion';

import DeleteFormalNotice from 'features/formal-notice/DeleteFormalNotice';
import { RequestInputs } from 'features/request/context/RequestFormProvider';
import { Button } from 'features/shared/buttons';

interface RequestNextStepButtonProps {
  question: LawyerQuestion;
}

const ValidateRequestAnswerStep = ({
  question
}: RequestNextStepButtonProps) => {
  const { t } = useTranslation('lawyer');
  const { t: tForm } = useTranslation('form');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    watch,
    setValue,
    trigger,
    getValues,
    formState: { errors }
  } = useFormContext<RequestInputs>();

  const { mutate: saveAnswer, isPending } = useMutation({
    mutationFn: (data: Pick<RequestInputs, 'answerContent'>) =>
      saveDraftAnswer(question.id, { content: data.answerContent }),
    onSuccess: (data) => setValue('hasLegalGrounds', data.has_legal_grounds),
    onError: () => toast.error(tForm('template.lawyer.answer.draft.error'))
  });
  const [mustCreateFormalNotice] = watch(['mustCreateFormalNotice']);

  const shouldDeleteFormalNotice =
    question.formal_notice && !mustCreateFormalNotice;

  const onNextStepClick = async () => {
    const isAnswerValid = await trigger('answerContent');
    if (!isAnswerValid) return null;

    await saveAnswer({ answerContent: getValues('answerContent') });

    if (question.formal_notice) return setIsModalOpen(true);

    return setValue(
      'currentStep',
      mustCreateFormalNotice ? 'FORMAL_NOTICE' : 'SUMMARY'
    );
  };

  const onDeleteFormalNoticeSuccess = () => {
    setIsModalOpen(false);
    if (mustCreateFormalNotice) {
      return setValue('currentStep', 'FORMAL_NOTICE');
    }

    return setValue('currentStep', 'SUMMARY');
  };

  const onCloseModal = () => {
    if (shouldDeleteFormalNotice) {
      return setIsModalOpen(false);
    }

    return setValue('currentStep', 'FORMAL_NOTICE');
  };

  return (
    <>
      <Button
        onClick={onNextStepClick}
        variant="tertiary"
        size="large"
        disabled={!!errors.answerContent || isPending}
        label={t('account.lawyer.request.step.button.next')}
        className="self-end !w-fit"
      />
      <DeleteFormalNotice
        questionId={question.id}
        isOpen={isModalOpen}
        description={
          shouldDeleteFormalNotice
            ? t('account.lawyer.request.formalNotice.delete.description')
            : t('account.lawyer.request.formalNotice.update.description')
        }
        onSuccess={onDeleteFormalNoticeSuccess}
        onCancel={onCloseModal}
      />
    </>
  );
};

export default ValidateRequestAnswerStep;
